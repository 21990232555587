import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';

import { AccountService } from './service/account.service';
import { AdminUser } from 'eccommons';
import { SharedService } from 'src/app/shared/services/shared.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AppSettings } from '../shared/services/appSettings.services';
import { AdminUtilities } from '../shared/admin_utilities';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  businessUpLogoPath: string;
  isInvalidCred: boolean = false;
  frmLogin: UntypedFormGroup;
  getBid: { [key: string]: string };

  constructor(private accountService: AccountService
    , private router: Router, private appSettings: AppSettings, private fb: UntypedFormBuilder) {
    this.businessUpLogoPath = this.appSettings.businessUpLogoImgPath;
  }


  ngOnInit(): void {
    this.checkLoginAndRedirect();
    this.frmLogin = this.fb.group({
      bid: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(7)]),
        { errLable: 'Business ID' }),
      username: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(10)]),
        { errLable: 'Phone Number' }),
      password: new UntypedFormControl('', [Validators.required, Validators.maxLength(15)])
    });
  }

  get f() { return this.frmLogin.controls; }

  checkLoginAndRedirect() {
    if (this.accountService.IsLoggedIn) {
      this.router.navigate(['/admin']);
    }
  }

  validate() {
    if (this.frmLogin.valid) {
      const bid = this.frmLogin.get('bid').value;
      const userName = this.frmLogin.get('username').value;
      const password = this.frmLogin.get('password').value;

      this.appSettings.BID = bid;

      this.accountService.validateAdminUser(userName, password).subscribe(data => {
        if (!data) {
          this.isInvalidCred = true;
        }
        else {
          this.accountService.saveUser(new AdminUser(data));
          this.accountService.loginStateChangeSubject.next(true);
          this.checkLoginAndRedirect();
        }
      });
    }
  }

  forgotPassword(){
    this.getBid = {bid: this.frmLogin.get('bid').value};
    this.router.navigate(['/password-set'],{queryParams: this.getBid} );
  }
}
