<!-- <div class="p-4 text-center page-center"> -->
<div class="background-image">
  <div class="container text-center center flxColCntr">
    <div>

      <div class="section-title flxRowNwSB align-end">
        <h4> Login </h4>

        <img [src]="businessUpLogoPath" alt="BusinessUP-Logo" />
      </div>

      <div class="form-container shadowed-box section-content">
        <form [formGroup]="frmLogin" autocomplete="off">
          <!-- BusinessID -->
          <div class="form-group">
            <input type="text" class="form-control" formControlName="bid" placeholder="bid" appAutofocus
              no-required-asterisk>
            <span class="form__label">Business ID </span>
          </div>

          <!-- Phone Number-->
          <div class="form-group">
            <input type="text" class="form-control" formControlName="username" placeholder="phone number"
              no-required-asterisk maxlength="10">
            <span class="form__label">Phone Number </span>
          </div>

          <!-- Password -->
          <div class="form-group">
            <input type="password" class="form-control" formControlName="password" placeholder="password"
              no-required-asterisk>
            <span class="form__label">Password</span>
          </div>

          <div class="alert alert-danger" role="alert" *ngIf="isInvalidCred">
            Invalid Username or Password!
          </div>

          <div class="row float-right clearfix">
            <button class="btn btn-sm btn-primary" (click)="validate()" [disabled]="!frmLogin.valid">
              Login</button>
          </div>

          <div class="row float-left">
            <a href="javascript:void(0);" (click)="forgotPassword()">Forget Password</a>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>