"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasurementUnits = void 0;
var unit_type_enum_1 = require("../enum/unit-type.enum");
var select_option_model_1 = require("../models/commons/select-option.model");
var measurement_units_model_1 = require("../models/item/measurement-units.model");
var MeasurementUnits = /** @class */ (function () {
    function MeasurementUnits() {
    }
    MeasurementUnits.getOtherUnitsOptions = function (otherUnitCode) {
        return MeasurementUnits.Units.filter(function (u) { return u.Code !== otherUnitCode; }).map(function (s) { return new select_option_model_1.SelectOptionModel({
            value: s.Code,
            lable: "".concat(s.Name, " - ").concat(s.Code)
        }); });
    };
    MeasurementUnits.getConversionValue = function (primaryUnitCode, secondaryCode) {
        if (primaryUnitCode.toLowerCase() === 'kg') {
            if ((secondaryCode === null || secondaryCode === void 0 ? void 0 : secondaryCode.toLowerCase()) === 'gm')
                return 1000;
            if ((secondaryCode === null || secondaryCode === void 0 ? void 0 : secondaryCode.toLowerCase()) === 'mg')
                return 1000000;
        }
        else if (primaryUnitCode.toLowerCase() === 'gm') {
            if ((secondaryCode === null || secondaryCode === void 0 ? void 0 : secondaryCode.toLowerCase()) === 'mg')
                return 1000;
        }
        else if (primaryUnitCode.toLowerCase() === 'l') {
            if ((secondaryCode === null || secondaryCode === void 0 ? void 0 : secondaryCode.toLowerCase()) === 'ml')
                return 1000;
        }
        else if (primaryUnitCode.toLowerCase() === 'sqm') {
            if ((secondaryCode === null || secondaryCode === void 0 ? void 0 : secondaryCode.toLowerCase()) === 'sqft')
                return 10.7639;
        }
        else if (primaryUnitCode.toLowerCase() === 'dozen') {
            return 12;
        }
        return null;
    };
    MeasurementUnits.getUnitName = function (code) {
        var _a;
        return (_a = MeasurementUnits.Units.find(function (u) { return u.Code.toLowerCase() === code.toLowerCase(); })) === null || _a === void 0 ? void 0 : _a.Name;
    };
    MeasurementUnits.getAllUnits = function () {
        return Object.keys(unit_type_enum_1.UnitType).map(function (k) { return k.toUpperCase(); });
    };
    MeasurementUnits.Units = [
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Bags',
            Code: unit_type_enum_1.UnitType.Bag
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Bottles',
            Code: unit_type_enum_1.UnitType.Bottle
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Box',
            Code: unit_type_enum_1.UnitType.Box
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Bundles',
            Code: unit_type_enum_1.UnitType.Bundle
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Carton',
            Code: unit_type_enum_1.UnitType.Carton
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Case',
            Code: unit_type_enum_1.UnitType.Case
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Container',
            Code: unit_type_enum_1.UnitType.Container
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Crate',
            Code: unit_type_enum_1.UnitType.Crate
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Dozen',
            Code: unit_type_enum_1.UnitType.Dozen
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Kilogram',
            Code: unit_type_enum_1.UnitType.Kilogram
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Gram',
            Code: unit_type_enum_1.UnitType.Gram
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Milligram',
            Code: unit_type_enum_1.UnitType.Milligram
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Litre',
            Code: unit_type_enum_1.UnitType.Litre
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Millilitre',
            Code: unit_type_enum_1.UnitType.Millilitre
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Packs',
            Code: unit_type_enum_1.UnitType.Packs
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Packets',
            Code: unit_type_enum_1.UnitType.Packet
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Pieces',
            Code: unit_type_enum_1.UnitType.Pieces
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Rolls',
            Code: unit_type_enum_1.UnitType.Roll
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Sheets',
            Code: unit_type_enum_1.UnitType.Sheet
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Square Feet',
            Code: unit_type_enum_1.UnitType.Square_Feet
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Square Meters',
            Code: unit_type_enum_1.UnitType.Square_Meter
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Strip',
            Code: unit_type_enum_1.UnitType.Strip
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Tablets',
            Code: unit_type_enum_1.UnitType.Tablets
        }),
        new measurement_units_model_1.MeasurementUnitModel({
            Name: 'Ton',
            Code: unit_type_enum_1.UnitType.Ton
        }),
    ];
    return MeasurementUnits;
}());
exports.MeasurementUnits = MeasurementUnits;
