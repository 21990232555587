"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommunicationModel = void 0;
var CommunicationModel = /** @class */ (function () {
    function CommunicationModel(value) {
        Object.assign(this, value);
    }
    return CommunicationModel;
}());
exports.CommunicationModel = CommunicationModel;
