import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CategoryModel, TableActionModel, TableActionType, TableColumnModel, TableColumnType, TableConfigModel, TaxModel } from 'eccommons';

import { TaxAndChargeService } from './service/tax-charge.service';

@Component({
  selector: 'app-tax-and-charges',
  templateUrl: './tax-and-charges.component.html',
  styleUrls: ['./tax-and-charges.component.scss']
})
export class TaxAndChargesComponent implements OnInit { 

  // tableConfig: TableConfigModel;
  // rowData: Array<TaxAndChargeModel>;

  constructor(private taxChargeService: TaxAndChargeService, private router: Router) {
    // this.tableConfig = new TableConfigModel({
    //   Headers: [
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'TaxAndCharge_ID',
    //       FieldName: 'TaxAndChargeID'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'Name',
    //       FieldName: 'Name'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'Value',
    //       FieldName: 'Value'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'Deduction Type',
    //       FieldName: 'DeductionType'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'Deduction Type',
    //       FieldName: 'DeductionType'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.LABLE,
    //       ColumnName: 'Is Active',
    //       FieldName: 'IsActive'
    //     }),
    //     new TableColumnModel({
    //       ColumnType: TableColumnType.ACTION,
    //       ColumnName: 'Action',
    //       ActionOption: new TableActionModel({
    //         ActionType: TableActionType.ICON_EDIT,
    //         Lable: 'Edit',
    //       })
    //     })
    //   ],
    //   ShowRowNo: true,
    //   EnableRowSelection: false,
    //   AllowReOrdering: false
    // });
  }

  ngOnInit(): void {
    // this.taxChargeService.getAllTaxAndCharges().subscribe(res => {
    //   this.rowData = res;
    // });
  }

  // onActionClick(action: TableActionModel) {
  //   if (action && action.RowData) {
  //     const rowData = action.RowData as TaxAndChargeModel;
  //     this.taxChargeService.taxToEdit = rowData;
  //     this.router.navigate(['/saveTaxCharge', rowData.TaxAndChargeID]);
  //   }
  // }

}
