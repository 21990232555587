"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumnType = void 0;
var TableColumnType;
(function (TableColumnType) {
    TableColumnType["LABLE"] = "LABEL";
    TableColumnType["BOOLEAN"] = "BOOLEAN";
    TableColumnType["ACTION"] = "ACTION";
    TableColumnType["TEXTBOX"] = "TEXTBOX";
    TableColumnType["SUB_TABLE"] = "SUB_TABLE";
    TableColumnType["ENUM"] = "ENUM";
    TableColumnType["CURRENCY"] = "CURRENCY";
    TableColumnType["DATE"] = "DATE";
    TableColumnType["DATETIME"] = "DATE_TIME";
    TableColumnType["LINK"] = "LINK";
    TableColumnType["TAG"] = "TAG";
})(TableColumnType = exports.TableColumnType || (exports.TableColumnType = {}));
