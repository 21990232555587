import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AlertType } from 'eccommons';

import { SharedService } from '../shared/services/shared.service';
import { AccountService } from '../account/service/account.service';
import { AlertService } from '../shared/services/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private alertService: AlertService, private accountService: AccountService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        // Bad Request
        if (err.status == 400) {
          this.alertService.error(err.statusText || 'Something went wrong!');
          return throwError(err);
        }
        // Unauthorized
        else if (err.status == 401) {
          this.alertService.error('Unauthorized Access! Please relogin');
          this.accountService.logOutUser();
          return throwError(err);
        }
        else {
          this.alertService.error('Oops! Something went wrong. Please try again after sometime.');
          const error = err.error.message || err.statusText;
          return throwError(error);
        }
      }));
  }
}
