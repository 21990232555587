import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective {

  @Input() appAutofocus = 1;

  constructor(private elementRef: ElementRef) { };

  ngOnInit(): void {
    if (this.appAutofocus == 1) {
      setTimeout(() => {        
        this.elementRef.nativeElement.focus();
      }, 100);
    }
  }
}
