import { Injectable, OnDestroy, OnInit } from '@angular/core';

import { Observable, of, Subject } from 'rxjs';
import { concatMap, map, reduce, shareReplay } from 'rxjs/operators';
import { Subscription } from 'rxjs/internal/Subscription';

import {
  DiscountModel, SelectOptionModel, DeductionType, MessageStatusType, MessageModel, ItemBulkUploadModel,
  Utilities, TaxModel, ItemType, CategoryModel, ItemModel, ItemVarietyModel, ItemImageModel, AvailabilityType,
  PartyProfileModel,
  VareityPriceProfileModel,
  UnitLevel
} from 'eccommons';

import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { AccountService } from 'src/app/account/service/account.service';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AdminUtilities } from 'src/app/shared/admin_utilities';
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';

@Injectable({
  providedIn: 'root',
})
export class AdminService implements OnDestroy {

  discountToEdit: DiscountModel;
  itemChangeSubject: Subject<number> = new Subject<number>();

  private taxesCache: Observable<any>;
  private itemsCache: Observable<Array<ItemModel>>;
  private partyProfilesCache: Observable<Array<PartyProfileModel>>;
  private subscriptions: Subscription = new Subscription();


  /* Constructor
  ***************************************************/
  constructor(
    private httpHelper: HttpHelperService,
    private appsetting: AppSettings, private currencyPipe: CustomCurrencyPipe,
    private accountService: AccountService, private formBuilder: UntypedFormBuilder
  ) {
    this.subscriptions = this.accountService.loginStateChangeSubject.subscribe(state => {
      if (!state) {
        this.taxesCache = null;
      }
    });
  }

  /*** Category ***/
  getCategory(): Observable<CategoryModel[]> {
    return this.httpHelper.get<Array<CategoryModel[]>>(this.appsetting.URL_GetCategoriesDetails)
      .pipe(map(res => res.Data[0]?.map(c => new CategoryModel(c))));
  }
  getCategoryById(categoryID: number): Observable<CategoryModel> {
    return this.httpHelper.getByKey<CategoryModel>(this.appsetting.URL_GetCategoriesByID, "CategoryID", categoryID).pipe(map(res => res.Data));
  }

  saveCategoryDetail(categoryModel: CategoryModel, file: File): Observable<any> {
    let input = new FormData();
    input.append("ImageFiles", file);
    input.append("formData", JSON.stringify(categoryModel));
    return this.httpHelper.post(this.appsetting.URL_SaveCategory, input).pipe(map(res => res.Data));

  }

  createCategoryList(categoryModel: CategoryModel[]) {
    return this.httpHelper.post<CategoryModel[], number>('', categoryModel).pipe(map(res => res.Data));
  }

  createCategory(categoryModel: CategoryModel): Observable<string> {
    return this.httpHelper.post<CategoryModel, string>('', categoryModel).pipe(map(res => res.Data));
  }

  /*** Item ***/
  getItems(onlyActive: boolean = true): Observable<ItemModel[]> {
    if (!this.itemsCache) {
      this.itemsCache = this.httpHelper.get<any>(this.appsetting.API_URL.getItems(null, null))
        .pipe(shareReplay(1), map(res => {
          if (res.Data) {
            return this.constructItemList(res.Data[0], res.Data[1], res.Data[2], res.Data[3]);
          }
        }));
    }
    return this.itemsCache.pipe(shareReplay(1), map(itms => {
      if (onlyActive) {
        itms = itms.map(itm => {
          itm.Variety = itm.getVarietiesList().filter(iv => iv.IsActive && !iv.IsDeleted);
          return itm;
        });
      }
      return itms;
    }));
  }

  clearItemsCache(itemIDs?: number | Array<number>, callBackFn?: Function, showLoader?: boolean) {
    if (this.itemsCache) {
      if (itemIDs) {
        // Fetch details of that item and update only that to the items cache
        this.getItemDetailsByIds(itemIDs, showLoader).subscribe(freshItems => {
          if (freshItems) {
            freshItems?.forEach(freshItem => {
              this.itemsCache?.subscribe(items => {
                let itemIndex = items.findIndex(i => i.ItemID == freshItem.ItemID);

                // If already present
                if (itemIndex > -1)
                  items[itemIndex] = freshItem;
                // If newly saved
                else if (itemIndex == -1)
                  items.push(freshItem);

                this.itemsCache = of(items);

                if (callBackFn)
                  callBackFn();
              });
            });
          }
        });
      }
      else {
        this.itemsCache = null;
        if (callBackFn)
          callBackFn();
      }
    } else {
      if (callBackFn)
        callBackFn();
    }
  }

  getItemDetailsByIds(itemID: number | Array<number>, showLoader?: boolean): Observable<Array<ItemModel>> {
    if (!itemID) {
      return of(null);
    }

    let itemIDs = Array.isArray(itemID) ? itemID.join(',') : [itemID].join(',');

    return this.httpHelper.get<any>(this.appsetting.API_URL.getItems(null, itemIDs), showLoader).pipe(map(res => {
      if (res.Data) {
        return this.constructItemList(res.Data[0], res.Data[1], res.Data[2], res.Data[3]);
      }
    }));
  }

  saveItemDetail(itemModel: ItemModel, file: Array<File>): Observable<number> {
    let input = new FormData();
    if (file) {
      for (let i = 0; i < file.length; i++) {
        input.append("ItemImages[" + i + "]", file[i]);
      }
    }

    input.append("formData", JSON.stringify(itemModel));
    return this.httpHelper.post(this.appsetting.URL_SaveItem, input).pipe(map(res => res.Data[0]['@SavedItemID']));
  }

  deleteItemImage(imageID: string, FilePath: string): Observable<any> {
    let input = new FormData();
    input.append("imageId", imageID);
    input.append("filePath", FilePath);
    return this.httpHelper.post(this.appsetting.URL_DeleteItemImage, input).pipe(map(res => res.Data));
  }

  deleteItemVariety(itemVarietyID: number): Observable<any> {
    const dataToSend = {
      "itemVareityID": itemVarietyID
    };
    return this.httpHelper.post(this.appsetting.URL_DeleteItemVariety, dataToSend).pipe(map(res => res.Data));
  }

  getItemBasicDetails(categoryID?: number, itemID?: number): Observable<any> {
    const dataToSend = {
      'categoryID': categoryID,
      'itemID': itemID
    };
    return this.httpHelper.post<any, any>(this.appsetting.URL_GetItemBasicDetails, dataToSend)
      .pipe(map(res => res.Data));
  }

  getDiscountDetails(discountID?: number): Observable<any> {
    const dataToSend = {
      'DiscountID': discountID
    };
    return this.httpHelper.post<any, any>(this.appsetting.URL_GetDiscountDetails, dataToSend)
      .pipe(map(res => res.Data));
  }

  saveDiscountDetails(discount: DiscountModel, options: Array<SelectOptionModel>): Observable<number> {
    const dataToSend = {
      'DiscountID': discount.DiscountID,
      'Name': discount.Name,
      'Desc': discount.Desc,
      'DeductionType': discount.DeductionType,
      'DeductionValue': discount.DeductionValue,
      'LinkTo': discount.LinkTo,
      'LinkOptionValues': this.formArrayOfStrings(options, 'value'),
      'IsActive': discount.IsActive
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveDiscountDetails, dataToSend)
      .pipe(map(res => res.Data));
  }

  saveItemVarietyPrice(itemVarietyID: number, purchasePrice: number, MRP: number,
    primaryMinSellingUnit: number, priceProfiles: Array<VareityPriceProfileModel>, secondaryMinSellingUnit: number,
    discountValue: number, discountType: DeductionType): Observable<number> {
    const dataToSend = {
      'ItemVarietyID': itemVarietyID,
      'PurchasePrice': purchasePrice,
      'MRP': MRP,
      'PrimaryMinSellingUnit': primaryMinSellingUnit,
      'PriceProfiles': priceProfiles,
      'SecondaryMinSellingUnit': secondaryMinSellingUnit,
      'DiscountValue': discountValue,
      'DiscountType': discountType
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveItemVarietyPrice, dataToSend)
      .pipe(map(res => res.Data));
  }

  saveItemVarietyStock(itemVarietyID: number, availableQty: number, availabilityUnit: number,
    lowStockThreshold: number, location: string) {
    const dataToSend = {
      'ItemVarietyID': itemVarietyID,
      'AvailableQty': availableQty,
      'AvailabilityUnit': availabilityUnit,
      'LowStockThreshold': lowStockThreshold,
      'Location': location
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveItemVarietyStock, dataToSend)
      .pipe(map(res => res.Data));
  }


  saveBulkCategories(categoryNames: string) {
    const dataToSend = {
      CategoryNameList: categoryNames
    }
    return this.httpHelper.post<any, any[]>(this.appsetting.URL_SaveBulkCategories, dataToSend)
      .pipe(map(res => res.Data));
  }

  saveItemBulkUpload(itemDetails: Array<ItemBulkUploadModel>) {
    let input = new FormData();
    input.append("formData", JSON.stringify(itemDetails));
    return this.httpHelper.post(this.appsetting.URL_SaveItemBulkUpload, input).pipe(map(res => res.Data));
  }

  public computeDiscountPrice(price: number, discount: DiscountModel): number {
    if (discount.DeductionType === DeductionType.PERCENT) {
      price = price - (price * Number(discount.DeductionValue) / 100);
    }
    else if (discount.DeductionType === DeductionType.AMOUNT) {
      price = price - Number(discount.DeductionValue);
    }
    return price;
  }

  public getCustomerMessages(status?: MessageStatusType): Observable<Array<MessageModel>> {
    const dataToSend = {
      'Status': status
    };
    return this.httpHelper.post<any, Array<Array<MessageModel>>>(this.appsetting.URL_GetCustomerMessage, dataToSend)
      .pipe(map(res => res.Data[0]));
  }

  public saveCustomerMessageStatus(customerMessageID: string, status: MessageStatusType): Observable<number> {
    const dataToSend = {
      'CustomerMessageID': customerMessageID,
      'Status': status
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveCustomerMessageStatus, dataToSend)
      .pipe(map(res => res.Data));
  }

  public exportBasicVarietyDetails(): Observable<Array<ItemBulkUploadModel>> {
    return this.httpHelper.get(this.appsetting.URL_GetBasicVarietyList)
      .pipe(map(res => this.constructItemBulkUploadList(res.Data[0], res.Data[1])));
  }

  fuzzySearchItem(itemList: Array<ItemModel>, searchKey: string): Array<ItemModel> {
    if (itemList) {      
      let matchingItems: Array<Array<ItemModel>> = new Array(10).fill([]).map(() => new Array<ItemModel>());

      if (!searchKey) return itemList;

      itemList.forEach(item => {
        let searchKeyFound = false;
        let matchingPriorityIndex: number = 0;

        if (Utilities.hasKey(item.Name, searchKey) ||
          Utilities.hasKey(item.KeyWords, searchKey) ||
          Utilities.hasKey(item.Description, searchKey) ||
          Utilities.hasKey(item.CategoryName, searchKey)
        ) {
          searchKeyFound = true;
        }

        if (item.ItemType === ItemType.Single) {
          let iv = item.Variety as ItemVarietyModel;
          let itemVarietyMatchinPriority = this.fuzzySearchItemVariety(searchKey, iv);
          if (searchKeyFound || itemVarietyMatchinPriority > 0) {
            searchKeyFound = true;
            if (itemVarietyMatchinPriority > matchingPriorityIndex)
              matchingPriorityIndex = itemVarietyMatchinPriority - 1;

            matchingItems[matchingPriorityIndex].push(new ItemModel(JSON.parse(JSON.stringify(item))));
          }
        }
        else if (item.ItemType === ItemType.Multi) {
          // if (searchKeyFound)
          //   matchingItems[matchingPriority].push(new ItemModel(JSON.parse(JSON.stringify(item))));

          // else
          {
            (item.Variety as Array<ItemVarietyModel>).forEach(iv => {
              let itemVarietyMatchinPriority = this.fuzzySearchItemVariety(searchKey, iv);
              if (searchKeyFound || itemVarietyMatchinPriority > 0) {
                if (itemVarietyMatchinPriority > matchingPriorityIndex)
                  matchingPriorityIndex = itemVarietyMatchinPriority - 1;

                let matchingItem = Utilities.deepCopy<ItemModel>(item, ItemModel)
                matchingItem.Variety = [iv];

                matchingItems[matchingPriorityIndex].push(new ItemModel(matchingItem));
              }
            });
          }
        }
      });
      let finalMatchings = [];

      // If exact match found, return that
      if(matchingItems[9]?.length)
        return matchingItems[9];

      // Combine all matching items
      for(let i=8; i>=0; i--) {
        if (matchingItems[i]?.length)
          Utilities.combineArray(finalMatchings, matchingItems[i]);
      }     
      return finalMatchings;
    }
  }

  // Search and returns matched priority else 0 when not matched
  fuzzySearchItemVariety(searchKey: string, itemVariety: ItemVarietyModel): number {
    
    const codes = itemVariety.Code?.split(',');
    if (codes && codes.length > 0 && codes.includes(searchKey))
      return 10; // If a variety code matches, return highest priority
    if (Utilities.isStringsEqual(searchKey, itemVariety.Code))
      return 2;
    else if (Utilities.hasKey(itemVariety.Code, searchKey))
      return 1;
    else if (Utilities.hasKey(itemVariety.Name, searchKey))
      return 1;
    else if (Utilities.hasKey(itemVariety.CategoryName, searchKey))
      return 1;
    else if (Utilities.hasKey(itemVariety.PrimaryPrice, searchKey))
      return 1;
    else if (Utilities.hasKey(itemVariety.SecondaryPrice, searchKey))
      return 1;
    else if (Utilities.hasKey(itemVariety.PurchasePrice, searchKey))
      return 1;
    return 0;
  }

  saveCategoryDisplayOrder(categoryIDList: Array<number>): Observable<number> {
    const dataToSend = {
      'CategoryIDList': categoryIDList.join(',')
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveCategoriesDisplayOrder, dataToSend)
      .pipe(map(res => res.Data));
  }

  saveItemDisplayOrder(itemIDList: Array<number>): Observable<number> {
    const dataToSend = {
      'ItemIDList': itemIDList.join(',')
    };
    return this.httpHelper.post<any, number>(this.appsetting.URL_SaveItemDisplayOrder, dataToSend)
      .pipe(map(res => res.Data));
  }

  getTaxInfo(showLoader: boolean = true): Observable<Array<TaxModel>> {
    if (!this.taxesCache) {
      this.taxesCache = this.httpHelper.get<Array<any>>(this.appsetting.URL_GetTaxes, showLoader)
        .pipe(shareReplay(1), map(res => {
          if (res.Data && res.Data[0]) {
            return res.Data[0]?.map(d => new TaxModel(d));
          }
        }));
    }
    return this.taxesCache;
  }

  getPartyProfile(onlyActive: boolean = true, showLoader: boolean = true): Observable<Array<PartyProfileModel>> {
    if (!this.partyProfilesCache) {
      this.partyProfilesCache = this.httpHelper.get<Array<any>>(this.appsetting.URL_GetPartyProfile, showLoader)
        .pipe(shareReplay(1), map(res => {
          if (res.Data && res.Data[0]) {
            return res.Data[0]?.map(d => new PartyProfileModel(d));
          }
        }));
    }
    return this.partyProfilesCache.pipe(shareReplay(1), map(res => res?.filter(itm => {
      if (!onlyActive)
        return true;

      return itm.IsActive;
    })));
  }


  clearPartyProfilesCache() {
    this.partyProfilesCache = null;
  }

  filterItemByStock(itemList: Array<ItemModel>, filterAvailabilityType: AvailabilityType): Array<ItemModel> {
    if (!Utilities.hasValue(filterAvailabilityType))
      return itemList;

    if (filterAvailabilityType == AvailabilityType.Low_Stock) {
      return itemList.filter(itm => {
        itm.Variety = itm.getVarietiesList()
          .filter(iv => iv.AvailablilityType == AvailabilityType.Stocked && iv.IsLowOnStock);
        if (itm.ItemType == ItemType.Single) {
          itm.Variety = itm.Variety[0];
          return itm.Variety?.ItemVarietyID > 0;
        }
        return itm.Variety?.length > 0
      });
    }
    else {
      return itemList.filter(itm => {
        itm.Variety = itm.getVarietiesList().filter(iv => iv.AvailablilityType == filterAvailabilityType);
        if (itm.ItemType == ItemType.Single) {
          itm.Variety = itm.Variety[0];
          return itm.Variety?.ItemVarietyID > 0;
        }
        return itm.Variety?.length;
      });
    }

    return itemList;
  }

  createVarietyPriceFormArray(varietyIndex: number, varietyPrices: Array<VareityPriceProfileModel>, partyProfiles: Array<PartyProfileModel>): UntypedFormArray {
    let varietyPriceFormArray: UntypedFormArray = this.formBuilder.array([]);
    // Loop through active party profiles and create the form
    partyProfiles.forEach((ppf) => {
      let vp = varietyPrices?.find(vp => vp.PartyProfileID == ppf.PartyProfileID) ?? new VareityPriceProfileModel({
        PartyProfileID: ppf.PartyProfileID,
        PartyProfileName: ppf.Name
      });

      const frmGrp = new UntypedFormGroup({
        'ItemVarietyPriceID': new UntypedFormControl(vp.ItemVarietyPriceID),
        'ItemVarietyID': new UntypedFormControl(vp.ItemVarietyID),
        'PartyProfileID': new UntypedFormControl(vp.PartyProfileID),
        'PartyProfileName': new UntypedFormControl(vp.PartyProfileName),
        'PrimaryPrice': AdminUtilities.newFormControl(new UntypedFormControl(Utilities.parseTo2DecPlaces(vp.PrimaryPrice),
          [Validators.required]), { errLable: `Variety ${varietyIndex + 1} - ${vp.PartyProfileName} Primary Selling Price` }),
        'SecondaryPrice': AdminUtilities.newFormControl(new UntypedFormControl(Utilities.parseTo2DecPlaces(vp.SecondaryPrice)),
          { errLable: `Variety ${varietyIndex + 1} - ${vp.PartyProfileName} Secondary Selling Price` }),
      });

      varietyPriceFormArray.push(frmGrp);
    });

    return varietyPriceFormArray;
  }

  marginInfo(qtyGrp: UntypedFormGroup, priceGrp: UntypedFormGroup, applyFor: string): string {
    let purchasePrice = Utilities.parseNumber(qtyGrp.get('PurchasePrice').value);
    if (purchasePrice) {
      if (applyFor === 'P') {
        let SellingPrice = Utilities.parseNumber(priceGrp.get('PrimaryPrice').value);
        let minCustValue = Utilities.parseNumber(qtyGrp.get('PrimaryMinSellingUnit').value);

        if (!minCustValue)
          minCustValue = 1;

        if (SellingPrice) {
          let priceDiff = (SellingPrice / minCustValue) - purchasePrice;
          if (priceDiff < 1)
            priceGrp.get('PrimaryPrice').updateValueAndValidity();
          return this.currencyPipe.transform(priceDiff);
        }
      }
      else if (applyFor === 'S') {
        let secondaryPrice = Utilities.parseNumber(priceGrp.get('SecondaryPrice').value);
        let unitConversionValue = Utilities.parseNumber(qtyGrp.get('UnitConversionValue').value);
        let minCustValue = Utilities.parseNumber(qtyGrp.get('SecondaryMinSellingUnit').value);

        if (!minCustValue)
          minCustValue = 1;

        if (secondaryPrice && unitConversionValue) {
          let priceDiff = ((secondaryPrice * unitConversionValue) / minCustValue) - purchasePrice;
          if (priceDiff < 1)
            priceGrp.get('SecondaryPrice').updateValueAndValidity();
          return this.currencyPipe.transform(priceDiff);
        }
      }
    }
  }

  setPriceFieldsValidation(qtyGrp: UntypedFormGroup) {
    let sellingUnit: UnitLevel = qtyGrp.get('SellingUnit').value as UnitLevel;
    let unitConversionCtrl = qtyGrp.get('UnitConversionValue');
    let priceProfiles = this.getPriceProfileControls(qtyGrp);

    priceProfiles?.forEach((priceGrp: UntypedFormGroup) => {
      let primaryPriceCtrl = priceGrp.get('PrimaryPrice');
      let secondaryPriceCtrl = priceGrp.get('SecondaryPrice');

      unitConversionCtrl.clearValidators();
      primaryPriceCtrl.clearValidators();
      secondaryPriceCtrl.clearValidators();

      if (!sellingUnit || sellingUnit == UnitLevel.PrimaryUnit) {
        primaryPriceCtrl.setValidators([Validators.required, this.greaterThanPurchasePrice(qtyGrp)]);
        secondaryPriceCtrl.reset();
      }
      else if (sellingUnit == UnitLevel.SecondaryUnit) {
        unitConversionCtrl.setValidators(Validators.required);
        secondaryPriceCtrl.setValidators([Validators.required, this.greaterThanComputedPurchasePrice(qtyGrp)]);
        primaryPriceCtrl.reset();
      }
      else if (sellingUnit == UnitLevel.All) {
        unitConversionCtrl.setValidators(Validators.required);
        primaryPriceCtrl.setValidators([Validators.required, this.greaterThanPurchasePrice(qtyGrp)]);
        secondaryPriceCtrl.setValidators([Validators.required, this.greaterThanComputedPurchasePrice(qtyGrp)]);
      } else {
        primaryPriceCtrl.setValidators(Validators.required);
      }

      unitConversionCtrl.updateValueAndValidity();
      primaryPriceCtrl.updateValueAndValidity();
      secondaryPriceCtrl.updateValueAndValidity();
    });
  }

  getDeductionText(qtyGrp: UntypedFormGroup, priceGrp: UntypedFormGroup, applyFor: string): string {
    const discountType: DeductionType = qtyGrp.get('DiscountType').value;
    const discountValue = Utilities.parseNumber(qtyGrp.get('DiscountValue').value);

    if (discountType && discountValue) {
      let sellingPrice: number;
      if (applyFor === 'p') {
        sellingPrice = Utilities.parseNumber(priceGrp.get('PrimaryPrice').value);
      }
      else if (applyFor === 's') {
        sellingPrice = Utilities.parseNumber(priceGrp.get('SecondaryPrice').value);
      }
      // const sellingPrice = Utilities.parseNumber(qtyGrp.get('Price').value);
      if (sellingPrice > 0) {
        let deductionText = Utilities.discountDesc(sellingPrice, discountType, discountValue);
        return deductionText;
      }
    }
  }

  getPriceProfileControls(qtyGrp: UntypedFormGroup): Array<AbstractControl> {
    return (<UntypedFormArray>qtyGrp.get('PriceProfiles')).controls;
  }

  greaterThanPurchasePrice(qtyGrp: UntypedFormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const group = control.parent;
      let valueOfFieldToCompare = Number(qtyGrp.get('PurchasePrice').value);
      let minCustValue = Utilities.parseNumber(qtyGrp.get('PrimaryMinSellingUnit').value);
      if (!minCustValue)
        minCustValue = 1;

      const isLessThan = valueOfFieldToCompare > (Number(control.value) / minCustValue);
      return isLessThan ? { 'lessThan': { value: control.value, targetField: 'Purchase Price' } } : null;
    }
  }

  greaterThanComputedPurchasePrice(qtyGrp: UntypedFormGroup): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const group = control.parent;
      const purchasePrice = Number(qtyGrp.get('PurchasePrice').value);
      let minCustValue = Utilities.parseNumber(qtyGrp.get('SecondaryMinSellingUnit').value);
      if (!minCustValue)
        minCustValue = 1;

      const isLessThan = purchasePrice > ((Number(control.value) * Number(qtyGrp.get('UnitConversionValue').value) / minCustValue));
      return isLessThan ? { 'custom': { message: `Cannot be less than Purchase Price` } } : null;
    }
  }

  getItemVarietyNames(): Observable<Array<{ key: number, value: string }>> {
    return this.getItems().pipe(
      concatMap(items => items ? of(...items) : of()),
      map((item: ItemModel) => item.getVarietiesList().filter(v => v.ItemVarietyID).map(variety => ({ key: variety.ItemVarietyID, value: variety.DisplayName }))),
      reduce((acc, val) => acc.concat(val), [])
    );
  }

  /**************** PRIVATES ******/
  private constructItemList(itemList: Array<ItemModel>, itemVarities: Array<ItemVarietyModel>, itemImages: Array<ItemImageModel>,
    varietyPriceProfiles: Array<VareityPriceProfileModel>): Array<ItemModel> {
    let items = new Array<ItemModel>();
    itemList?.forEach(item => {
      item.Variety = itemVarities?.filter(iv => iv.ItemID == item.ItemID)?.map(iv => {
        iv.PriceProfiles = varietyPriceProfiles?.filter(vpp => vpp.ItemVarietyID == iv.ItemVarietyID);
        return new ItemVarietyModel(iv);
      });
      item.Images = itemImages?.filter(im => im.ItemID == item.ItemID)?.map(im => new ItemImageModel(im))
      items.push(new ItemModel(item));
    });

    return items;
  }

  private constructItemBulkUploadList(itemList: Array<ItemBulkUploadModel>,
    varietyPriceProfiles: Array<VareityPriceProfileModel>): Array<ItemBulkUploadModel> {
    let items = new Array<ItemBulkUploadModel>();

    itemList?.forEach(item => {
      item.PriceProfiles = varietyPriceProfiles?.filter(vpp => vpp.ItemVarietyID == item.ItemVarietyID);
      items.push(new ItemBulkUploadModel(item));
    });

    return items;
  }

  private formItemObj(itemBaseDetails: ItemModel, itemVarities: Array<ItemVarietyModel>, itemImages: Array<ItemImageModel>): ItemModel {
    if (itemBaseDetails) {
      itemBaseDetails.Variety = itemVarities?.map(iv => new ItemVarietyModel(iv));
      itemBaseDetails.Images = itemImages?.map(im => new ItemImageModel(im));
    }
    return new ItemModel(itemBaseDetails);
  }

  private formArrayOfStrings(list: Array<any>, fieldName: string): string {
    if (list) {
      let ids = list.map(i => {
        return i[fieldName]
      }).toString();
      return ids;
    }
    return null;

  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
