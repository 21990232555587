<div id="side-menu-content">
  <div id="side-logo-container">
    <!-- Business Logo -->
    <img [src]="bussinessLogoImgPath" class="h-100" />
  </div>

  <div id="side-menus-container" class="flxColNWSB">
    <!-- Side Menus -->
    <div class="mt-4">
      <!-- Dashboard -->
      <div class="menu-row" routerLink="dashboard" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Dashboard icon-m" alt="Dashboard"></i>
          <span class="side-menu-title">Dashboard</span>
        </div>
      </div>

      <!-- Order -->
      <div class="menu-row" routerLink="order" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Orders icon-m" alt="Orders"></i>
          <span class="side-menu-title">Order</span>

          <i class="icon-Add show-on-hover ml-4 action-icon" (click)="routeChange($event, 'order/bill/0/1')"></i>
        </div>
      </div>

      <!-- Finance -->
      <div class="menu-row" (click)="showFinanceSubMenu = !showFinanceSubMenu">
        <div class="menu-row-content">
          <i class="icon-Finance icon-m" alt="Finance"></i>
          <span class="side-menu-title">Finance</span>
        </div>

        <i class="fa-solid icon-sm" [ngClass]="showFinanceSubMenu ? 'fa-chevron-up': 'fa-chevron-down'"></i>
      </div>
      <!-- Sub-menu: Finance -->
      <div class="sub-menu-row" *ngIf="showFinanceSubMenu" [@fadeInOut]>
        <!-- Overview -->
        <div class="menu-row  l-1" routerLink="finance/overview" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Finance icon-m" alt="Finance"></i>
            <span class="side-menu-title">Overview</span>
          </div>
        </div>

        <!-- Receive -->
        <div class="menu-row  l-1" routerLink="finance/moneyin" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="fas fa-location-arrow fa-rotate-180"></i>
            <span class="side-menu-title">Receive</span>
          </div>
        </div>

        <!-- Pay -->
        <div class="menu-row  l-1" routerLink="finance/moneyout" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="fas fa-location-arrow"></i>
            <span class="side-menu-title">Pay</span>
          </div>
        </div>

        <!-- Investment -->
        <div class="menu-row  l-1" routerLink="finance/investment" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="fas fa-coins"></i>
            <span class="side-menu-title">Investment</span>
          </div>
        </div>

        <!-- Cash Register -->
        <div class="menu-row  l-1" routerLink="finance/cashRegister" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="fas fa-cash-register"></i>
            <span class="side-menu-title">Cash Register</span>
          </div>
        </div>
      </div>

      <!-- Category -->
      <div class="menu-row" routerLink="categorydetails" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Category icon-m"></i>
          <span class="side-menu-title">Category</span>

          <i class="icon-Add show-on-hover ml-4 action-icon" (click)="routeChange($event, 'saveCategory/0')"></i>
        </div>
      </div>

      <!-- Items -->
      <div class="menu-row" routerLink="item/itemdetails" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Items icon-m" alt="Items"></i>
          <span class="side-menu-title">Items</span>

          <i class="icon-Add show-on-hover ml-4 action-icon" (click)="routeChange($event, '/item/saveItem/0')"></i>
        </div>
      </div>

      <!-- Banner -->
      <div class="menu-row" routerLink="banner" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Banner icon-m" alt="Items"></i>
          <span class="side-menu-title">Banner</span>

          <i class="icon-Add show-on-hover ml-4 action-icon" (click)="routeChange($event, 'saveBanner/0')"></i>
        </div>
      </div>

      <!-- Bulk Upload -->
      <div class="menu-row" routerLink="bulkupload" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Upload-Cloud icon-m" alt="Bulk Upload"></i>
          <span class="side-menu-title">Bulk Upload</span>
        </div>
      </div>

      <!-- Messages -->
      <div class="menu-row" routerLink="customermessage" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Message icon-m" alt="Messages"></i>
          <span class="side-menu-title">Messages</span>
        </div>
      </div>

      <!-- Parties -->
      <div class="menu-row" routerLink="party" [routerLinkActive]="'is-active'">
        <div class="menu-row-content">
          <i class="icon-Parties icon-m" alt="Parties"></i>
          <span class="side-menu-title">Parties</span>

          <i class="icon-Add show-on-hover ml-4 action-icon" (click)="routeChange($event, 'party/saveParty/0')"></i>
        </div>
      </div>

      <!-- Reports -->
      <div class="menu-row" (click)="showReportsSubMenu = !showReportsSubMenu">
        <div class="menu-row-content nav-link">    
          <i class="fa fa-line-chart icon-sm" aria-hidden="true" alt="Reports"></i>                          
          <span class="side-menu-title">Reports</span>
        </div>

        <i class="fa-solid fa-chevron-down icon-sm"
          [ngClass]="showReportsSubMenu ? 'fa-chevron-up': 'fa-chevron-down'"></i>
      </div>

      <!-- Sub-menu: Reports -->
      <div class="sub-menu-row" *ngIf="showReportsSubMenu" [@fadeInOut]>
        <!-- Site Images -->
        <div class="menu-row  l-1" routerLink="report/stocklog" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="fa fa-history icon-sm" aria-hidden="true" alt="Stock Log"></i>            
            <span class="side-menu-title">Stock Log</span>
          </div>
        </div>
      </div>
    

      <!-- Settings -->
      <div class="menu-row" (click)="showSettingsSubMenu = !showSettingsSubMenu">
        <div class="menu-row-content nav-link">
          <i class="icon-Setting icon-m" alt="Items"></i>
          <span class="side-menu-title">Settings</span>
        </div>

        <i class="fa-solid fa-chevron-down icon-sm"
          [ngClass]="showSettingsSubMenu ? 'fa-chevron-up': 'fa-chevron-down'"></i>
      </div>

      <!-- Sub-menu: Settings -->
      <div class="sub-menu-row" *ngIf="showSettingsSubMenu" [@fadeInOut]>
        <!-- Site Settings -->
        <div class="menu-row  l-1" routerLink="settings/siteSettings" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Site icon-m" alt="Site"></i>
            <span class="side-menu-title">Site</span>
          </div>
        </div>

        <!-- Announce -->
        <div class="menu-row  l-1" routerLink="settings/notifycustomers" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Announce icon-m" alt="notify customers"></i>
            <span class="side-menu-title">Announce</span>
          </div>
        </div>

        <!-- Payment Settings -->
        <div class="menu-row  l-1" routerLink="settings/paymentsettings" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Payment icon-m" alt="Payment"></i>
            <span class="side-menu-title">Payment</span>
          </div>
        </div>

        <!-- Delivery Settings -->
        <div class="menu-row  l-1" routerLink="settings/deliverySettings" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Delivery icon-m" alt="Delivery"></i>
            <span class="side-menu-title">Delivery</span>
          </div>
        </div>

        <!-- SMS Settings -->
        <div class="menu-row  l-1" routerLink="settings/smsSettings" [routerLinkActive]="'is-active'">
          <div class="menu-row-content">
            <div class="hline"></div>
            <i class="icon-Message icon-m" alt="SMS Images"></i>
            <span class="side-menu-title">SMS</span>
          </div>
        </div>
      </div>
    </div>

    <!-- Powered By Footer -->
    <div class="flxColStNw mt-2">
      <span class="text-muted-xsm">Powered by</span>
      <img [src]="bupLogoImgPath" class="img-sm" alt="BusinessUP" />
    </div>
  </div>
</div>