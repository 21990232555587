import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'CustomCurrency' })
export class CustomCurrencyPipe implements PipeTransform {
    private locale: string = 'en-IN';

    public constructor() {
        //super('en-IN');
    }

    transform(value: number | string, currentyCode: string = 'INR'): string {
        // If invalid value passed then return as it is.
        if (!value && value != 0) {
            return value as string;
        }

        // If value is string then convert all of it's number to currency.
        if (typeof value === 'string') {
            let numbersInString = value.match(/[0-9.]+/g);
            numbersInString?.forEach((number) => {
                value = String(value).replace(number, this.convert(parseFloat(number), currentyCode));
            });

            return String(value);
        }
        else if (!Number.isNaN(value)) {
            value = Number(value);
            return this.convert(value, currentyCode);
        }
        else
            return String(value);
    }

    private convert(value: number, currentyCode: string): string {
        if (currentyCode == 'INR') {
            return value.toLocaleString('en-IN', {
                maximumFractionDigits: 2,
                style: 'currency',
                currency: 'INR'
            });
        }
        else
            return new CurrencyPipe(this.locale).transform(value, currentyCode, 'symbol', '2.2-2');
    }

}
