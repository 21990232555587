"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitType = void 0;
var UnitType;
(function (UnitType) {
    UnitType["Bag"] = "Bag";
    UnitType["Bottle"] = "Btl";
    UnitType["Box"] = "Box";
    UnitType["Bundle"] = "Bndl";
    UnitType["Carton"] = "Crtn";
    UnitType["Case"] = "Case";
    UnitType["Container"] = "Cntnr";
    UnitType["Crate"] = "Crate";
    UnitType["Dozen"] = "Dozen";
    UnitType["Kilogram"] = "Kg";
    UnitType["Gram"] = "gm";
    UnitType["Milligram"] = "mg";
    UnitType["Litre"] = "L";
    UnitType["Millilitre"] = "ml";
    UnitType["Packs"] = "Pac";
    UnitType["Packet"] = "Pkt";
    UnitType["Pieces"] = "Pcs";
    UnitType["Roll"] = "Roll";
    UnitType["Sheet"] = "Sheet";
    UnitType["Square_Feet"] = "Sqft";
    UnitType["Square_Meter"] = "Sqm";
    UnitType["Strip"] = "Strip";
    UnitType["Tablets"] = "Tblt";
    UnitType["Ton"] = "Ton";
})(UnitType = exports.UnitType || (exports.UnitType = {}));
