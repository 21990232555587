"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesItemModel = void 0;
var utilities_1 = require("../../utils/utilities");
var SalesItemModel = /** @class */ (function () {
    function SalesItemModel(value) {
        Object.assign(this, value);
        this.Date = new Date(this.Date);
        this.TotalSales = utilities_1.Utilities.parseNumber(this.TotalSales);
    }
    return SalesItemModel;
}());
exports.SalesItemModel = SalesItemModel;
