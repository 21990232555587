"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppliedPromoCode = void 0;
var enum_1 = require("../../enum");
var AppliedPromoCode = /** @class */ (function () {
    function AppliedPromoCode(value) {
        if (value)
            Object.assign(this, value);
        this.computeDisplayString();
    }
    AppliedPromoCode.prototype.computeDisplayString = function () {
        if (this.DiscountType == enum_1.DeductionType.PERCENT)
            this.DiscountDisplayString = "".concat(this.DiscountValue, " % Discount");
        else if (this.DiscountType == enum_1.DeductionType.AMOUNT)
            this.DiscountDisplayString = "Flat ".concat(this.DiscountValue, " \u20B9 Discount");
    };
    return AppliedPromoCode;
}());
exports.AppliedPromoCode = AppliedPromoCode;
