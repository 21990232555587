"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableColumnModel = void 0;
var enum_1 = require("../../enum");
var TableColumnModel = /** @class */ (function () {
    function TableColumnModel(value) {
        this.ColumnType = enum_1.TableColumnType.LABLE;
        Object.assign(this, value);
        if (!this.ColumnType)
            this.ColumnType = enum_1.TableColumnType.LABLE;
    }
    Object.defineProperty(TableColumnModel.prototype, "IsComputationRequired", {
        get: function () {
            return this.ShowSum;
        },
        enumerable: false,
        configurable: true
    });
    return TableColumnModel;
}());
exports.TableColumnModel = TableColumnModel;
