"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeliverySettingsModel = void 0;
var utils_1 = require("../../utils");
var business_settings_model_1 = require("./business-settings.model");
var drop_location_model_1 = require("./drop-location.model");
var DeliverySettingsModel = /** @class */ (function (_super) {
    __extends(DeliverySettingsModel, _super);
    function DeliverySettingsModel(value, dropLocations) {
        var _this = _super.call(this) || this;
        Object.assign(_this, value);
        if (dropLocations)
            _this.AllowedDropLocations = dropLocations.map(function (dl) { return new drop_location_model_1.DropLocationModel(dl); });
        _this.IsCustomDropLocationAllowed = utils_1.Utilities.parseBoolean(_this.IsCustomDropLocationAllowed);
        _this.IsDeliveryOnlyAtDropLocations = utils_1.Utilities.parseBoolean(_this.IsDeliveryOnlyAtDropLocations);
        return _this;
    }
    Object.defineProperty(DeliverySettingsModel.prototype, "AllowedDeliveryStateIDListCol", {
        get: function () {
            if (this.AllowedDeliveryStateIDList)
                return this.AllowedDeliveryStateIDList.split(',');
            else
                return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DeliverySettingsModel.prototype, "AllowedDeliveryPinCodesCol", {
        get: function () {
            if (this.AllowedDeliveryPinCodes)
                return this.AllowedDeliveryPinCodes.split(',');
            else
                return null;
        },
        enumerable: false,
        configurable: true
    });
    DeliverySettingsModel.prototype.addPinCode = function (newPinCode) {
        if (!this.AllowedDeliveryPinCodes) {
            this.AllowedDeliveryPinCodes = newPinCode;
        }
        else {
            if (this.AllowedDeliveryPinCodesCol.find(function (p) { return p == newPinCode; }))
                return -1;
            else {
                this.AllowedDeliveryPinCodes = this.AllowedDeliveryPinCodes.concat(",", newPinCode);
            }
        }
        return 1;
    };
    DeliverySettingsModel.prototype.removePinCode = function (pinCodeToRemove) {
        if (this.AllowedDeliveryPinCodes) {
            var remainingPinCodes = this.AllowedDeliveryPinCodesCol.filter(function (p) { return p != pinCodeToRemove; });
            if (remainingPinCodes)
                this.AllowedDeliveryPinCodes = remainingPinCodes.join(",");
            else
                this.AllowedDeliveryPinCodes = null;
        }
        return 1;
    };
    return DeliverySettingsModel;
}(business_settings_model_1.BusinessSettingsModel));
exports.DeliverySettingsModel = DeliverySettingsModel;
