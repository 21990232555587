import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AdminComponent } from './admin/admin.component'
import { SaveCategoryComponent } from './admin/save-category/save-category.component';
import { CategoryDetailsComponent } from './admin/category-details/category-details.component';
import { LoginComponent } from './account/login.component';
import { TaxAndChargesComponent } from './admin/tax-and-charges/tax-and-charges.component';
import { SaveTaxChargeComponent } from './admin/tax-and-charges/save-tax-charge/save-tax-charge.component';
import { BannerComponent } from './admin/banner/banner.component';
import { SaveBannerComponent } from './admin/banner/save-banner/save-banner.component';
import { OnlyLoggedInUsersGuard } from './helpers/only-loggedin-users.guard';
import { StocksComponent } from './admin/stocks/stocks.component';
import { CustomerMessageComponent } from './customer-message/customer-message.component';
import { ItemBulkUploadComponent } from './admin/bulk-upload/item-bulk-upload/item-bulk-upload.component';
import { PasswordSetComponent } from './account/password-set/password-set.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: LoginComponent },
  { path: 'password-set', component: PasswordSetComponent },
  { path: 'admin', component: AdminComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Admin' } },
  { path: 'dashboard', loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule), data: { title: 'Dashboard' } },
  { path: 'item', loadChildren: () => import('./admin/item/item.module').then(m => m.ItemModule), data: { title: 'Item' } },
  { path: 'saveCategory/:categoryID', component: SaveCategoryComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Save Category' } },  
  { path: 'stocks', component: StocksComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Stocks' } },  
  { path: 'categorydetails', component: CategoryDetailsComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Category' } },
  { path: 'taxandcharges', component: TaxAndChargesComponent, canActivate: [OnlyLoggedInUsersGuard] },
  { path: 'saveTaxCharge/:taxChargeID', component: SaveTaxChargeComponent, canActivate: [OnlyLoggedInUsersGuard] },
  { path: 'banner', component: BannerComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Banner' } },
  { path: 'saveBanner/:bannerID', component: SaveBannerComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Save Banner' } },
  { path: 'customermessage', component: CustomerMessageComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Messages' } },
  //{ path: 'bulkupload', component: ItemBulkUploadComponent, canActivate: [OnlyLoggedInUsersGuard], data: { title: 'Bulk Upload' } },
  { path: 'order', loadChildren: () => import('./order-management/order-management.module').then(m => m.OrderManagementModule), canLoad: [OnlyLoggedInUsersGuard], data: { title: 'Order' } },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule), canLoad: [OnlyLoggedInUsersGuard], data: { title: 'Settings' }  },
  { path: 'finance', loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule), canLoad: [OnlyLoggedInUsersGuard] , data: { title: 'Finance' } },
  { path: 'party', loadChildren: () => import('./admin/parties/party.module').then(m => m.PartyModule), data: { title: 'Party' } },
  { path: 'report', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule), data: { title: 'Report' } },
  { path: 'test', loadChildren: () => import('./admin/test/test.module').then(m => m.TestModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [OnlyLoggedInUsersGuard]
})
export class AppRoutingModule { }
