"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalOrderItemModel = void 0;
var AdditionalOrderItemModel = /** @class */ (function () {
    function AdditionalOrderItemModel(value) {
        if (value)
            Object.assign(this, value);
        if (this.PercentageValue)
            this.PercentageValue = Math.round(this.PercentageValue * 100) / 100;
    }
    return AdditionalOrderItemModel;
}());
exports.AdditionalOrderItemModel = AdditionalOrderItemModel;
