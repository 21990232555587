"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportImportColumn = void 0;
var custom_data_type_enum_1 = require("../../enum/custom-data-type.enum");
var custom_data_types_constants_1 = require("../../constants/custom-data-types.constants");
var ExportImportColumn = /** @class */ (function () {
    /*** Constructor ******/
    function ExportImportColumn(value) {
        this.IsMandatory = false;
        Object.assign(this, value);
        // If no field name then same as column name
        if (!this.FieldName)
            this.FieldName = this.ColumnName;
    }
    Object.defineProperty(ExportImportColumn.prototype, "AllowedValuesErrorMsg", {
        get: function () {
            if (!this.AllowedValues)
                return;
            var errorMsg = "".concat(this.ColumnName, " : Only following values are allowed : ");
            this.AllowedValues.forEach(function (av) {
                if (av == custom_data_type_enum_1.CustomDataTypeEnum.ANY_NATURAL_NUMBER) {
                    errorMsg += 'Any Natural Numbers like 0,1,2 etc.';
                }
                else if (av == custom_data_type_enum_1.CustomDataTypeEnum.ANY_NUMBER) {
                    errorMsg += 'Any Numbers like 0.0, 0.1, 1.0, 1.1, 1.2 etc.';
                }
                else
                    errorMsg += "".concat(av, ", ");
            });
            return errorMsg;
        },
        enumerable: false,
        configurable: true
    });
    /**** Public Methods ******/
    ExportImportColumn.prototype.isValueAllowed = function (value) {
        if (!this.AllowedValues)
            return true;
        if (!this.IsMandatory && !value)
            return true;
        var isAllowed = false;
        for (var _i = 0, _a = this.AllowedValues; _i < _a.length; _i++) {
            var av = _a[_i];
            if (typeof av === 'string') {
                if (custom_data_types_constants_1.CustomDataType.IsValueValid(av, String(value))) {
                    isAllowed = true;
                    break;
                }
                if (av.toLowerCase() == String(value).toLowerCase()) {
                    isAllowed = true;
                    break;
                }
            }
            else if (av == value) {
                isAllowed = true;
                break;
            }
        }
        return isAllowed;
    };
    return ExportImportColumn;
}());
exports.ExportImportColumn = ExportImportColumn;
