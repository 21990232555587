"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionModel = void 0;
var utilities_1 = require("../../utils/utilities");
var TransactionModel = /** @class */ (function () {
    function TransactionModel(value) {
        Object.assign(this, value);
        this.Amount = utilities_1.Utilities.parseNumber(this.Amount);
        this.Balance = utilities_1.Utilities.parseNumber(this.Balance);
        this.TransactionDate = utilities_1.Utilities.convertUTCToLocalDate(String(this.TransactionDate));
        // Set the display Payment Category Name
        if (!this.CategoryName)
            this.DisplayPaymentCategoryName = this.MasterPaymentCategoryName;
        else
            this.DisplayPaymentCategoryName = this.MasterPaymentCategoryName + ' - ' + this.CategoryName;
    }
    return TransactionModel;
}());
exports.TransactionModel = TransactionModel;
