import { NgModule } from '@angular/core';

import { FitWidthDirective } from './fit-width/fit-width.directive';
import { ObeyMinMaxDirective } from './obey-min-max/obey-min-max.directive';
import { AsteriskRequiredDirective } from './form-error/asterisk-required.directive';
import { ControlErrorContainerDirective } from './form-error/control-error-container.directive';
import { AutofocusDirective } from './autofocus.directive';
import { ControlErrorsDirective } from './form-error/control-errors.directive';
import { FormSubmitDirective } from './form-submit.directive';
import { ClickedOutsideDirective } from './clicked-outside/click-outside.directive';
import { DefaultImageOnErrorDirective } from './default-img-onerror/default-img-onerror.directive';
import { UrlToBase64Directive } from './url-to-base64/url-to-base64.directive';
import { ColorCodedDirective } from './color-coded/color-coded.directive';
import { ImageLoadErrorDirective } from './img-load-error/img-load-error.directive';
import { ClickStopPropagation } from './click-stop-propagation/click-stop-propagation.directive';
import { VisibilityDirective } from './visibility/visibility.directive';
import { NumberFormatterDirective } from './number-formatter/number-formatter.directive';

@NgModule({
  imports: [],
  declarations: [
    AsteriskRequiredDirective,
    AutofocusDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    FitWidthDirective,
    ObeyMinMaxDirective,
    ClickedOutsideDirective,
    DefaultImageOnErrorDirective,
    UrlToBase64Directive,
    ColorCodedDirective,
    ImageLoadErrorDirective,
    ClickStopPropagation,
    VisibilityDirective,
    NumberFormatterDirective
  ],
  exports: [
    AsteriskRequiredDirective,
    AutofocusDirective,
    ControlErrorsDirective,
    FormSubmitDirective,
    ControlErrorContainerDirective,
    FitWidthDirective,
    ObeyMinMaxDirective,
    ClickedOutsideDirective,
    DefaultImageOnErrorDirective,
    UrlToBase64Directive,
    ColorCodedDirective,
    ImageLoadErrorDirective,
    ClickStopPropagation,
    VisibilityDirective,
    NumberFormatterDirective
  ]
})
export class DirectivesModule { }