<section>
    <div class="d-flex flex-column shadowed-box-non-mobile section-content" id="customerMessageList"
        *ngIf="customerMessages && customerMessages.length > 0; else noMsg">
        <div class="emailMsgBox postion-relative" *ngFor="let message of customerMessages; let i = index"
            [ngClass]="{'read-already':message.Status == messageType.READ}">
            <div class="top-right-cornered" *ngIf="message.Status == messageType.UNREAD">
                <button class="btn btn-sm" (click)="markAsRead(message.CustomerMessageID)"><i
                        class="fas fa-check positiveIcon-sm pr-1"></i></button>
            </div>
            <div>
                <span class="text-muted">{{message.SentDate}}</span>
            </div>
            <div>
                <label class="text-muted">Subject:</label> <span class="font-weight-bold">{{message.Subject}}</span>
            </div>
            <div>
                <label class="text-muted">Message:</label> <span class="font-weight-bold">{{message.Message}}</span>
            </div>
            <div>
                <div>
                    <label class="text-muted">Name:</label> <span class="font-weight-bold">{{message.Name}}</span>
                </div>
                <div>
                    <label class="text-muted">Phone:</label> <span class="font-weight-bold">{{message.Phone}}</span>
                </div>
                <div>
                    <label class="text-muted">EmailID:</label> <span class="font-weight-bold">{{message.EmailID}}</span>
                </div>
            </div>

        </div>
    </div>
    <!-- No Msg -->
    <ng-template #noMsg>
        <div class="flxRowCntr msgBox" *ngIf="!isLoading">
            <span> No Msgs Found </span>
        </div>
    </ng-template>
</section>