"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableActionType = void 0;
var TableActionType;
(function (TableActionType) {
    TableActionType["LINK"] = "LINK";
    TableActionType["BUTTON"] = "BUTTON";
    TableActionType["ICON_VIEW"] = "ICON_VIEW";
    TableActionType["ICON_ADD"] = "ICON_ADD";
    TableActionType["ICON_EDIT"] = "ICON_EDIT";
    TableActionType["ICON_DELETE"] = "ICON_DELETE";
    TableActionType["TEXTBOX"] = "TEXTBOX";
})(TableActionType = exports.TableActionType || (exports.TableActionType = {}));
