<div class="popup-modal" *ngIf="showModal">
  <div class="modalContainer" [style.maxWidth]="maxWidth" clickedOutside (clickedOutsideEvent)="onClickedOutside()">
    <i class="far fa-times-circle top-right-cornered negativeIcon-sm p-1" (click)="onCancelClick($event);"></i>
    <!-- Title -->
    <div class="modalTitle text-center" *ngIf="title">
      <h5><i class="pr-1" [ngClass]="iconClass"></i>{{title}}</h5>
    </div>
    <!-- Body -->
    <div class="modalBody">
      <ng-content></ng-content>
    </div>
    <!-- Action -->
    <div class="modalAction">
      <button class="btn btn-outline-primary btn-sm mr-2 proceed-btn" (click)="onProceedClick($event);"
        *ngIf="showProceedBtn">
        {{proceedBtnTxt}} </button>

      <button class="btn btn-outline-danger btn-sm proceed-btn" (click)="onCancelClick($event);" *ngIf="showCancelBtn" autofocus
        appAutofocus>
        {{cancelBtnTxt}} </button>
    </div>
  </div>
</div>