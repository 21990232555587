"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BusinessInfoModel = void 0;
var BusinessInfoModel = /** @class */ (function () {
    function BusinessInfoModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(BusinessInfoModel.prototype, "PrimaryBankAccount", {
        get: function () {
            if (this.BankAccounts) {
                return this.BankAccounts.find(function (ba) { return ba.IsPrimary; }) || this.BankAccounts[0];
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    return BusinessInfoModel;
}());
exports.BusinessInfoModel = BusinessInfoModel;
