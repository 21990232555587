"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdditionalOrderItemsType = void 0;
var AdditionalOrderItemsType;
(function (AdditionalOrderItemsType) {
    AdditionalOrderItemsType["TRANSPORT_CHARGE"] = "TRANSPORT_CHARGE";
    AdditionalOrderItemsType["SERVICE_CHARGE"] = "SERVICE_CHARGE";
    AdditionalOrderItemsType["PACKAGING_CHARGE"] = "PACKAGING_CHARGE";
    AdditionalOrderItemsType["INSURANCE_CHARGE"] = "INSURANCE_CHARGE";
    AdditionalOrderItemsType["ADJUSTMENT"] = "ADJUSTMENT";
    AdditionalOrderItemsType["DISCOUNT"] = "DISCOUNT";
})(AdditionalOrderItemsType = exports.AdditionalOrderItemsType || (exports.AdditionalOrderItemsType = {}));
