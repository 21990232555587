"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopUpDialogModel = void 0;
var enum_1 = require("../../enum");
var PopUpDialogModel = /** @class */ (function () {
    function PopUpDialogModel(value) {
        this.showProceedBtn = true;
        this.showCancelBtn = true;
        this.proceedBtnText = "Ok";
        this.cancelBtnText = "Cancel";
        this.iconType = enum_1.AlertType.Info;
        this.closeOnClickOutisde = true;
        this.minWidth = '20vw';
        this.maxWidth = '80vw';
        this.proceedClickWaitTime = 0;
        Object.assign(this, value);
    }
    return PopUpDialogModel;
}());
exports.PopUpDialogModel = PopUpDialogModel;
