"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactModel = void 0;
var ContactModel = /** @class */ (function () {
    function ContactModel(value) {
        Object.assign(this, value);
    }
    return ContactModel;
}());
exports.ContactModel = ContactModel;
