"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataExcelConfigModel = void 0;
var DataExcelConfigModel = /** @class */ (function () {
    function DataExcelConfigModel(value) {
        Object.assign(this, value);
    }
    return DataExcelConfigModel;
}());
exports.DataExcelConfigModel = DataExcelConfigModel;
