"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressType = void 0;
var AddressType;
(function (AddressType) {
    AddressType[AddressType["Home"] = 1] = "Home";
    AddressType[AddressType["Office"] = 2] = "Office";
    AddressType[AddressType["Billing"] = 3] = "Billing";
    AddressType[AddressType["Shipping"] = 4] = "Shipping";
})(AddressType = exports.AddressType || (exports.AddressType = {}));
