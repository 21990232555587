import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';

import { environment } from 'src/environments/environment';
import { map } from 'rxjs/internal/operators/map';
import { APICallOptionsModel, ResponseModel, Utilities } from 'eccommons';

@Injectable({
  providedIn: 'root',
})
export class HttpHelperService {

  /* Constructor
  ************************************************/
  constructor(private readonly httpClient: HttpClient) { }

  public post<T, R>(endPoint: string, item?: T, showLoader: boolean = true): Observable<ResponseModel<R>> {
    return this.httpClient.post(`${environment.ec_api_endPoint}/${endPoint}`, item, { params: this.getParams(showLoader) })
      .pipe(map(res => new ResponseModel<R>(res)));
  }

  public postWithOptions<R>(options: APICallOptionsModel): Observable<ResponseModel<R>> {
    options = new APICallOptionsModel(options);
    let api_target_base = this.getApiTargetBase(options.apiTarget);

    return this.httpClient.post(`${api_target_base}/${options.endPoint}`, options.dataToSend, { params: this.getParams(options.showLoader) })
      .pipe(map(res => new ResponseModel<R>(res)));
  }

  public put<T, R>(endPoint: string, item: T, showLoader: boolean = true): Observable<ResponseModel<R>> {
    return this.httpClient.put(`${environment.ec_api_endPoint}/${endPoint}`, item, { params: this.getParams(showLoader) }).pipe(map(res => new ResponseModel<R>(res)));;
  }

  public putWithOptions<R>(options: APICallOptionsModel): Observable<ResponseModel<R>> {
    options = new APICallOptionsModel(options);
    let api_target_base = this.getApiTargetBase(options.apiTarget);

    return this.httpClient.put(`${api_target_base}/${options.endPoint}`, options.dataToSend, { params: this.getParams(options.showLoader) })
      .pipe(map(res => new ResponseModel<R>(res)));
  }

  public getByKey<R>(endPoint: string, key: string, value: string | number, showLoader: boolean = true): Observable<ResponseModel<R>> {
    return this.httpClient.get(
      `${environment.ec_api_endPoint}/${endPoint}?${key}=${value}`, { params: this.getParams(showLoader) }).pipe(map(res => new ResponseModel<R>(res)));
  }

  public get<R>(endPoint: string, showLoader: boolean = true): Observable<ResponseModel<R>> {
    return this.httpClient.get(`${environment.ec_api_endPoint}/${endPoint}`, { params: this.getParams(showLoader) }).pipe(map(res => new ResponseModel<R>(res)));
  }

  public getWithOptions<R>(options: APICallOptionsModel): Observable<ResponseModel<R>> {
    options = new APICallOptionsModel(options);
    let api_target_base = this.getApiTargetBase(options.apiTarget);

    return this.httpClient.get(`${api_target_base}/${options.endPoint}`, { params: this.getParams(options.showLoader, options.dataToSend) })
      .pipe(map(res => new ResponseModel<R>(res)));
  }

  public delete<R>(options: APICallOptionsModel): Observable<ResponseModel<R>> {
    options = new APICallOptionsModel(options);
    let api_target_base = this.getApiTargetBase(options.apiTarget);

    return this.httpClient.delete(`${api_target_base}/${options.endPoint}`,
      { body: options.dataToSend, params: this.getParams(options.showLoader) })
      .pipe(map(res => new ResponseModel<R>(res)));
  }



  private getParams(showLoader: boolean, dataToSend?: any): any {
    let params = { showLoader: String(showLoader) };

    //If dataToSend is an object, then convert all properties to 
    //key-value pairs and add them to params
    if (dataToSend && typeof dataToSend === 'object') {
      Object.keys(dataToSend).forEach(key => {
        if (Utilities.hasValue(dataToSend[key]))
          params[key] = String(dataToSend[key]);
      });
    }

    return params;
  }

  private getApiTargetBase(apiTarget: string): string {
    if (apiTarget == 'site-api')
      return environment.site_api_endPoint;
    else
      return environment.ec_api_endPoint;
  }
}
