"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserModel = void 0;
var UserModel = /** @class */ (function () {
    function UserModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(UserModel.prototype, "HasAddress", {
        get: function () {
            return this.Address && this.Address.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    return UserModel;
}());
exports.UserModel = UserModel;
