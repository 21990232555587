"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utilities = void 0;
var enum_1 = require("../enum");
var Utilities = /** @class */ (function () {
    function Utilities() {
    }
    Utilities.parseNumber = function (value, decimalPlaces, canIgnoreDecimalZeros) {
        if (canIgnoreDecimalZeros === void 0) { canIgnoreDecimalZeros = true; }
        if (!value && value != 0) {
            return null;
        }
        var numValue = Number(value);
        if (isNaN(numValue))
            return null;
        else if (decimalPlaces != null) {
            if (canIgnoreDecimalZeros)
                return parseFloat(numValue.toFixed(decimalPlaces));
            return Number(numValue.toFixed(decimalPlaces));
        }
        else
            return numValue;
    };
    Utilities.parseBoolean = function (value) {
        if (value && value != "0") {
            return JSON.parse(value) ? true : false;
        }
        return false;
    };
    Utilities.isWholeNumber = function (value) {
        value = this.parseNumber(value);
        return value % 1 == 0;
    };
    Utilities.hasValue = function (value) {
        if (value && value != 'null' && value != 'undefined')
            return true;
        else
            return false;
    };
    Utilities.isValueTruthy = function (value) {
        if (this.hasValue(value) && String(value).toLowerCase() != 'no' && value != '0' &&
            String(value).toLowerCase() != 'false' &&
            // It should not be a number OR if it is then should be greater than 0
            (!this.parseNumber(value) || this.parseNumber(value) > 0))
            return true;
        else
            return false;
    };
    Utilities.getValueOrNull = function (value) {
        if (this.isValueTruthy(value))
            return value;
        else
            return null;
    };
    Utilities.discountDesc = function (sellingPrice, deductionType, deductionValue, marketPrice) {
        var discountDesc = '';
        if (this.hasValue(deductionValue) && !marketPrice) {
            marketPrice = this.computeMarketPrice(sellingPrice, deductionType, deductionValue);
        }
        else if (this.parseNumber(marketPrice) > 0 && !this.hasValue(deductionValue)) {
            deductionValue = this.computeDiscountValue(marketPrice, sellingPrice, deductionType);
        }
        if (this.hasValue(deductionValue) || (this.parseNumber(marketPrice) > 0 && marketPrice > sellingPrice)) {
            if (deductionType === enum_1.DeductionType.PERCENT) {
                discountDesc = "".concat(deductionValue, "% discount : &nbsp;<del>").concat(marketPrice, "\u20B9</del> &nbsp; <strong> ").concat(sellingPrice, "\u20B9 </strong>");
            }
            else if (deductionType === enum_1.DeductionType.AMOUNT) {
                discountDesc = "Flat ".concat(deductionValue, "\u20B9 discount : &nbsp; <del>").concat(marketPrice, "\u20B9</del> &nbsp; <strong> ").concat(sellingPrice, "\u20B9 </strong>");
            }
        }
        return discountDesc;
    };
    Utilities.computeDiscountValue = function (marketPrice, sellingPrice, deductionType) {
        var priceDifference = marketPrice - sellingPrice;
        var discountValue = null;
        switch (deductionType) {
            case enum_1.DeductionType.PERCENT:
                discountValue = Math.round((priceDifference / marketPrice) * 100);
                break;
            case enum_1.DeductionType.AMOUNT:
                discountValue = Math.round(priceDifference);
                break;
        }
        return discountValue;
    };
    /**
     * Computes MRP price from selling price by removing the discount.
     * @param sellingPrice
     * @param deductionType
     * @param discountValue
     * @returns mrp price - which is selling price without any discount.
     */
    Utilities.computeMarketPrice = function (sellingPrice, deductionType, discountValue) {
        var marketPrice = null;
        sellingPrice = Utilities.parseNumber(sellingPrice);
        discountValue = Utilities.parseNumber(discountValue);
        switch (deductionType) {
            case enum_1.DeductionType.PERCENT:
                marketPrice = (sellingPrice / (100 - discountValue)) * 100;
                break;
            case enum_1.DeductionType.AMOUNT:
                marketPrice = sellingPrice + discountValue;
                break;
        }
        return this.parseTo2DecPlaces(marketPrice);
    };
    Utilities.percentageIncrease = function (value, percentage) {
        value = this.parseNumber(value);
        percentage = this.parseNumber(percentage);
        return this.parseTo2DecPlaces(value + value * (percentage / 100));
    };
    /**
     * Fuzzy search looks for key in value, where order matters but can have extra characters in between.
     * @param value Target string to search in
     * @param key key to search
     * @param level It has 4 levels where 4 being exact match, 3 being 75% match, 2 being 50% match and 1 being 25% match.
     * @returns Rank of matched value. 0 means no match. Rank is in decimal format.
     * Remember to reorder the searched list by rank.
     */
    Utilities.fuzzySearch = function (value, key, level) {
        var _a, _b;
        if (level === void 0) { level = 3; }
        value = (_a = this.removeWhiteSpace(String(value))) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        key = (_b = this.removeWhiteSpace(String(key))) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        if (this.hasValue(value) && this.hasValue(key)) {
            // Loops through and checks if the key is present in the value (order matters but can have intermediate characters)
            var j = 0;
            for (var i = 0; i < value.length; i++) {
                if (value[i] == key[j])
                    j++;
                if (j == key.length)
                    break;
            }
            /* level * 0.25 * key.length gives the minimum matching characters required to be present in the value.
               ex: level 3 and key length 5, then 3 * 0.25 * 5 = 3.75, so minimum 4 characters should be present in the value.
            */
            if (j >= level * 0.25 * key.length) {
                /* key.length/value.length is to prioritize strings with exact match.
                   ex: Key = 'abc' and value = 'abcde', then 3/5 = 0.6,
                        for another value = 'abc' then 3/3 = 1 so 'abc' will be ranked higher than 'abcde'.
                */
                return (key.length / value.length);
            }
        }
        return 0;
    };
    Utilities.hasKey = function (value, key, matchPartial, minPartialMatch) {
        var _this = this;
        var _a, _b;
        value = (_a = this.removeWhiteSpace(String(value))) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        key = (_b = this.removeWhiteSpace(key)) === null || _b === void 0 ? void 0 : _b.toLowerCase();
        if (value && key && value.includes(key))
            return true;
        if (matchPartial) {
            var subStrings = this.getSubstrings(key, key.length);
            var match_1 = false;
            subStrings.forEach(function (s) {
                if (!match_1 && s.length >= minPartialMatch && value.includes(_this.removeWhiteSpace(s)))
                    match_1 = true;
            });
            return match_1;
        }
        return false;
    };
    Utilities.removeWhiteSpace = function (value) {
        return value === null || value === void 0 ? void 0 : value.replace(/\s/g, "");
    };
    Utilities.isStringsEqual = function (value1, value2, removeWhiteSpace) {
        if (removeWhiteSpace === void 0) { removeWhiteSpace = true; }
        value1 = String(value1).toLowerCase();
        value2 = String(value2).toLowerCase();
        if (removeWhiteSpace) {
            value1 = this.removeWhiteSpace(value1);
            value2 = this.removeWhiteSpace(value2);
        }
        return value1.trim() == value2.trim();
    };
    Utilities.getSubstrings = function (str, n) {
        var substrings = [];
        for (var i = 0; i < n; i++) {
            for (var len = 1; len <= n - i; len++) {
                substrings.push(str.substring(i, len));
            }
        }
        return substrings;
    };
    Utilities.isSmallScreenDevice = function () {
        return (window.innerWidth <= 420);
    };
    Utilities.appendToFileName = function (filePath, appendString) {
        var extensionStrt = filePath.lastIndexOf('.');
        var fileNameWOExtension = filePath.slice(0, extensionStrt);
        var extension = filePath.slice(extensionStrt + 1, filePath.length);
        return "".concat(fileNameWOExtension).concat(appendString, ".").concat(extension);
    };
    Utilities.isToday = function (someDate) {
        if (!this.isDate(someDate))
            return false;
        someDate = new Date(someDate);
        var today = new Date();
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear();
    };
    Utilities.isDate = function (date) {
        return (new Date(date).toDateString() !== "Invalid Date") && !isNaN(Date.parse(date));
    };
    Utilities.dateBeforeXDays = function (date, days) {
        var newDate = new Date(date);
        newDate.setDate(date.getDate() - days);
        return newDate;
    };
    /**
     * Convers yyyy-mm-dd to dd/mm/yyyy
     * @param dbDate
     */
    Utilities.convertDBDateToLocal = function (dbDate) {
        var datePart = String(dbDate).match(/\d+/g);
        return datePart[2] + '/' + datePart[1] + '/' + datePart[0];
    };
    /**
    * Converts dd/mm/yyyy to yyyy-mm-dd
    * @param dbDate
    */
    Utilities.convertLocalDateToDB = function (dbDate) {
        var strDateValue = dbDate;
        if (typeof (dbDate) === 'object') {
            strDateValue = dbDate.toLocaleDateString();
        }
        var datePart = String(strDateValue).match(/\d+/g);
        return datePart[2] + '-' + datePart[1] + '-' + datePart[0];
    };
    Utilities.convertUTCToLocalDate = function (date) {
        if (!date)
            return null;
        if (date[date.length - 1].toLowerCase() != 'z')
            date += 'Z';
        return new Date(date);
    };
    Utilities.parseTo2DecPlaces = function (value) {
        var number = this.parseNumber(value);
        if (number)
            return this.parseNumber(number.toFixed(2));
    };
    Utilities.stringToEnum = function (enumObj, value) {
        var key = Object.keys(enumObj).find(function (k) { return k.toString().toLowerCase() === value.toLowerCase(); });
        return enumObj[key];
    };
    Utilities.valueToEnumName = function (enumObj, value) {
        if (!value)
            return '';
        return Object.keys(enumObj).find(function (key) { return typeof key === 'string' && String(enumObj[key]).toLowerCase() === String(value).toLowerCase(); });
    };
    Utilities.getAllEnumNames = function (enumObj) {
        return Object.keys(enumObj).filter(function (k) { return !parseInt(k, 10); });
    };
    Utilities.getAvailabilityType = function (value) {
        value = Utilities.parseNumber(value);
        if (value == null)
            return enum_1.AvailabilityType.Unlimited;
        else if (value <= 0)
            return enum_1.AvailabilityType.Out_Of_Stock;
        else if (value > 0)
            return enum_1.AvailabilityType.Stocked;
    };
    Utilities.getAvailableQty = function (availableQty) {
        if (availableQty == "UNLIMITED")
            return null;
        else if (availableQty == "OUT_OF_STOCK")
            return 0;
        else
            return Utilities.parseNumber(availableQty);
    };
    Utilities.notLessThanValidationFn = function (field) {
        return function (control) {
            var group = control.parent;
            var valueOfFieldToCompare = Number(group.get(field).value);
            var isLessThan = valueOfFieldToCompare > Number(control.value);
            return isLessThan ? { 'lessThan': { value: control.value, targetField: field } } : null;
        };
    };
    Utilities.notGreaterThanValidationFn = function (field) {
        return function (control) {
            var group = control.parent;
            var valueOfFieldToCompare = Number(group.get(field).value);
            var isGreaterThan = Number(control.value) > valueOfFieldToCompare;
            return isGreaterThan ? { 'greaterThan': { value: control.value, targetField: field } } : null;
        };
    };
    Utilities.onlyPositiveIntegerValidationFn = function () {
        var _this = this;
        return function (control) {
            if (_this.hasValue(control.value) && !/^\+?(0|[1-9]\d*)$/.test(control.value))
                return { 'shouldBePositiveInteger': { value: control.value } };
            return null;
        };
    };
    Utilities.onlyNonZeroNumber = function (data) {
        data = Utilities.parseNumber(data);
        if (!data)
            return null;
        else
            return data;
    };
    Utilities.absNumber = function (data) {
        data = Utilities.parseNumber(data);
        if (!data)
            return null;
        else
            return Math.abs(data);
    };
    Utilities.findNextIndex = function (currnetIndex, totalItems) {
        var nextIndex = currnetIndex + 1;
        if (nextIndex >= totalItems)
            nextIndex = 0;
        return nextIndex;
    };
    Utilities.getRandomColor = function () {
        return "hsl(" + Math.random() * 360 + ", 100%, 75%)";
    };
    Utilities.capitalizeFirstWord = function (str) {
        if (str)
            return str.charAt(0).toUpperCase() + str.slice(1);
        else
            return str;
    };
    // Creates a heirarchy tree from given node by taking property names to check as inputs.
    Utilities.createTreeStructure = function (node, dataList, idRefPropName, childRefPropName, parentRefPropName) {
        var _this = this;
        if (!node)
            node = {};
        node[childRefPropName] = dataList.filter(function (d) { return d[parentRefPropName] == node[idRefPropName]; })
            .map(function (ch) { return _this.createTreeStructure(ch, dataList, idRefPropName, childRefPropName, parentRefPropName); });
        return node;
    };
    Utilities.computeSum = function (dataList, propName) {
        var sum = 0;
        for (var i = 0; i < (dataList === null || dataList === void 0 ? void 0 : dataList.length); i++)
            sum += this.parseNumber(dataList[i][propName]);
        return sum;
    };
    Utilities.isObjectHasValue = function (obj, value) {
        var hasValue = false;
        for (var prop in obj) {
            if (obj[prop]) {
                if (typeof (obj[prop]) == 'object') {
                    return this.isObjectHasValue(obj[prop], value);
                }
                else if (this.hasKey(String(obj[prop]), value)) {
                    hasValue = true;
                    break;
                }
            }
        }
        return hasValue;
    };
    Utilities.isValidEmailID = function (emailID) {
        var regex = new RegExp(Utilities.emailIDPattern);
        return (emailID && regex.test(emailID));
    };
    Utilities.isValidMobileNo = function (phoneNumber) {
        var regex = new RegExp(Utilities.mobileNumberPattern);
        return (phoneNumber && regex.test(phoneNumber));
    };
    Utilities.getWhatsAppClickToChatLink = function (phoneNumber, message) {
        if (phoneNumber.length == 10)
            phoneNumber = '91' + phoneNumber;
        message = encodeURIComponent(message);
        return "https://api.whatsapp.com/send?phone=".concat(phoneNumber, "&text=").concat(message);
    };
    Utilities.getReceiptName = function (receiptGroup) {
        switch (Number(receiptGroup)) {
            case enum_1.ReceiptGroupEnum.INVOICE:
                return 'Invoice';
            case enum_1.ReceiptGroupEnum.QUOTATION:
                return 'Quotation';
            case enum_1.ReceiptGroupEnum.PURCHASE_ORDER:
                return 'Purchase Order';
            case enum_1.ReceiptGroupEnum.DELIVERY_CHALLAN:
                return 'Delivery Challan';
            case enum_1.ReceiptGroupEnum.CREDIT_NOTE:
                return 'Credit Note';
        }
    };
    Utilities.getReceiptFolderName = function (receiptGroup) {
        switch (Number(receiptGroup)) {
            case enum_1.ReceiptGroupEnum.INVOICE:
                return 'Invoice';
            case enum_1.ReceiptGroupEnum.QUOTATION:
                return 'Quotation';
            case enum_1.ReceiptGroupEnum.PURCHASE_ORDER:
                return 'PurchaseOrder';
            case enum_1.ReceiptGroupEnum.DELIVERY_CHALLAN:
                return 'DeliveryChallan';
            case enum_1.ReceiptGroupEnum.CREDIT_NOTE:
                return 'CreditNote';
        }
    };
    Utilities.chunkString = function (str, length) {
        return str.match(new RegExp('.{1,' + length + '}', 'g'));
    };
    Utilities.timeout = function (ms) {
        return new Promise(function (res) { return setTimeout(res, ms); });
    };
    Utilities.deepCopyArray = function (arrObject, type) {
        if (arrObject && arrObject.length)
            return JSON.parse(JSON.stringify(arrObject)).map(function (arrItem) { return new type(arrItem); });
        return null;
    };
    Utilities.deepCopyArrayWithoutType = function (arrObject) {
        if (arrObject && arrObject.length)
            return JSON.parse(JSON.stringify(arrObject)).map(function (arrItem) { return arrItem; });
        return null;
    };
    Utilities.deepCopy = function (srcObject, type) {
        if (srcObject)
            return new type(JSON.parse(JSON.stringify(srcObject)));
        return null;
    };
    Utilities.IsChrome = function () {
        // please note, 
        // that IE11 now returns undefined again for window.chrome
        // and new Opera 30 outputs true for window.chrome
        // but needs to check if window.opr is not undefined
        // and new IE Edge outputs to true now for window.chrome
        // and if not iOS Chrome check
        // so use the below updated condition
        var isChromium = window.chrome;
        var winNav = window.navigator;
        var vendorName = winNav.vendor;
        var isOpera = typeof window.opr !== "undefined";
        var isIEedge = winNav.userAgent.indexOf("Edg") > -1;
        var isIOSChrome = winNav.userAgent.match("CriOS");
        if (isIOSChrome) {
            // is Google Chrome on IOS
            return false;
        }
        if (isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false) {
            // is Google Chrome
            return true;
        }
        return false;
    };
    Utilities.combineArray = function (arr1, arr2) {
        arr1.push.apply(arr1, arr2);
    };
    Utilities.utcToLocal = function (utcDateTime) {
        if (!utcDateTime)
            return null;
        return new Date(utcDateTime).toLocaleString();
    };
    /**
     * To remove extra commas from comma separated string
     * @param str To remove extra commas from string
     * @returns string without a leading and trailing comma
     */
    Utilities.removeExtraCommas = function (str) {
        if (!str)
            return null;
        if (str[0] == ',')
            str = str.substring(1);
        if (str[str.length - 1] == ',')
            str = str.substring(0, str.length - 1);
        return str;
    };
    /**
     * To get alphabet by position
     * @param position on which alphabet is required. It is 1 based index.
     * @returns alphabet at the given position
     */
    Utilities.getAlphabetByPosition = function (position, caps) {
        if (caps === void 0) { caps = 'UPPER'; }
        if (!position)
            return null;
        var baseCode = 65;
        if (caps == 'LOWER')
            baseCode = 97;
        return String.fromCharCode(baseCode + (position - 1));
    };
    /**
     * To check whether the file is an valid excel file or not
     * @param file file to check the validity
     * @returns true if file is valid excel file
     */
    Utilities.isValidExcelFile = function (file) {
        if (!file)
            return false;
        var ext = file.name.split('.').pop();
        return ((ext == 'xls' || ext == 'xlsx') &&
            (file.type == 'application/vnd.ms-excel'
                || file.type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'));
    };
    Utilities.AlphanumericValidation = function (value) {
        if (value) {
            var regex = new RegExp(Utilities.AlphanumbericPattern);
            var data = value.replace(regex, '');
            return data;
        }
        else {
            return null;
        }
    };
    Utilities.AlphabetValidation = function (value) {
        if (value) {
            var regex = new RegExp(Utilities.AlphabetValidationPattern);
            var data = value.replace(regex, '');
            return data;
        }
        else {
            return null;
        }
    };
    Utilities.onlyNumberValidationPattern = "^[0-9]*$";
    Utilities.emailIDPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,20}$/;
    Utilities.mobileNumberPattern = "^[1-9]\\d{9}$";
    Utilities.AlphanumbericPattern = /[^a-zA-Z0-9]/g;
    Utilities.AlphabetValidationPattern = /[^a-zA-Z' ']/g;
    return Utilities;
}());
exports.Utilities = Utilities;
