"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentSettingsModel = void 0;
var utils_1 = require("../../utils");
var commons_1 = require("../commons");
var PaymentSettingsModel = /** @class */ (function (_super) {
    __extends(PaymentSettingsModel, _super);
    function PaymentSettingsModel(value) {
        var _this = _super.call(this, value) || this;
        Object.assign(_this, value);
        _this.IsOnlyEnquiry = utils_1.Utilities.parseBoolean(_this.IsOnlyEnquiry);
        _this.IsCODAllowed = utils_1.Utilities.parseBoolean(_this.IsCODAllowed);
        _this.IsNetBankingAllowed = utils_1.Utilities.parseBoolean(_this.IsNetBankingAllowed);
        _this.IsDirectBankTransferAllowed = utils_1.Utilities.parseBoolean(_this.IsDirectBankTransferAllowed);
        _this.IsMobileBankingAllowed = utils_1.Utilities.parseBoolean(_this.IsMobileBankingAllowed);
        _this.IsPartyWalletAllowed = utils_1.Utilities.parseBoolean(_this.IsPartyWalletAllowed);
        _this.IsPromocodeEnabled = utils_1.Utilities.parseBoolean(_this.IsPromocodeEnabled);
        _this.MinOrderValue = utils_1.Utilities.parseNumber(_this.MinOrderValue, 2);
        if (_this.MobileBankInfo) {
            var mobileBankinfo = _this.MobileBankInfo.split(':');
            if (mobileBankinfo && mobileBankinfo.length >= 2) {
                _this.MobileBankPhoneNo = mobileBankinfo[0];
                _this.MobileBankName = mobileBankinfo[1];
            }
        }
        return _this;
    }
    return PaymentSettingsModel;
}(commons_1.BaseModel));
exports.PaymentSettingsModel = PaymentSettingsModel;
