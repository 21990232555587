"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExcelHeaderDisplayConfigModel = void 0;
var ExcelHeaderDisplayConfigModel = /** @class */ (function () {
    function ExcelHeaderDisplayConfigModel(value) {
        var _this = this;
        Object.assign(this, value);
        console.log('on creation');
        if (this.Children) {
            this.Children.forEach(function (child) {
                child.BgColor = _this.BgColor;
                child.FgColor = _this.FgColor;
            });
        }
    }
    Object.defineProperty(ExcelHeaderDisplayConfigModel.prototype, "depth", {
        get: function () {
            var depth = 0;
            var currentChildren = this.Children;
            while (currentChildren && currentChildren.length > 0) {
                depth++;
                currentChildren = currentChildren[0].Children;
            }
            return depth;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ExcelHeaderDisplayConfigModel.prototype, "leafNodes", {
        get: function () {
            var leafNodes = [];
            if (this.Children && this.Children.length > 0) {
                this.Children.forEach(function (child) {
                    leafNodes = leafNodes.concat(child.leafNodes);
                });
            }
            else {
                leafNodes.push(this);
            }
            return leafNodes;
        },
        enumerable: false,
        configurable: true
    });
    return ExcelHeaderDisplayConfigModel;
}());
exports.ExcelHeaderDisplayConfigModel = ExcelHeaderDisplayConfigModel;
