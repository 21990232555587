"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AboutUsModel = void 0;
var AboutUsModel = /** @class */ (function () {
    function AboutUsModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(AboutUsModel.prototype, "hasWWHContent", {
        get: function () {
            if (this.WhoWeAre || this.WhatWeDo || this.HowWeDo)
                return true;
            return false;
        },
        enumerable: false,
        configurable: true
    });
    return AboutUsModel;
}());
exports.AboutUsModel = AboutUsModel;
