"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAppSiteSettingsModel = void 0;
var utilities_1 = require("../../utils/utilities");
var UserAppSiteSettingsModel = /** @class */ (function () {
    function UserAppSiteSettingsModel(value) {
        this.IsSiteUnderMaintenance = false;
        this.IsAllowOrder = true;
        Object.assign(this, value);
        if (utilities_1.Utilities.hasValue(this.IsSiteUnderMaintenance))
            this.IsSiteUnderMaintenance = utilities_1.Utilities.parseBoolean(this.IsSiteUnderMaintenance);
        else
            this.IsSiteUnderMaintenance = false; // default value
        if (utilities_1.Utilities.hasValue(this.IsAllowOrder))
            this.IsAllowOrder = utilities_1.Utilities.parseBoolean(this.IsAllowOrder);
        else
            this.IsAllowOrder = true; // default value
    }
    return UserAppSiteSettingsModel;
}());
exports.UserAppSiteSettingsModel = UserAppSiteSettingsModel;
