/*** Core ****/
import { Component, OnDestroy, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

/*** Libraries ****/
import { Subscription } from 'rxjs/internal/Subscription';
import { take } from 'rxjs/internal/operators/take';
import { AdminUser } from 'eccommons';

/*** Services ***/
import { AccountService } from 'src/app/account/service/account.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TopBarComponent implements OnInit, OnDestroy {

  title: string;
  profilePicURL: string;
  defaultPartyLogoImgPath: string;
  showProfileMenu: boolean = false;
  user: AdminUser;

  private subscriptions = new Subscription();

  /*** Constructor ****/
  constructor(private sharedService: SharedService, private appSettings: AppSettings,
    private accountService: AccountService, private readonly router: Router,) { }

  /*** Lifecycle Events ****/
  // On Init  
  ngOnInit(): void {
    this.profilePicURL = this.appSettings.businessLogoImgPath;
    this.defaultPartyLogoImgPath = this.appSettings.partyDefaultLogoImgPath();

    // Subscribe to page title
    this.subscriptions.add(this.sharedService.pageTitleSubject.subscribe(title => {
      this.title = title;
    }));

    this.user = this.accountService.user;
  }

  // On Destroy
  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /*** Events ***/
  closeProfileMenu() {
    this.showProfileMenu = false;
  }

  logout() {
    this.sharedService.showConfirmDialog('Confirm', 'Are you sure want to logout?')
      .pipe(take(1)).subscribe(res => {
        if (res) {
          this.accountService.logOutUser();
          this.router.navigate(['/login']);
        }
      });
  }

}
