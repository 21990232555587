import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

import { BehaviorSubject, forkJoin, Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { map } from 'rxjs/internal/operators/map';
import { take } from 'rxjs/operators';

import {
  AboutUsModel, AddressModel, AlertType, BankAccountModel, BasicInfoModel,
  BusinessInfoModel, CommunicationModel, ContactModel, Params, SocialMedia, UrlModel,
  PopUpDialogModel,
  SiteSettingsModel,
  BusinessSiteInfoModel
} from 'eccommons';

import { AlertService } from './alert.service';
import { HttpHelperService } from './http-helper.service';
import { AppSettings } from './appSettings.services';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  showLoaderCount: number = 0;
  showLoaderSubject: Subject<boolean> = new Subject<boolean>();
  public popUpDialogSubject: Subject<PopUpDialogModel> = new Subject<PopUpDialogModel>();
  public popUpDialogResponseSubject: Subject<boolean> = new Subject<boolean>();
  public popupModalShowOpenSubject: Subject<number> = new Subject<number>();
  public pageTitleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public currentScreenSize: "web" | "mobile";

  private businessInfoCache: Observable<BusinessInfoModel>;
  private businessSiteInfoCache: Observable<BusinessSiteInfoModel>;
  private gotoURL: UrlModel = null;

  /* Constructor
  ***************************************************/
  constructor(private alert: AlertService, private httpHelper: HttpHelperService,
    private appSettings: AppSettings) {
  }

  public notify(type: AlertType, msg: string): void {
    if (type === AlertType.Success)
      this.alert.success(msg);
    else if (type === AlertType.Error)
      this.alert.error(msg);
    else if (type === AlertType.Warning)
      this.alert.warn(msg);
    else if (type === AlertType.Info)
      this.alert.info(msg);
  }

  public notifyWarning(msg: string): void {
    this.notify(AlertType.Warning, msg);
  }

  getBusinessBasicInfo(): Observable<BusinessInfoModel> {
    if (!this.businessInfoCache) {
      let $businessInfo = this.httpHelper.get(this.appSettings.URL_GetBusinessBasicInfo);
      let $bankAccountInfo = this.httpHelper.get(this.appSettings.URL_GetBankAccountDetails);
      this.businessInfoCache = forkJoin([$businessInfo, $bankAccountInfo])
        .pipe(shareReplay(1), map(res => {
          if (res && res[0].Data) {
            let basicInfo = res[0].Data;
            let bankInfo = res[1].Data[0];
            return this.constructBusinessInfo(basicInfo[0][0], basicInfo[1][0], basicInfo[2][0], basicInfo[3][0],
              basicInfo[4][0], basicInfo[5], bankInfo)
          }
          return null;
        }
        ));
    }

    return this.businessInfoCache;
  }

  getBusinessSiteInfo(): Observable<BusinessSiteInfoModel> {
    if (!this.businessSiteInfoCache) {
      let apiCallOptions = {
        apiTarget: 'site-api',
        endPoint: this.appSettings.URL_GetBusinessSiteInfo,
      };
      
      this.businessSiteInfoCache = this.httpHelper.getWithOptions<BusinessSiteInfoModel>(apiCallOptions)
        .pipe(shareReplay(1), map(res => {
          return new BusinessSiteInfoModel(res.Data);
        }));
    }

    return this.businessSiteInfoCache;
  }

  getFromLocalStorage<T>(name: string): T {
    const content = localStorage.getItem(name);
    if (content) {
      return JSON.parse(content) as T;
    }
    return null;
  }

  saveToLocalStorage(name: string, value: any): void {
    if (value === null || value === undefined) {
      localStorage.setItem(name, null);
    }
    else if (typeof value === 'string')
      localStorage.setItem(name, value);
    else
      localStorage.setItem(name, JSON.stringify(value));
  }

  removeFromLocalStorage(name: string): void {
    if (name) {
      localStorage.removeItem(name);
    }
  }

  showLoader() {
    this.showLoaderCount++;
    if (this.showLoaderCount > 0)
      this.showLoaderSubject.next(true);
  }

  hideLoader() {
    this.showLoaderCount--;
    if (this.showLoaderCount < 1) {
      this.showLoaderCount = 0;
      this.showLoaderSubject.next(false);
    }
  }

  saveGotoUrl(url: string, qparam?: Params) {
    this.gotoURL = new UrlModel({
      url: url,
      qparams: qparam
    });
  }

  removeGotoUrl() {
    this.gotoURL = null;
  }

  getGotoUrl(): UrlModel {
    if (this.gotoURL)
      return this.gotoURL;
    else
      return null;
  }


  showPopUpDialog(popUpDialogDetails: PopUpDialogModel): Observable<boolean> {
    this.popUpDialogSubject.next(popUpDialogDetails);
    return this.popUpDialogResponseSubject;
  }

  showConfirmDialog(title: string, msg: string, confirmBtnTxt: string = 'Yes', cancelBtnTxt: string = 'No'): Observable<boolean> {
    let dialogDetails = new PopUpDialogModel({
      title: title,
      message: msg,
      proceedBtnText: confirmBtnTxt,
      cancelBtnText: cancelBtnTxt,
      iconType: AlertType.Warning
    });
    return this.showPopUpDialog(dialogDetails).pipe(take(1));
  }


  getHighlightedText(text: string, key: string) {
    if (text) {
      key = key.toLowerCase();
      let index = text.toLowerCase().indexOf(key);
      if (index >= 0) {
        key = text.slice(index, index + key.length);
        text = text.replace(key, '<mark>' + key + '</mark>');
      }
    }
    return text;
  }

  clearBusinessInfoCache() {
    this.businessInfoCache = null;
  }

  cleargetBusinessSiteInfoCache() {
    this.businessSiteInfoCache = null;
  }

  validateForm(frm: UntypedFormGroup) {
    let errors = this.formErrors(frm);
    if (errors) {
      let errSummary = `Please correct errors: <br>`;
      errSummary += this.getErrorMessage(errors)?.msg;

      if (errSummary)
        this.notify(AlertType.Error, errSummary);
    }
  }

  formErrors(form: AbstractControl) {
    if (form instanceof UntypedFormControl) {
      let errors = form.errors;
      if (errors && form['errLable']) {
        Object.defineProperty(errors, 'errLable', { value: form['errLable'] });
      }
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof UntypedFormGroup || form instanceof UntypedFormArray) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach(key => {
        // Recursive call of the FormGroup fields
        const error = this.formErrors(form.get(key));
        // Only add error if not null
        if (error !== null) {
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  deleteFile(filePath: string) {
    const dataToSend = {
      "FilePath": filePath,
    };
    return this.httpHelper.post(this.appSettings.URL_DeleteFile, dataToSend).pipe(map(res => res.Data));
  }

  private constructBusinessInfo(basicInfo: BasicInfoModel, aboutUs: AboutUsModel,
    siteSettings: SiteSettingsModel, address: AddressModel,
    communication: CommunicationModel, socialMedia: Array<SocialMedia>, bankAccounts: BankAccountModel[]): BusinessInfoModel {
    let businessInfo = new BusinessInfoModel({
      BasicInfo: new BasicInfoModel(basicInfo),
      AboutInfo: new AboutUsModel(aboutUs),
      SiteSettings: new SiteSettingsModel(siteSettings),
      ContactInfo: this.constructContactInfo(address, communication, socialMedia),
      BankAccounts: bankAccounts?.map(ba => new BankAccountModel(ba, this.appSettings.bankAccountQRImgPath(ba.BankAccountID)))
    })

    return businessInfo;
  }

  private constructContactInfo(address: AddressModel, communication: CommunicationModel, socialMedia: Array<SocialMedia>): ContactModel {
    return new ContactModel({
      Address: address,
      Communication: communication,
      SocialMedia: socialMedia
    });
  }

  private getErrorMessage(errors: ValidationErrors | {}, count = 0): { msg: string, count: number } {
    let errSummary = '';
    Object.keys(errors).forEach((field, i) => {
      if (errors[field]) {
        // If the property have a nested object with number as property name.
        if (Object.keys(errors[field]).every(k => isNaN(Number(k)) == false)) {
          Object.keys(errors[field]).forEach(subFieldErrs => {
            let errMsg = this.getErrorMessage(errors[field][subFieldErrs], count++);
            errSummary += errMsg.msg;
            count = errMsg.count;
          })
        }
        else {
          count++;
          errSummary += `${count}. `;
          let errLable = (errors[field]['errLable'] ? errors[field]['errLable'] : field).trim();
          if (errors[field].hasOwnProperty('required'))
            errSummary += `${errLable} is required.`;
          else if (errors[field].hasOwnProperty('min'))
            errSummary += `${errLable} should have minimum value of ${errors[field]['min']['min']}.`;
          else if (errors[field].hasOwnProperty('minlength'))
            errSummary += `${errLable} should have minimum of ${errors[field]['minlength']['requiredLength']} characters.`;
          else if (errors[field].hasOwnProperty('max'))
            errSummary += `${errLable} should be less than ${errors[field]['max']['max']}.`;
          else if (errors[field].hasOwnProperty('maxlength'))
            errSummary += `${errLable} should be less than ${errors[field]['maxlength']['requiredLength']} characters.`;
          else if (errors[field].hasOwnProperty('lessThan'))
            errSummary += `${errLable} should not be less than ${errors[field]['lessThan']['targetField']}.`;
          else if (errors[field].hasOwnProperty('custom'))
            errSummary += errors[field]['custom']['message'];
          else if (errors[field].hasOwnProperty('atleast_one_required'))
            errSummary += `Atleast one of ${errors[field]['atleast_one_required']['errLables']?.join()} is required.`;
          else if (errors[field].hasOwnProperty('pattern'))
            errSummary += `${errLable} is not valid.`;

          errSummary += '<br>';
        }
      }
    });

    return { msg: errSummary, count: count };
  }
}
