import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { finalize } from 'rxjs/internal/operators/finalize';

import { Utilities } from 'eccommons';

import { environment } from 'src/environments/environment';
import { AppSettings } from '../shared/services/appSettings.services';
import { AccountService } from '../account/service/account.service';
import { SharedService } from '../shared/services/shared.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private appSettings: AppSettings, private accountService: AccountService, private sharedService: SharedService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let showLoader: boolean = false;
    const isEcApiUrl = request.url.startsWith(environment.ec_api_endPoint);
    const isSiteApiUrl = request.url.startsWith(environment.site_api_endPoint);
    const bid = String(this.appSettings.BID);
    const token = this.accountService.Token || '';
    const userID = String(this.accountService.LoggedInUserID);
    
    if (request.params.has('showLoader')) {
      showLoader = Utilities.isValueTruthy(request.params.get('showLoader'));
    }
    
    if (showLoader)
      this.sharedService.showLoader();

    if (isEcApiUrl) {
      request = request.clone({
        headers: new HttpHeaders({
          'BusinessID': bid,
          'Authorization': token,
          'UserID': userID
        })
      });
    }
    else if (isSiteApiUrl) {
      request = request.clone({
        headers: new HttpHeaders({
          'BusinessID': bid,
          'UserID': userID
        })
      });
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (showLoader)
          this.sharedService.hideLoader();
      })
    );
  }
}
