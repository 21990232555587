import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { Observable } from 'rxjs';
import { TaxModel, ActionResponseModel } from 'eccommons';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class TaxAndChargeService {

  taxToEdit: TaxModel;

  constructor(private httpHelper: HttpHelperService, private appSettings: AppSettings) {
  }

  getAllTaxAndCharges(): Observable<Array<TaxModel>> {
    return this.getTaxAndCharges(null, null, null);
  }

  getTaxAndCharges(taxChargeID, isForAll = null, isActive = null): Observable<Array<TaxModel>> {
    const dataToSend = {
      'taxAndChargeID': taxChargeID,
      'isForAll': isForAll,
      'isActive': isActive
    };
    return this.httpHelper.post<any, Array<Array<TaxModel>>>(this.appSettings.URL_GetTaxAndCharges, dataToSend)
      .pipe(map(res => res.Data[0]));
  }

  saveTaxAndCharge(taxAndCharge: TaxModel): Observable<number> {
    const dataToSend = {
      'taxAndChargeID': taxAndCharge.TaxID,
      'name': taxAndCharge.Name,
      'value': taxAndCharge.Value,
      'deductionType': taxAndCharge.DeductionType,
      'isActive': taxAndCharge.IsActive
    };
    return this.httpHelper.post<any, any>(this.appSettings.URL_SaveTaxAndCharges, dataToSend)
      .pipe(map(res => res.Data[0]['SavedTaxAndChargeID']));
  }
}