import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AlertType, PopUpDialogModel } from 'eccommons';
import { Subscription } from 'rxjs';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss']
})
export class PopupModalComponent implements OnInit, OnDestroy {

  @Input() modalID: number;
  @Input() title: string;
  @Input() showProceedBtn: boolean = true;
  @Input() showCancelBtn: boolean = true;
  @Input() proceedBtnTxt: string = 'Ok';
  @Input() cancelBtnTxt: string = 'Cancel';
  @Input() set showModal(value: boolean) {
    this._showModal = value;
  }
  @Input() iconType: AlertType;
  @Input() iconClass: string;
  @Input() closeOnClickOutisde: boolean = true;
  @Input() minWidth: string = '20vw';
  @Input() maxWidth: string = '80vw';
  @Input() proceedClickWaitTime: number = 0;
  @Input() set popUpDialogDetails(value: PopUpDialogModel) {
    if (value) {
      this.title = value.title;
      this.showProceedBtn = value.showProceedBtn;
      this.showCancelBtn = value.showCancelBtn;
      this.proceedBtnTxt = value.proceedBtnText;
      this.cancelBtnTxt = value.cancelBtnText;
      this.iconType = value.iconType;
      this.iconClass = value.iconClass;
      this.closeOnClickOutisde = value.closeOnClickOutisde;
      this.minWidth = value.minWidth;
      this.maxWidth = value.maxWidth;
      this.proceedClickWaitTime = value.proceedClickWaitTime;
      this.showModal = true;
    }
  }

  @Output() proceedClickEvent = new EventEmitter();
  @Output() cancelClickEvent = new EventEmitter();
  @Output() modalCloseEvent = new EventEmitter();
  @Output() showModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public subscriptions = new Subscription();

  get showModal(): boolean {
    return this._showModal;
  }

  private _showModal: boolean;

  constructor(private sharedService: SharedService) {
  }

  ngOnInit(): void {
    switch (this.iconType) {
      case AlertType.Warning:
        this.iconClass = 'fas fa-exclamation-triangle warning-icon';
        return;
    }
  }

  onProceedClick(event) {
    event?.preventDefault();
    setTimeout(() => {
      this.proceedClickEvent.emit();
      this.hideModel();
    }, this.proceedClickWaitTime);
  }

  onCancelClick(event) {
    event?.preventDefault();
    this.cancelClickEvent.emit();
    this.hideModel();
  }

  onClickedOutside() {
    if (this.closeOnClickOutisde)
      this.hideModel();
  }

  onModalClick(e) {
    e.stopPropagation();
  }

  private hideModel() {
    this.showModal = false;
    this.showModalChange.emit(this.showModal);
    this.modalCloseEvent.emit();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
