<section>
  <div class="form-container shadowed-box-non-mobile section-content">
    <form [formGroup]="frmBannerGroup" *ngIf="frmBannerGroup">
      <fieldset class="box-shadow box-shadow-border">
        <legend class="flxRowAlignCenter">Banner</legend>

        <div class="flxRowSB align-center">
          <!--  Name -->
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Banner Name" formControlName="Name"
              aria-describedby="basic-addon1">
            <span class="form__label">Banner Name </span>
          </div>

          <!--  IsActive -->
          <div class="form-check">
            <app-switch formControlName="IsActive"></app-switch>
          </div>
        </div>

        <!--  Image -->
        <div class="form-group">
          <app-ec-upload-file [allowedExtensions]="allowedExtensions" [previewFiles]="previewImages"
            (files)="onFilesSelected($event)" (fileSelectFailed)="onFileSelectFailed($event)"
            (fileRemoved)="onFileRemove($event)">
          </app-ec-upload-file>
          <span class="form__label">Banner Image </span>
        </div>

        <div class="flxRowSB align-center">
          <!-- Link Type Options -->
          <div class="form-group">
            <select class="form-control" (change)="onLinkTypeChange($event)" formControlName="LinkType">
              <option *ngFor="let option of linkTypeOptions" [value]="option.value">{{option.lable}}
              </option>
            </select>
            <span class="form__label">Link To </span>
          </div>

          <!-- Link Options -->
          <div class="form-group" *ngIf="linkOptions && linkOptions.length > 0">
            <select class="form-control" (change)="onLinkOptionChange($event)" formControlName="LinkOptionValue">
              <option *ngFor="let option of linkOptions" [value]="option.value">{{option.lable}}
              </option>
            </select>
            <span class="form__label">Link Option </span>
          </div>
        </div>

        <!--  Display Order -->
        <div class="form-group">
          <input type="text" class="form-control input-sm" placeholder="Display Order" formControlName="DisplayOrder"
            aria-describedby="basic-addon1">
          <span class="form__label">Display Order </span>
        </div>



        <!-- Action Buttons -->
        <div class="flxRowNwEnd">
          <button type="button" class="btn btn-sm btn-outline-dark" (click)="cancelSave()">Cancel</button>
          <button type="button" class="btn btn-sm btn-primary" [disabled]="!frmBannerGroup.valid"
            (click)="saveBanner()">Save</button>
        </div>
      </fieldset>
    </form>
  </div>
</section>