import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { moveItemInArray, CdkDragDrop } from "@angular/cdk/drag-drop";

import { Subject } from 'rxjs/internal/Subject';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';

import {
  TableConfigModel, TableColumnType, TableActionType, TableActionModel, TableColumnModel, PartyModel, Utilities, ExcelModel, WorkSheetModel
} from 'eccommons';

import { AlertService } from '../../services/alert.service';
import { ExcelService } from '../../services/excel.service';
import { CustomCurrencyPipe } from '../../pipe/custom-currency.pipe';
import { AppSettings } from '../../services/appSettings.services';

@Component({
  selector: 'ec-app-table',
  templateUrl: './app-table.component.html',
  styleUrls: ['./app-table.component.scss']
})
export class AppTableComponent implements OnInit {
  @ViewChild('appTable') appTable: ElementRef;

  @Input() tableConfig: TableConfigModel;
  @Input() set rowData(value: Array<any>) {
    this._rowData = value;
    this.initRowData();
  }
  @Input() rowKey: string;
  @Input() tableTitle: string;
  
  @Output() actionClickEmit = new EventEmitter<TableActionModel>();
  @Output() textBoxChangedEmit = new EventEmitter<TableActionModel>();
  @Output() selectedRowsEmit = new EventEmitter<Array<any>>();
  @Output() rowSortedEmit = new EventEmitter<Array<number>>();

  set tableData(value: Array<any>) {
    this._colSumCollection = new Array<object>();
    this._tableData = this.prepareDisplayData(value);
  }

  get tableData(): Array<any> {
    return this._tableData;
  }

  currentPageNo: number = 1;
  totalPages: number;
  columnType = TableColumnType;
  actionType = TableActionType;

  selectedRows = new Array<any>();
  totalColumns: number;
  checkedColumns: Array<boolean>;
  showPagerControls: boolean;
  searchKeyChangeSubject: Subject<string> = new Subject<string>();
  searchKey: string;
  dateFormat: string = 'dd/MM/yyyy';
  dateTimeFormat: string = 'MMM d, y, h:mm:ss a';
  allRowsSelected: boolean = false;
  dragEnabled = false;

  get rowData(): Array<any> {
    return this._rowData;
  }

  private _rowData: Array<any>;
  private _tableData: Array<any>;
  private _colSumCollection: Array<object> = new Array<{ columnName: string, sum: number }>();

  /****** Constructor *****/
  constructor(private readonly alertService: AlertService, private readonly excelService: ExcelService,
    private customCurrencyPipe: CustomCurrencyPipe, private readonly appSettingService: AppSettings) {
    this.dateFormat = this.appSettingService.DATE_FORMAT;
    this.dateTimeFormat = this.appSettingService.DATE_TIME_FORMAT;
  }

  /****** On Init *****/
  ngOnInit(): void {
    this.totalColumns = this.tableConfig.Headers.length;
    if (this.tableConfig.ShowRowNo)
      this.totalColumns += 1;
    if (this.tableConfig.AllowReOrdering)
      this.totalColumns += 1;

    this.checkedColumns = new Array<boolean>(this.rowData?.length);
    this.checkedColumns.fill(false);

    this.searchKeyChangeSubject.pipe(
      debounceTime(700)
    ).subscribe(searchKey => {
      this.searchTable(searchKey);
    });
  }

  /****** Events *****/
  rowSelect(event, index: number, row: any) {
    if (event.target.checked) {
      row['Selected'] = true;
      if (!this.selectedRows.find(r => r[this.rowKey] == row[this.rowKey]))
        this.selectedRows.push(row);
    }
    else {
      row['Selected'] = false;
      this.selectedRows = this.selectedRows.filter(r => r[this.rowKey] != row[this.rowKey]);
    }
    this.handleAllCheckBox();
    this.selectedRowsEmit.emit(this.selectedRows);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (this.tableConfig.AllowReOrdering) {
      let indexTillPrevPage = ((this.currentPageNo - 1) * this.tableConfig.PageSize) || 0;
      moveItemInArray(this.rowData, (indexTillPrevPage + event.previousIndex), (indexTillPrevPage + event.currentIndex));
      let reOrderedKeys = new Array<number>();
      this.rowData.forEach(d => {
        reOrderedKeys.push(d[this.rowKey]);
      });
      this.rowSortedEmit.emit(reOrderedKeys);
      this.loadTableData();
    }
    else {
      this.alertService.warn('Sorry! Reordering not supported');
    }
  }

  actionClick(actionType: TableActionType, index: number, row: any) {
    this.actionClickEmit.emit(new TableActionModel({
      ActionType: actionType,
      RowIndex: index,
      RowData: row
    }));
  }

  onTxtBoxChanged(actionType: TableActionType, event, index: number, row: any) {
    this.textBoxChangedEmit.emit(new TableActionModel({
      ActionType: actionType,
      RowIndex: index,
      Value: event.target.value,
      RowData: row
    }));
  }

  onPageSizeChange() {
    this.tableConfig.PageSize = !!this.tableConfig.PageSize && Math.abs(this.tableConfig.PageSize) >= 0 ? Math.abs(this.tableConfig.PageSize) :
      this.tableConfig.PageSize === 0 ? 1 : null;
    this.computeTotalPages();
    this.loadTableData();
  }

  onPageNoChange() {
    let absPageNo = Math.abs(this.currentPageNo);
    this.currentPageNo = (absPageNo > this.totalPages) ? this.totalPages :
      (absPageNo == 0) ? 1 : (absPageNo >= 0) ? absPageNo : null;
    this.loadTableData();
  }

  onUserSearchInput(value: string) {
    if (value) {
      this.searchKeyChangeSubject.next(value);
    }
    else {
      this.loadTableData();
    }
  }

  onAllRowsSelected() {
    if (this.allRowsSelected) {
      this.rowData.map(row => row['Selected'] = true);
      this.selectedRows = this.rowData;
    }
    else {
      this.rowData.map(row => row['Selected'] = false);
      this.selectedRows = new Array<any>();
    }
    this.loadTableData();
    this.selectedRowsEmit.emit(this.selectedRows);
  }

  /****** Methods *****/
  getRowDataInArray(rowData: any): Array<any> {
    if (Array.isArray(rowData))
      return rowData;
    else
      return [rowData];
  }

  goToPreviousPage() {
    if (this.currentPageNo > 1) {
      this.currentPageNo--;
      this.loadTableData();
    }
  }

  goToNextPage() {
    if (this.currentPageNo < this.totalPages) {
      this.currentPageNo++;
      this.loadTableData();
    }
  }

  getDynamicClass(row, header: TableColumnModel) {
    if (header.DynamicClassFn) {
      return header.DynamicClassFn(row);
    }
  }

  loadTableData() {
    this.tableData = this.rowData
      .slice(((this.currentPageNo - 1) * this.tableConfig.PageSize),
        this.currentPageNo * this.tableConfig.PageSize);
  }

  exportToExcel() {
    let dataToExport = this.prepareDisplayData(this.rowData);

    if (dataToExport && this.tableConfig?.ExcelConfig.AllowExcelExport) {
      let exportJson = [];

      dataToExport.forEach((row, i) => {
        let exportRow = {};

        if (this.tableConfig?.ExcelConfig.ShowRowNo) {
          exportRow['SNo.'] = i + 1;
        }

        this.tableConfig.Headers.filter(th => th.ColumnType != TableColumnType.ACTION
          && th.ColumnType != TableColumnType.SUB_TABLE
          && th.ColumnType != TableColumnType.TEXTBOX).forEach(th => {
            let value = null;

            switch (th.ColumnType) {
              case TableColumnType.BOOLEAN:
                value = row[th.FieldName] ? 'Yes' : 'No';
                break;
              case TableColumnType.ENUM:
                value = th.Enum[row[th.FieldName]];
                break;
              case TableColumnType.LABLE:
                value = row[th.FieldName];
                break;
              default:
                value = row[th.FieldName];
                break;
            }

            exportRow[th.ColumnName] = value;
          });

        if (exportRow)
          exportJson.push(exportRow);
      });

      this.excelService.exportAsExcelFile(new ExcelModel({
        FileName: this.tableConfig.ExcelConfig?.FileName ?? 'Export',
        WorkSheets: [new WorkSheetModel({
          Name: this.tableConfig.ExcelConfig?.SheetName ?? 'Data',
          Data: exportJson
        })],
      }));
    }
  }

  getColumnComputedValue(header: TableColumnModel) {
    if (!header.ShowSum)
      return null;

    let _columnSumObj = this._colSumCollection.find(csc => csc['columnName'] == header.ColumnName);

    if (!_columnSumObj)
      this.calculateSumOfColumn(header);

    _columnSumObj = this._colSumCollection.find(csc => csc['columnName'] == header.ColumnName);

    if (header.ColumnType == this.columnType.CURRENCY)
      return this.customCurrencyPipe.transform(_columnSumObj['sum']);

    return _columnSumObj['sum'];
  }

  calculateSumOfColumn(header: TableColumnModel) {
    if (header.ShowSum) {
      let sum = 0;
      this.tableData.forEach(row => {
        sum += Utilities.parseNumber(row[header.FieldName]);
      });

      this._colSumCollection.push({ columnName: header.ColumnName, sum: sum });
    }
  }

  onLinkClicked(index: number, header: string, row: any) {
    this.actionClickEmit.emit(new TableActionModel({
      ActionType: TableActionType.LINK,
      Lable: header,
      Value: row[header],
      RowIndex: index,
      RowData: row
    }));
  }

  /****** PRIVATES *****/
  private initRowData() {
    // Flags or Properties
    this.selectedRows = new Array<any>();
    this.allRowsSelected = false;

    if (this.rowData && this.rowData.length > 0 && this.tableConfig.PageSize && this.rowData.length > this.tableConfig.PageSize) {
      this.showPagerControls = true;
      this.computeTotalPages();
      this.loadTableData();
    }
    else {
      this.tableData = this.rowData;
      this.showPagerControls = false;
    }
  }

  private computeTotalPages() {
    this.totalPages = Math.ceil(this.rowData.length / this.tableConfig.PageSize);
    this.currentPageNo = this.currentPageNo > this.totalPages ? this.totalPages : this.currentPageNo;
  }

  private prepareDisplayData(data: Array<any>): Array<any> {
    let dataCopy = [];
    if (data) {
      dataCopy = JSON.parse(JSON.stringify(data));
      this.tableConfig.Headers.filter(th => th.DynamicDisplayFns && th.DynamicDisplayFns.length).forEach(th => {
        th.DynamicDisplayFns.forEach(dfn => {
          if (dfn) {
            dataCopy?.forEach(tdata => {
              tdata[th.FieldName] = dfn(tdata[th.FieldName], tdata);
            });
          }
        });
      });
    }
    dataCopy.map(change=> change.AccTypeName=change.AccTypeName=="SAVINGS" ? 'Savings':(change.AccTypeName=="CURRENT" ? 'Current' :change.AccTypeName))
    return dataCopy;
  }

  private searchTable(searchKey: string) {
    this.tableData = this.rowData?.filter(row => Utilities.isObjectHasValue(row, searchKey));
  }

  private handleAllCheckBox() {
    if (this.rowData?.length == this.selectedRows?.length) {
      this.allRowsSelected = true;
    }
    else {
      this.allRowsSelected = false;
    }
  }
}

