import { Injectable } from '@angular/core';
import { DatePipe, KeyValue, formatNumber } from '@angular/common';

import { map } from 'rxjs/internal/operators/map';
import { Observable } from 'rxjs/internal/Observable';
import { mergeMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';

import {
  OrderStatusEnum, PaymentStatusEnum, OrderModel, OrderItemModel,
  PaymentModeType, ResponseModel, DeliverySettingsModel, PaymentSettingsModel, OrderMode, ReceiptGroupEnum, PartyModel, PartyAddressModel, AlertType, AdditionalOrderItemModel, ChargeType, FileModel, PopUpDialogModel, ItemVarietyModel, UnitLevel, Utilities, TaxModel, AdditionalOrderItemsType, ItemModel, AppliedPromoCode, OrderFilterModel
} from 'eccommons';

import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { SharedService } from 'src/app/shared/services/shared.service';

import { AdminService } from 'src/app/admin/services/admin.service';
import { AccountService } from 'src/app/account/service/account.service';
import { CustomCurrencyPipe } from '../../shared/pipe/custom-currency.pipe';
@Injectable({
  providedIn: 'root',
})
export class OrderService {

  orderToEdit: OrderModel;
  refOrder: OrderModel;
  inActiveOrderStatusList: Array<OrderStatusEnum> = [OrderStatusEnum.CART, OrderStatusEnum.CANCELLED, OrderStatusEnum.UNAVAILABLE];

  constructor(private httpHelper: HttpHelperService, private datePipe: DatePipe, private appSettings: AppSettings,
    private sharedService: SharedService, private adminService: AdminService, private accountService: AccountService,
    private customCurrencyPipe: CustomCurrencyPipe) {
  }

  public getOrderDetails(orderID, showLoader: boolean = true): Observable<OrderModel> {
    const dataToSend = {
      'orderID': orderID
    }

    return this.httpHelper.post<any, any>(this.appSettings.URL_GetOrderDetails, dataToSend, showLoader)
      .pipe(map(res => {
        if (res && res.Data[0])
          return this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0];
      }));
  }

  searchOrderDetails(filters: OrderFilterModel
  ): Observable<Array<OrderModel>> {
    const dataToSend = {
      "orderID": filters?.OrderID,
      "partyID": filters?.Party?.PartyID,
      "phoneNo": filters?.PhoneNo,
      "orderStatusIDs": filters?.OrderStatus?.join(),
      "paymentStatusIDs": filters?.PaymentStatus?.join(),
      "orderFromDate": this.datePipe.transform(filters?.OrderFromDate, this.appSettings.DB_DATE_FORMAT),
      "orderTillDate": this.datePipe.transform(filters?.OrderTillDate, this.appSettings.DB_DATE_FORMAT),
      "receiptGroupIDs": filters?.ReceiptGroup?.join()
    };

    return this.httpHelper.post(this.appSettings.URL_SearchOrderDetails, dataToSend)
      .pipe(map(res => this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])));
  }

  saveOrderItem(orderItem: OrderItemModel, order: OrderModel): Observable<OrderModel | number> {
    // If OrderID is present then make API call else update in local storage
    if (orderItem && orderItem.OrderID > 0) {
      const dataToSend = {
        'orderID': orderItem.OrderID,
        'customerID': order?.CustomerID,
        'itemVarietyID': orderItem.ItemVarietyID,
        'unitLevel': orderItem.UnitLevel,
        'count': orderItem.Count,
        'customUnitPrice': orderItem.IsUnitPriceChanged ? orderItem.UnitPrice : null,
        'partyProfileID': order.PartyProfileID,
        'isAvailabilityCheckMandatory': 0
      }

      return this.httpHelper.post<any, any>(this.appSettings.URL_SaveOrderItem, dataToSend, true)
        .pipe(map(res => {
          if (res && typeof (res.Data) === 'number') {
            return Number(res.Data);
          }
          else {
            let savedOrder = this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0];
            if (!savedOrder)
              savedOrder = null;
            else
              savedOrder.BID = this.appSettings.BID;

            if (savedOrder || !orderItem.Count || orderItem.Count < 1) {
              // If order saved then remove from local storage
              this.updateLSCartOrder(null);
            }

            return savedOrder;
          }
        }));
    }
    else {
      // Add to Item to local cart
      this.saveOrderItemToLocalCart(orderItem, order);
      return of(this.getCartOrders());
    }
  }

  // Submits all the order in local storage
  submitCartOrder(receiptGroupID: ReceiptGroupEnum, refOrderID?: number): Observable<OrderModel> {
    let cartOrder = this.getCartOrders();
    if (cartOrder && cartOrder?.OrderItems?.length > 0) {
      cartOrder.ReceiptGroup = receiptGroupID;
      cartOrder.RefOrderID = refOrderID;
      return this.saveOrder(cartOrder);
    }
    else {
      this.sharedService.notify(AlertType.Warning, 'No items found in Cart');
    }
  }

  saveOrder(order: OrderModel, fetchUpdatedOrder: boolean = true): Observable<OrderModel> {
    let input = new FormData();
    if (order.CustomerID)
      input.append("userID", String(order.CustomerID));
    if (order.OrderID)
      input.append("orderID", String(order.OrderID));
    input.append("receiptGroupID", String(order.ReceiptGroup));
    input.append("refOrderID", order.RefOrderID ? String(order.RefOrderID) : null);
    input.append("isAvailabilityCheckMandatory", String(0));
    input.append("partyProfileID", order.PartyProfileID ? String(order.PartyProfileID) : null);
    input.append("fetchUpdatedOrder", String(fetchUpdatedOrder ? 1 : 0));
    input.append("formData", JSON.stringify(order.OrderItems));
    return this.httpHelper.post<any, any>(this.appSettings.URL_SaveOrderItems, input, true)
      .pipe(map(res => {
        if (res) {
          let order = null;
          if (res.Data[0] && res.Data[0].length > 0) {
            // Show unavailable items
            let unavailableItems = 'Items Not Available : ';
            this.adminService.getItems().subscribe(allItems => {
              (res.Data[0] as Array<number>).forEach(unavailableItemVarietyID => {
                allItems.find(item => {
                  const itemVariety = item.getVarietiesList().find(iv => iv.ItemVarietyID == unavailableItemVarietyID);
                  if (itemVariety) {
                    unavailableItems += itemVariety.DisplayName + ", ";
                    return true;
                  }
                  return false;
                });

                this.sharedService.showPopUpDialog(new PopUpDialogModel({
                  title: 'Items Unavailable',
                  message: unavailableItems,
                  showCancelBtn: false,
                  proceedBtnText: 'Ok',
                  iconType: AlertType.Warning
                }));

                this.sharedService.notify(AlertType.Warning, 'Sorry! Some Items Not Available!');
              });
            });
          }

          if (!fetchUpdatedOrder)
            return true;

          if (res.Data[1] && res.Data[1].length > 0) {
            order = this.putTogetherOrderObject(res.Data[1][0], res.Data[1][1], res.Data[1][2], res.Data[1][3], res.Data[1][4], res.Data[1][5], res.Data[1][6])[0];
          }

          if (!order) {
            this.sharedService.notify(AlertType.Error, 'Sorry! Order processing failed');
          }
          else {
            order.BID = this.appSettings.BID;
            this.updateLSCartOrder(null);
          }

          return order;
        }
      }));
  }

  public getCartOrders(): OrderModel {
    const cartOrder = this.sharedService.getFromLocalStorage<OrderModel>(this.appSettings.LS_CART_ORDER);

    if (cartOrder && cartOrder.BID == this.appSettings.BID) {
      return new OrderModel(cartOrder);
    }
    else
      return null;
  }

  changeOrderItemUnitPrice(orderID: number, orderItemID: number, newUnitPrice: number): Observable<OrderModel | Number> {

    const dataToSend = {
      'orderID': orderID,
      'orderItemID': orderItemID,
      'newUnitPrice': newUnitPrice
    }

    return this.httpHelper.post<any, any>(this.appSettings.URL_ChangeOrderItemUnitPrice, dataToSend)
      .pipe(map(res => {
        if (res && typeof (res.Data) === 'number') {
          return Number(res.Data);
        }
        else {
          return this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0];
        }
      }));
  }

  saveAdditionalOrderItem(additionalOrderItem: AdditionalOrderItemModel): Observable<OrderModel> {
    if (additionalOrderItem) {
      const dataToSend = {
        'AdditionalOrderItemID': additionalOrderItem.AdditionalOrderItemID,
        'OrderID': additionalOrderItem.OrderID,
        'Name': additionalOrderItem.Name,
        'TotalUnits': additionalOrderItem.TotalUnits,
        'CostPerUnit': additionalOrderItem.CostPerUnit,
        'Unit': additionalOrderItem.Unit,
        'PercentageValue': additionalOrderItem.PercentageValue,
        'ChargeType': additionalOrderItem.ChargeType,
        'Amount': additionalOrderItem.Amount,
        'TaxID': additionalOrderItem.TaxID,
        'Note': additionalOrderItem.Note
      }

      return this.httpHelper.post<any, any>(this.appSettings.URL_SaveAdditionalOrderItem, dataToSend)
        .pipe(map(res => this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0]
        ));
    }
  }

  saveOrderStatuses(orderID: number, orderStatusID?: OrderStatusEnum, paymentStatusID?: PaymentStatusEnum,
    paymentModeID?: number, shouldSendOrderStatusChangeSMS?: boolean
  ): Observable<OrderModel> {
    let orderStatus = null;
    if (orderStatusID)
      orderStatus = OrderStatusEnum[orderStatusID];
    const dataToSend = {
      "orderID": orderID,
      "orderStatusID": orderStatusID,
      "orderStatus": orderStatus,
      "paymentStatusID": paymentStatusID,
      "shouldSendOrderStatusChangeSMS": shouldSendOrderStatusChangeSMS,
      "userID": this.accountService.LoggedInUserID
    };
    return this.httpHelper.post(this.appSettings.URL_SaveOrderStatuses, dataToSend)
      .pipe(
        map(res => {
          if (res.Data) {
            let om = new OrderModel();

            if (res.Data[0] && res.Data[0][0]) {
              om.OrderStatusID = res.Data[0][0]['OrderStatusID'];
              om.PaymentStatusID = res.Data[0][0]['PaymentStatusID'];
            }

            return new OrderModel(om);
          }
        })
      );
  }

  saveOrderProperties(orderID: number, customerID: number, addressID: number, dropLocationID: number,
    orderMode?: OrderMode, paymentModeID?: PaymentModeType, transportRefNo?: string,
    receivedOrderAmount?: number)
    : Observable<OrderModel> {
    const dataToSend = {
      "orderID": orderID,
      "customerID": customerID,
      "addressID": addressID,
      "dropLocationID": dropLocationID,
      "paymentModeID": paymentModeID,
      "orderModeID": orderMode || OrderMode.ADMIN_ORDER,
      "transportRefNo": transportRefNo,
      "receivedOrderAmount": receivedOrderAmount,
      "userID": this.accountService.LoggedInUserID
    };

    return this.httpHelper.post<any, number>(this.appSettings.URL_SaveOrderProperties, dataToSend)
      .pipe(map(res => this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0]
      ));
  }

  saveReceivedOrderAmount(orderID: number, receivedOrderAmount: number): Observable<OrderModel> {
    const dataToSend = {
      "orderID": orderID,
      "receivedOrderAmount": receivedOrderAmount,
      "userID": this.accountService.LoggedInUserID
    };

    return this.httpHelper.post<any, number>(this.appSettings.URL_SaveReceivedOrderAmount, dataToSend)
      .pipe(map(res => this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0]
      ));
  }

  saveTransportImage(orderID: number, file: File): Observable<any> {
    let input = new FormData();
    input.append("formData", JSON.stringify({ 'OrderID': orderID }));
    input.append("TransportImage", file);

    return this.httpHelper.post(this.appSettings.URL_SaveTransportImage, input).pipe(map(res => res.Data));
  }

  public uploadPaymentScreenShot(orderID: number, userID: number, screenShot: File, isSMSToBeSent: boolean): Observable<any> {

    let input = new FormData();
    input.append("formData", JSON.stringify({ 'OrderID': orderID, 'UserID': userID, 'isSMSToBeSent': isSMSToBeSent }));
    input.append("ScreenShotImage", screenShot);

    return this.httpHelper.post(this.appSettings.URL_UploadPaymentScreenShot, input, true).pipe(map(res => res.Data));
  }

  public shouldSendOrderStatusChangeSMS(previousOrderStatus: OrderStatusEnum, newOrderStatus: OrderStatusEnum): boolean {
    if (previousOrderStatus != newOrderStatus) {
      if (previousOrderStatus == OrderStatusEnum.CART) {
        return true;
      }
      else if (newOrderStatus == OrderStatusEnum.CANCELLED || newOrderStatus == OrderStatusEnum.PACKAGING || newOrderStatus == OrderStatusEnum.COMPLETED) {
        return true;
      }
    }

    return false;
  }

  saveReceiptImage(receiptImage: Blob, receiptGroup: ReceiptGroupEnum, receiptNumber: string,
    currentReceiptFileName: string, shouldEmail: boolean, emailID: string): Observable<string> {

    return this.sharedService.getBusinessBasicInfo().pipe(mergeMap(res => {
      let businessName = '';
      if (res && res.BasicInfo)
        businessName = res.BasicInfo.FullName;

      const dataToSend = {
        "ReceiptType": receiptGroup,
        "ReceiptNumber": receiptNumber,
        "ReceiptFileName": currentReceiptFileName,
        "BusinessName": businessName,
        "ShouldEmail": shouldEmail,
        "EmailID": emailID
      };
      let input = new FormData();
      input.append("formData", JSON.stringify(dataToSend));
      input.append("ReceiptImage", receiptImage);

      return this.httpHelper.post<any, string>(this.appSettings.URL_UploadReceiptScreenShot, input, true)
        .pipe(map(res => res.Data));
    }));
  }

  shareReceipt(receiptGroup: ReceiptGroupEnum, receiptNumber: string,
    currentReceiptFileName: string, shouldEmail?: boolean, emailID?: string,
    shouldSMS?: boolean, phoneNo?: string): Observable<number> {
    return this.sharedService.getBusinessBasicInfo().pipe(mergeMap(res => {
      let businessName = '';
      if (res && res.BasicInfo)
        businessName = res.BasicInfo.FullName;

      const dataToSend = {
        "ReceiptType": receiptGroup,
        "ReceiptNumber": receiptNumber,
        "ReceiptFileName": currentReceiptFileName,
        "BusinessName": businessName,
        "ShouldEmail": shouldEmail,
        "EmailID": emailID,
        "ShouldSMS": shouldSMS,
        "PhoneNo": phoneNo
      };

      return this.httpHelper.post<any, number>(this.appSettings.URL_ShareReceipt, dataToSend, true)
        .pipe(map(res => res.Data));
    }));
  }

  deleteAdditionalOrderItem(additionalOrderItemID: number): Observable<OrderModel> {
    const dataToSend = {
      "AdditionalOrderItemID": additionalOrderItemID,
    };

    return this.httpHelper.post<any, any>(this.appSettings.URL_DeleteAdditionalOrderItem, dataToSend, true)
      .pipe(map(res => this.putTogetherOrderObject(res.Data[0], res.Data[1], res.Data[2], res.Data[3], res.Data[4], res.Data[5], res.Data[6])[0]
      ));
  }

  saveOrderNotes(orderID: number, notes: string, showLoader: boolean = false): Observable<ResponseModel<number>> {
    const dataToSend = {
      "OrderID": orderID,
      "OrderNotes": notes,
    };

    return this.httpHelper.post<any, any>(this.appSettings.URL_SaveOrderNotes, dataToSend, showLoader);
  }

  clearOrderToEdit() {
    this.orderToEdit = null;
  }

  clearRefOrder() {
    this.refOrder = null;
  }

  // saveReceivedOrderAmount(orderID: number, receivedAmount: number): Observable<OrderModel> {
  //   const dataToSend = {
  //     "OrderID": orderID,
  //     "ReceivedAmount": receivedAmount,
  //   };

  //   return this.httpHelper.post(this.appSettings.URL_SaveReceivedOrderAmount, dataToSend, true)
  //     .pipe(
  //       map(res => {
  //         if (res.Data) {
  //           let ord = new OrderModel();

  //           if (res.Data[0] && res.Data[0][0]) {
  //             ord.OrderStatusID = res.Data[0][0]['OrderStatusID'];
  //             ord.PaymentStatusID = res.Data[0][0]['PaymentStatusID'];
  //           }
  //           if(res.Data[1] && res.Data[1][0])
  //           {
  //             ord.PaymentBalance = res.Data[1][0]['NewPaymentBalance'];
  //           }

  //           return ord;
  //         }
  //       })
  //     );
  // }

  // mapPartyWithOrder(orderID: number, customerID: number, addressID?: number): Observable<ResponseModel<number>> {
  //   const dataToSend = {
  //     "OrderID": orderID,
  //     "CustomerID": customerID,
  //     "AddressID": addressID
  //   };

  //   return this.httpHelper.post<any, any>(this.appSettings.URL_MapPartyWithOrder, dataToSend, true);
  // }

  /************* PRIVATE ****************/

  saveOrderItemToLocalCart(newOrderItem: OrderItemModel, order?: OrderModel): void {
    let cartOrder = this.getCartOrders();

    // Update Existing Order
    if (cartOrder && cartOrder.OrderItems) {
      const existingOrderItemIndex = cartOrder.OrderItems.findIndex(oi => oi.ItemVarietyID == newOrderItem.ItemVarietyID
        && oi.UnitLevel == newOrderItem.UnitLevel);

      // Item Already Present
      if (existingOrderItemIndex >= 0) {
        // Delete Item
        if (newOrderItem.Count <= 0)
          cartOrder.OrderItems.splice(existingOrderItemIndex, 1);
        else { // Update Count
          cartOrder.OrderItems[existingOrderItemIndex] = newOrderItem;
        }
      }
      else { // New Item
        cartOrder.OrderItems.push(newOrderItem);
      }
    }
    else { // New Order
      if (!order) {
        order = new OrderModel(order);        
      }
      
      order.BID = this.appSettings.BID;
      order.OrderItems = [new OrderItemModel(newOrderItem)];
      cartOrder = order;
    }

    if (!cartOrder.OrderItems || cartOrder.OrderItems.length < 1)
      cartOrder = null;

    // Calculate bill
    cartOrder?.computeBill();

    this.updateLSCartOrder(cartOrder);
  }

  updateLSCartOrder(cartOrder: OrderModel) {
    this.sharedService.saveToLocalStorage(this.appSettings.LS_CART_ORDER, cartOrder);
  }

  clearCartOrder() {
    this.updateLSCartOrder(null);
    this.clearOrderToEdit();
    this.clearRefOrder();
  }

  copyOrder(order: OrderModel) {
    if (order?.OrderItems?.length > 0) {
      this.adminService.getItems().subscribe(items => {
        let copiedOrderItems = Utilities.deepCopyArray(order?.OrderItems, OrderItemModel);
        this.clearCartOrder();
        let newOrder = new OrderModel({
          BID: this.appSettings.BID,
          ReceiptGroup: order.ReceiptGroup,
        });
        console.log('Copied order', copiedOrderItems);
        let unavailableItems = [];
        copiedOrderItems.forEach(oi => {
          let unitPrice: number;
          let variety = this.getItemVarietyFromItemList(items, oi.ItemVarietyID);
          let buyingUnitLevel = oi.UnitLevel;

          if(!variety)
          {
            unavailableItems.push(oi.FullName);
            return;
          }

          if (order.ReceiptGroup == ReceiptGroupEnum.PURCHASE_ORDER) {
            unitPrice = variety.getPurchasePrice(buyingUnitLevel);
          }
          else {
            unitPrice = buyingUnitLevel && buyingUnitLevel == UnitLevel.SecondaryUnit ?
              variety.SecondaryPrice : variety.PrimaryPrice;
          }

          const newOrderItem = new OrderItemModel({
            OrderID: null,
            ItemID: variety.ItemID,
            ItemVarietyID: variety.ItemVarietyID,
            ItemName: variety.DisplayName,
            AllowCustomValue: variety.AllowCustomValue,
            MinSellingUnit: oi.MinSellingUnit,
            UnitLevel: buyingUnitLevel,
            UnitType: variety.getUnitType(buyingUnitLevel),
            Count: oi.Count,
            UnitPrice: unitPrice,
            AvailableQty: variety.AvailableQty,
            AvailabilityUnit: variety.AvailabilityUnit
          });

          this.saveOrderItemToLocalCart(newOrderItem, newOrder);
        });

        if(unavailableItems?.length > 0)
        {
          this.sharedService.notify(AlertType.Warning, `Items Deleted/Not Available : ${unavailableItems.join(',<br/>')}`);
        }
      });
    }
  }

  getToReceiveOrderInfo(orders: Array<OrderModel>): KeyValue<number, number> {
    let count = 0;
    let totalBalance = orders?.filter(o =>
      (o.ReceiptGroup == ReceiptGroupEnum.INVOICE || o.ReceiptGroup == ReceiptGroupEnum.DELIVERY_CHALLAN)
      && this.isActiveOrder(o) && o.PaymentStatusID != PaymentStatusEnum.SUCCESS)
      .reduce<number>((total: number, ord: OrderModel) => {
        count++;
        total += Utilities.parseNumber(ord.PaymentBalance);
        return total;
      }, 0);

    return { key: count, value: totalBalance };
  }

  getToPayOrderInfo(orders: Array<OrderModel>): KeyValue<number, number> {
    let count = 0;
    let totalBalance = orders?.filter(o => o.ReceiptGroup == ReceiptGroupEnum.PURCHASE_ORDER
      && this.isActiveOrder(o) && o.PaymentStatusID != PaymentStatusEnum.SUCCESS)
      .reduce<number>((total: number, ord: OrderModel) => {
        count++;
        total += Utilities.parseNumber(ord.PaymentBalance);
        return total;
      }, 0);

    return { key: count, value: totalBalance };
  }

  isActiveOrder(order: OrderModel): boolean {
    return !this.inActiveOrderStatusList.some(ios => order?.OrderStatusID == ios);
  }

  // deletes bulk orders
  deleteOrderDetails(OrderIDList: Array<number>): Observable<number> {
    const dataToSend = {
      'ToDeleteOrderIDList': OrderIDList?.join(',')
    };
    return this.httpHelper.post<any, number>(this.appSettings.URL_DeleteOrderDetails, dataToSend)
      .pipe(map(res => res.Data));
  }

  computeTaxSplit(order: OrderModel, callBackFn: Function) {
    if (!order.Taxes) {
      this.adminService.getTaxInfo(false).subscribe(allTaxes => {
        if (allTaxes) {
          order.computeTaxSplit(allTaxes);
          if (callBackFn)
            callBackFn();
        }
      });
    }
    else {
      if (callBackFn)
        callBackFn();
    }
  }

  setDisplayNameOfAOIForOrder(order: OrderModel) {
    order?.AdditionalOrderItems?.forEach(aoi => {
      aoi.DisplayName = this.getDisplayNameOfAOI(aoi);
    });

    return order;
  }

  getDisplayNameOfAOI(aoi: AdditionalOrderItemModel) {
    switch (aoi.Name) {
      case AdditionalOrderItemsType.TRANSPORT_CHARGE:
        if (aoi.TotalUnits && aoi.CostPerUnit)
          return `Transport Charge (${aoi.TotalUnits} ${aoi.Unit} x ${this.customCurrencyPipe.transform(aoi.CostPerUnit)})`;
        else
          return `Transport Charge`;
      case AdditionalOrderItemsType.SERVICE_CHARGE:
        return 'Service Charge';

      case AdditionalOrderItemsType.PACKAGING_CHARGE:
        return 'Packaging Charge';

      case AdditionalOrderItemsType.INSURANCE_CHARGE:
        return 'Insurance Charge';

      case AdditionalOrderItemsType.ADJUSTMENT:
        return 'Adjustment';

      case AdditionalOrderItemsType.DISCOUNT:
        if (aoi.PercentageValue)
          return `Discount @${formatNumber(aoi.PercentageValue, "en-US", '1.0-2')}% `;
        else if (aoi.Amount)
          return `Discount @Flat ${formatNumber(aoi.Amount, "en-US", '1.0-2')} off `;
    }
  }

  setScreenShotFile(order: OrderModel) {
    this.setPaymentScreenShotFile(order);
    this.setTransportScreenShotFile(order);
  }

  setPaymentScreenShotFile(order: OrderModel) {
    order.PaymentScreenShot = new FileModel({
      fileID: order.OrderID,
      filePath: this.appSettings.paymentScreenShotImgPath(order.OrderID)
    });
  }

  setTransportScreenShotFile(order: OrderModel) {
    order.TransportScreenShot = new FileModel({
      fileID: order.OrderID,
      filePath: this.appSettings.transportScreenShotImgPath(order.OrderID)
    });
  }

  getItemVarietyFromItemList(items: Array<ItemModel>, itemVarietyID: number): ItemVarietyModel {
    let matchingItemVariety: ItemVarietyModel = null;
    if (items && itemVarietyID) {
      items?.find(i => {
        matchingItemVariety = i.getVariety(itemVarietyID);
        return matchingItemVariety || false;
      });
    }

    return matchingItemVariety;
  }

  getRefOrder(refOrderID: number): Observable<OrderModel> {
    if (this.refOrder && this.refOrder.OrderID == refOrderID)
      return of(this.refOrder);
    else
      return this.getOrderDetails(refOrderID);
  }

  getAllCreditNoteSummary(OrderID: number, showLoader: boolean = true): Observable<any> {
    const dataToSend = {
      'OrderID': OrderID
    };
    return this.httpHelper.post<any, number>(this.appSettings.URL_GetAllCreditNoteSummary, dataToSend, showLoader)
      .pipe(map(res => res.Data));
  }

  private putTogetherOrderObject(orderList: Array<OrderModel>, orderItems?: Array<OrderItemModel>,
    additionalOrderItems?: Array<AdditionalOrderItemModel>, parties?: Array<PartyModel>,
    addresses?: Array<PartyAddressModel>, appliedPromoCodes?: Array<AppliedPromoCode>, referringOrders?: Array<OrderModel>): Array<OrderModel> {
    let resultOrders = new Array<OrderModel>();
    if (orderList && orderList.length > 0) {
      //Descending order by order ID;
      orderList.sort((a, b) => (Number(a.OrderID) > Number(b.OrderID)) ? -1 : 1);
      orderList.map(o => {
        o.OrderItems = orderItems?.filter(oi => oi.OrderID == o.OrderID).map(oi => new OrderItemModel(oi));
        o.AdditionalOrderItems = additionalOrderItems?.filter(aoi => aoi.OrderID == o.OrderID)
          .map(aoi => new AdditionalOrderItemModel(aoi));
        o.Address = new PartyAddressModel(addresses?.find(a => a.AddressID == o.AddressID));
        o.Party = new PartyModel(parties?.find(p => p.PartyID == o.CustomerID));
        o.PaymentScreenShot = new FileModel({
          fileID: o.OrderID,
          filePath: this.appSettings.paymentScreenShotImgPath(o.OrderID)
        });

        //applied promo codes
        if (appliedPromoCodes)
          o.AppliedPromoCodes = appliedPromoCodes?.filter(apc => apc.OrderID === o.OrderID)?.map(mpc => new AppliedPromoCode(mpc));

        o.TransportScreenShot = new FileModel({
          fileID: o.OrderID,
          filePath: this.appSettings.transportScreenShotImgPath(o.OrderID)
        });

        o.ReferringOrders = referringOrders?.filter(ro => ro.RefOrderID == o.OrderID)?.map(mpo => new OrderModel(mpo));
        resultOrders.push(new OrderModel(o));
      });
    }
    return resultOrders;
  }
}
