"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RateOfChangeModel = void 0;
var utilities_1 = require("../../utils/utilities");
var enum_1 = require("../../enum");
var RateOfChangeModel = /** @class */ (function () {
    function RateOfChangeModel(value) {
        Object.assign(this, value);
        if (!this.CurrentValue)
            this.CurrentValue = 0;
        // If there is no previous sales
        if (!this.ComparisonValue) {
            this.ValueChange = this.CurrentValue;
            // If no current sales ie. no sales previously and today also 
            if (!this.CurrentValue) {
                this.ChangeDirection = enum_1.ChangeDirectionType.Neutral;
            }
            else {
                this.ChangeDirection = enum_1.ChangeDirectionType.Incrementing;
                this.PercentageChange = 100;
            }
        }
        else {
            this.ValueChange = this.CurrentValue - this.ComparisonValue;
            if (this.ValueChange > 0) {
                this.ChangeDirection = enum_1.ChangeDirectionType.Incrementing;
                this.PercentageChange = utilities_1.Utilities.parseTo2DecPlaces((this.ValueChange / this.ComparisonValue) * 100);
            }
            else if (this.ValueChange < 0) {
                this.ValueChange = Math.abs(this.ValueChange);
                this.ChangeDirection = enum_1.ChangeDirectionType.Decrementing;
                this.PercentageChange = utilities_1.Utilities.parseTo2DecPlaces((this.ValueChange / this.ComparisonValue) * 100);
            }
            else {
                this.ChangeDirection = enum_1.ChangeDirectionType.Neutral;
                this.PercentageChange = 0;
            }
        }
    }
    return RateOfChangeModel;
}());
exports.RateOfChangeModel = RateOfChangeModel;
