"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderFilterModel = void 0;
var OrderFilterModel = /** @class */ (function () {
    function OrderFilterModel(value) {
        if (value)
            Object.assign(this, value);
    }
    Object.defineProperty(OrderFilterModel.prototype, "OrderNoOrPhoneNo", {
        get: function () {
            if (this.PhoneNo)
                return this.PhoneNo;
            if (this.OrderID)
                return this.OrderID.toString();
            return null;
        },
        set: function (value) {
            if (!value || !Number(value)) {
                this.PhoneNo = null;
                this.OrderID = null;
                return;
            }
            if (value.length == 10) {
                this.PhoneNo = value;
                this.OrderID = null;
            }
            else {
                this.OrderID = Number(value);
                this.PhoneNo = null;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderFilterModel.prototype, "AppliedCount", {
        get: function () {
            var count = 0;
            if (this.OrderID)
                count++;
            if (this.PhoneNo)
                count++;
            if (this.OrderStatus && this.OrderStatus.length > 0)
                count++;
            if (this.PaymentStatus && this.PaymentStatus.length > 0)
                count++;
            if (this.OrderFromDate)
                count++;
            if (this.OrderTillDate)
                count++;
            if (this.ReceiptGroup && this.ReceiptGroup.length > 0)
                count++;
            if (this.Party)
                count++;
            return count;
        },
        enumerable: false,
        configurable: true
    });
    OrderFilterModel.prototype.reset = function () {
        this.OrderFromDate = null;
        this.OrderTillDate = null;
        this.PaymentStatus = null;
        this.OrderStatus = null;
        this.ReceiptGroup = null;
        this.PhoneNo = null;
        this.OrderID = null;
        this.Party = null;
    };
    return OrderFilterModel;
}());
exports.OrderFilterModel = OrderFilterModel;
