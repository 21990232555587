import { Injectable } from '@angular/core'

import { BillPrintType, BusinessInfoModel, ReceiptGroupEnum, Utilities } from 'eccommons';

import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class AppSettings {

  public BID: number;
  public APP_ENDPOINT: string;
  public CDN_ENDPOINT: string;
  public MULTI_TENANT_ID: number = 0;
  public CURRENCY_CODE: string = 'INR';
  public CURRENCY_SYMBOL: string = '₹';
  public DATE_FORMAT: string = 'dd/MM/yyyy';
  public DATE_TIME_FORMAT: string = 'MMM d, y - h:mm a';
  public DB_DATE_FORMAT: string = 'yyyy-MM-dd';
  public PRINT_AGENT_PROTOCOL: string = 'bup-pagent';
  public PRINT_AGENT_ARG_LEN: number = 500;
  public PRINT_AGENT_PROTOCOL_GAP: number = 200;
  public PREFERRED_PRINT = BillPrintType.RECEIPT;
  public APP_OWNER_NAME: string;
  public APP_OWNER_EMAIL: string;
  public PRINTER_URL: string = 'http://localhost:5577/print';

  public readonly DEFAULT_PARTY_IMG = 'Assets/Commons/Images/defaultProfile.svg';

  readonly ITEM_BLK_UPLOAD_TEMPLATE_PATH = 'Assets/Commons/StaticFiles/item_bulk_upload_template.xlsx';
  readonly CONSTANTS = {
    ROUTE_HOME: './home',
    ROUTE_LOGIN: './login',
  };
  public readonly OUT_OF_STOCK_THRESHOLD: number = 5;
  public readonly LS_CART_ORDER = 'CartOrder';

  paymentScreenShotImgPath(orderID: number): string {
    if (orderID) {
      return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/PaymentScreenShot/${orderID}_payment.jpg`;
    }
  }

  transportScreenShotImgPath(orderID: number): string {
    if (orderID)
      return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/Transport/${orderID}_transport.jpg`;
  }

  get businessTitleImgPath(): string {
    if (this.BID)
      return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/Commons/title_logo.png`;
  }

  get businessLogoImgPath(): string {
    if (this.BID)
      return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/Commons/Logo.jpg`;
  }

  get businessUpLogoImgPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/bup_logo.svg`;
  }

  get businessUPlogoIconImgPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/bup_logo_icon.png`;
  }

  get loaderImgPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/loader.gif`;
  }

  get paymentActionInImagPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/money_in_arrow.svg`;
  }

  get paymentActionOutImagPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/money_out_arrow.svg`;
  }

  get moneyImagPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/rupee.svg`;
  }

  get financeReportImagPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/finance_report.png`;
  }

  // Order Status
  get cartImagePath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/cart.svg`;
  }

  get orderEnquireImagePath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/order_enquire.svg`;
  }

  // Payment Status
  get successImgPath(): string {
    return environment.cdn_endpoint + `Assets/Commons/Images/success.svg`;
  }

  partyLogoImgPath(partyID: number): string {
    if (this.BID && partyID)
      return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/Party/${partyID}_logo.png`;
  }

  partyDefaultLogoImgPath(): string {
    return environment.cdn_endpoint + this.DEFAULT_PARTY_IMG;
  }

  bankAccountQRImgPath(bankAccountID: number) {
    return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/BankAccount/QR_${bankAccountID}.jpg`;
  }

  receiptImgPath(receiptGroup: ReceiptGroupEnum, fileName: string): string {
    //CODE_SMELL: Hard convert to number
    return environment.cdn_endpoint + `Assets/BID${this.BID}/Images/Receipts/${Utilities.getReceiptFolderName(Number(receiptGroup))}/${fileName}`;
  }

  cdnPath(filePath: string): string {
    if (filePath)
      return environment.cdn_endpoint + filePath;
  }

  constructor() {
    //this.API_ENDPOINT = '/api/';    
  }

  /* Password set */
  readonly URL_SendOTP = 'EC/sendPassword';
  readonly URL_SetPassword = 'Account/setPassword';

  /* Categorys */
  readonly URL_GetCategoriesDetails = 'EC/getCategories';
  readonly URL_SaveCategory = 'EC/saveCategory';
  readonly URL_GetCategoriesByID = 'EC/getCategoryByID';
  readonly URL_GetItemByID = 'EC/getCategoryByID';
  readonly URL_SaveCategoriesDisplayOrder = 'EC/saveCategoriesDisplayOrder';

  /* Items */
  // readonly URL_GetItemDetails = 'EC/getItems';
  readonly URL_GetItemBasicDetails = 'EC/getItemBasicDetails';

  readonly URL_GetOrderDetails = 'EC/getOrderDetails';
  readonly URL_SearchOrderDetails = 'EC/searchOrderDetails';
  readonly URL_DeleteOrderDetails = 'Order/deleteOrderDetails';
  readonly URL_SaveOrderStatuses = 'EC/saveOrderStatuses';
  readonly URL_SaveOrderProperties = 'EC/saveOrderProperties';
  readonly URL_SaveReceivedOrderAmount = 'Order/saveReceivedOrderAmount';
  readonly URL_GetAllCreditNoteSummary = 'Order/getAllCreditNoteSummary';

  readonly URL_SaveItem = 'EC/saveItem';
  readonly URL_DeleteItemImage = 'EC/deleteItemImage';

  readonly URL_DeleteItemVariety = 'EC/deleteItemVariety';
  readonly URL_SaveBulkCategories = 'EC/saveBulkCategories';
  readonly URL_SaveItemBulkUpload = 'EC/saveItemBulkUpload';
  readonly URL_SaveItemDisplayOrder = 'EC/saveItemDisplayOrder';


  readonly URL_SaveOrderItems = 'EC/saveOrderItems';
  readonly URL_SaveOrderItem = 'EC/saveOrderItem';
  readonly URL_SaveAdditionalOrderItem = 'EC/saveAdditionalOrderItem';
  readonly URL_DeleteAdditionalOrderItem = 'EC/deleteAdditionalOrderItem';
  readonly URL_SaveOrderNotes = 'EC/saveOrderNotes';
  readonly URL_ChangeOrderItemUnitPrice = 'EC/changeOrderItemUnitPrice';
  readonly URL_MapPartyWithOrder = 'EC/mapPartyWithOrder';

  readonly URL_DeleteCartOrder = 'EC/deleteCartOrder';
  readonly URL_UploadPaymentScreenShot = 'EC/uploadPaymentScreenShot';
  readonly URL_UploadReceiptScreenShot = 'EC/uploadReceiptScreenShot';
  readonly URL_ShareReceipt = 'EC/shareReceipt';

  readonly URL_SaveTransportImage = 'EC/saveTransportImage';
  readonly URL_DeleteFile = 'EC/deleteFile';

  /* Tax */
  readonly URL_GetTaxAndCharges = 'EC/getTaxAndCharges';
  readonly URL_SaveTaxAndCharges = 'EC/saveTaxAndCharge';
  readonly URL_GetTaxes = 'EC/getTaxes';

  /* Finance */
  readonly URL_GetPaymentPendingOrders = 'Finance/getPaymentPendingOrders';
  readonly URL_SavePaymentCategory = 'Finance/savePaymentCategory';
  readonly URL_GetPaymentCategory = 'Finance/getPaymentCategories';
  readonly URL_SaveTranasaction = 'Finance/SaveTranasaction';
  readonly URL_DeletePaymentCategory = 'Finance/deletePaymentCategory';
  readonly URL_GetAccountDetails = 'Finance/getAccountDetails';
  readonly URL_SaveAccount = 'Finance/saveAccount';
  readonly URL_GetCashBalance = 'Finance/getCashBalance';
  readonly URL_SetInitialCashBalance = 'Finance/setInitialCashBalance';
  readonly URL_GetTransactions = 'Finance/getTransactions';
  readonly URL_GetStockValue = 'Finance/getStockValue';
  readonly URL_GetPartyLoanSummary = 'Finance/getPartyLoanSummary';
  readonly URL_SaveRouting = 'Finance/saveRouting';

  /* Banner */
  readonly URL_Banner = 'business/banner';  
  readonly URL_SaveBannerDisplayOrder = 'business/banner-displayorder';

  readonly URL_Validate_Admin_User = 'Account/ValidateAdminUser'

  /* Discount */
  readonly URL_GetDiscountDetails = 'EC/getDiscountDetails';
  readonly URL_SaveDiscountDetails = 'EC/saveDiscountDetails';

  /* Stock */
  readonly URL_SaveItemVarietyPrice = 'EC/saveItemVarietyPrice';
  readonly URL_SaveItemVarietyStock = 'EC/saveItemVarietyStock';
  readonly URL_SaveItemVarietyUnit = 'EC/saveItemVarietyUnit';

  /* Customer */
  readonly URL_GetCustomerMessage = 'EC/getCustomerMessage';
  readonly URL_SaveCustomerMessageStatus = 'EC/saveCustomerMessageStatus';
  readonly URL_CheckPhoneRegistered = 'EC/checkPhoneRegistered';
  readonly URL_RegisterUser = 'EC/registerUser';
  readonly URL_RegisterBusinessUser = 'EC/registerBusinessUser';



  /* Dashboard */
  readonly URL_GetDashboardCounts = 'Dashboard/getDashboardCounts';
  readonly URL_GetTodaysSaleInfo = 'Dashboard/getTodaysSaleInfo';
  readonly URL_GetLastXDaysSaleInfo = 'Dashboard/getLastXDaysSaleInfo';
  readonly URL_GetCategorySaleInfo = 'Dashboard/getCategorySalesInfo';
  readonly URL_GetOrderCountByStatus = 'EC/getOrderCountByStatus';

  /* Site Message */    
  readonly URL_SiteMessage = '/business/site-message';

  /* Export */
  readonly URL_GetBasicVarietyList = 'EC/getBasicVarietyDetails';

  /* Site Settings */
  readonly URL_SiteImage = 'business/site-images';
  readonly URL_SaveTitleImage = 'EC/saveTitleImage';
  readonly URL_SaveLogoImage = 'EC/saveLogoImage';
  readonly URL_SaveHomeCoverImage = 'EC/saveHomeCoverImage';
  readonly URL_SaveAboutImage = 'EC/saveAboutImage';
  readonly URL_SaveSpinnerImage = 'EC/saveSpinnerImage';
  readonly URL_SaveSiteBGImage = 'EC/saveSiteBGImage';
  readonly URL_SaveQRImage = 'EC/saveQRImage';
  readonly URL_SavePriceListHeaderImage = 'EC/savePriceListHeaderImage';
  readonly URL_SavePriceListBGImage = 'EC/savePriceListBGImage';
  readonly URL_SavePriceListPDF = 'EC/savePriceListPDF';
  readonly URL_GetBankAccountDetails = 'EC/getBankAccountDetails';
  readonly URL_GetDropLocationDetails = 'Business/getDropLocationDetails';
  readonly URL_SaveDropLocation = 'Business/saveDropLocationDetails';
  readonly URL_GetPromoCode = 'EC/GetPromoCode';
  readonly URL_SavePromoCode = 'EC/SavePromoCode';

  /* Business */
  readonly URL_GetBusinessPaymentSettings = 'Business/GetBusinessPaymentSettings';
  readonly URL_GetBusinessDeliverySettings = 'Business/GetBusinessDeliverySettings';
  readonly URL_SaveBusinessDeliverySettings = 'Business/saveBusinessDeliverySettings';
  readonly URL_GetBusinessSMSSettings = 'Business/GetBusinessSMSSettings';
  readonly URL_SaveBusinessSMSSettings = 'Business/saveBusinessSMSSettings';
  readonly URL_SaveBusinessMinOrderValue = 'Business/saveBusinessMinOrderValue';
  readonly URL_GetBusinessBasicInfo = 'Business/GetBasicInfo';
  readonly URL_saveBusinessPromoCodeSettings = "Business/saveBusinessPromoCodeSettings";
  readonly URL_GetBusinessSiteInfo = 'business/site-info';


  /* Party */
  readonly URL_GetPartiesDetails = 'Party/getPartiesDetails';
  readonly URL_SearchParty = 'Party/searchParty';
  readonly URL_SaveParty = 'Party/saveParty';
  readonly URL_GetPartyAddress = 'Party/getPartyAddress';
  readonly URL_SavePartyAddress = 'Party/savePartyAddress';
  readonly URL_SavePartyPayment = 'Party/savePartyPaymet';
  readonly URL_GetExpenseDetails = 'Party/getExpense';
  readonly URL_GetPartyProfile = 'Party/getPartyProfile';
  readonly URL_SavePartyProfile = 'Party/savePartyProfile';

  /* Report */
  readonly URL_GetStockLogReport = 'Report/getStockLogs';

  /* Site Settings */
  readonly URL_SEOSETTING = '/business/seo';
  readonly USP_SocialMedia = '/business/social-media';
  /*****************************************************
   **** URLs *****
   *****************************************************/

  /*** Local Storage Names ***/
  readonly LS_USER_INFO = 'USER_INFO';

  API_URL = {
    getItems(categoryIDs?: string, itemIDs?: string, onlyActive: boolean = false) {
      return `EC/getItems?categoryIDs=${categoryIDs}&itemIDs=${itemIDs}&onlyActive=${onlyActive}`;
    }
  }

  APP_URL = {
    order_list: 'order/orderlist',

    query_string: {
      orderID: 'OrderID',
      paymentStatusID: 'PaymentStatusID',
      orderStatusID: 'OrderStatusID',
      partyID: 'PartyID'
    },

    getSavePartyUrl(partyID): string {
      return `saveParty/${partyID}`;
    }
  }

}