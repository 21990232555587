<div class="table-container mt-4">
  <div class="table-responsive position-relative">

    <div class="flxRowSB align-center">
      <!-- Table Title -->
      <div>
        <h4 class="m-0 p-2" *ngIf="tableTitle">{{tableTitle}}</h4>
      </div>

      <div class="flxRowWAlignCenter">
        <!-- Search bar -->
        <ng-container *ngIf="tableConfig.AllowSearch">
          <div class="form-group d-flex">
            <input type="search" class="form-control form-control-m" [(ngModel)]="searchKey" placeholder="Search Party"
              (input)="onUserSearchInput($event.target.value)">
            <i class="far fa-times-circle icon-inside-left-parent" *ngIf="searchKey"
              (click)="searchKey = ''; loadTableData()"></i>

            <span class="form__label">Search </span>
          </div>
        </ng-container>

        <!-- Pager Controls -->
        <ng-container *ngTemplateOutlet="pageControls"></ng-container>

        <!-- Export -->
        <i *ngIf="tableConfig.ExcelConfig?.AllowExcelExport" title="Export" class="fas fa-file-excel pr-4"
          (click)="exportToExcel()"></i>
      </div>
    </div>

    <div class="table-wrap">
      <table class="table table-sm table-striped" *ngIf="tableConfig" id="table" #appTable>
        <!-- Table Head -->
        <thead class="bg-light">
          <tr>
            <th scope="col" *ngIf="tableConfig.EnableRowSelection">
              <input type="checkbox" [(ngModel)]="allRowsSelected" (change)="onAllRowsSelected()" /> All
            </th>

            <th scope="col" *ngIf="tableConfig.ShowRowNo">#</th>

            <ng-container *ngFor="let header of tableConfig.Headers; let colIndex=index">
              <th scope="col" [ngClass]="header.HeaderClass">{{header.ColumnName}}</th>
            </ng-container>

            <th scope="col" *ngIf="tableConfig.CheckFriendly">Check</th>

            <th scope="col" *ngIf="tableConfig.AllowReOrdering"></th>
          </tr>
        </thead>

        <!-- Table Body With Drag & Drop -->
        <ng-container *ngIf="tableConfig.AllowReOrdering;else withoutReordering">
          <tbody cdkDropList (cdkDropListDropped)="onDrop($event)">
            <tr *ngFor="let row of tableData; let rowIndex = index" cdkDrag [cdkDragDisabled]="!dragEnabled"
              cdkDragLockAxis="y" [ngClass]="{'no-highlight': checkedColumns[rowIndex]}">
              <ng-container *ngTemplateOutlet="gridDataRows; context:{ row: row, rowIndex : rowIndex}"></ng-container>
            </tr>

            <tr *ngIf="!tableData || tableData.length < 1">
              <td [attr.colspan]="totalColumns" class="text-center alert-danger">
                No Data Found
              </td>
            </tr>
          </tbody>
        </ng-container>

        <!-- Table Body Without Drag & Drop -->
        <ng-template #withoutReordering>
          <tbody>
            <tr *ngFor="let row of tableData; let rowIndex = index"
              [ngClass]="{'no-highlight': checkedColumns[rowIndex]}">
              <ng-container *ngTemplateOutlet="gridDataRows; context:{ row: row, rowIndex : rowIndex}"></ng-container>
            </tr>

            <tr *ngIf="!tableData || tableData.length < 1">
              <td [attr.colspan]="totalColumns" class="text-center alert-danger">
                No Data Found
              </td>
            </tr>
          </tbody>
        </ng-template>

        <!-- Computed row -->
        <tr *ngIf="tableConfig.HasComputedColumn">
          <td *ngIf="tableConfig.EnableRowSelection">
          </td>

          <td scope="col" *ngIf="tableConfig.ShowRowNo"></td>

          <td *ngFor="let header of tableConfig.Headers; let colIndex=index">
            <span>{{getColumnComputedValue(header)}}</span>
          </td>
        </tr>
      </table>

      <!-- Bottom Page Control -->
      <ng-container *ngIf="tableConfig.PageSize > 15">
        <ng-container *ngTemplateOutlet="pageControls"></ng-container>
      </ng-container>
    </div>
  </div>
</div>

<!-- Page Controls -->
<ng-template #pageControls>
  <div *ngIf="showPagerControls" class="flxRowNwSt page-control-container">
    <!-- Total Records -->
    <span class="text-muted mr-2"># {{rowData?.length}} &nbsp;/&nbsp;</span>
    <!-- Page Size -->
    <div>
      <span>Page Size </span>

      <input type="number" [(ngModel)]="tableConfig.PageSize" (change)="onPageSizeChange()" appFitWidth min=1 max=100 />
    </div>

    <!-- Left Arrow -->
    <button class="btn btn-sm btn-outline-primary" (click)="goToPreviousPage()">
      <i class="fa fa-caret-left"></i>
    </button>

    <!-- Page No -->
    <input type="number" [(ngModel)]="currentPageNo" appFitWidth min=1 [max]="totalPages" (change)="onPageNoChange()" />
    &nbsp;<span class="text-muted">/
      {{this.totalPages}}</span>

    <!-- Right Arrow -->
    <button class="btn btn-sm btn-outline-primary" (click)="goToNextPage()">
      <i class="fa fa-caret-right"></i>
    </button>
  </div>
</ng-template>

<!-- Table Row Template -->
<ng-template #gridDataRows let-row="row" let-rowIndex="rowIndex">
  <td *ngIf="tableConfig.EnableRowSelection">
    <input type="checkbox" [(ngModel)]="row['Selected']" (click)="rowSelect($event, rowIndex,row)" />
  </td>

  <td *ngIf="tableConfig.ShowRowNo">{{((currentPageNo -1) * tableConfig.PageSize)+ rowIndex + 1}}</td>

  <td *ngFor="let header of tableConfig.Headers; let colIndex=index" class="text-overflow-hidden"
    [ngClass]="getDynamicClass(row, header)" [ngSwitch]="header.ColumnType">
    <!-- Lable Column -->
    <ng-container *ngSwitchCase="columnType.LABLE">
      <div class="cell-content" [title]="row[header.FieldName]">
        <span [innerHtml]="row[header.FieldName] | Highlight: searchKey"></span>
      </div>
    </ng-container>

    <!-- Boolean Column -->
    <ng-container *ngSwitchCase="columnType.BOOLEAN">
      <span [ngClass]="row[header.FieldName] ? 'positiveText': 'negativeText'">
        {{row[header.FieldName]? 'Yes': 'No'}}</span>
    </ng-container>

    <!-- Enum Column -->
    <ng-container *ngSwitchCase="columnType.ENUM">
      <div class="cell-content" [title]="header.Enum[row[header.FieldName]]">
        <span>{{header.Enum[row[header.FieldName]]}}</span>
      </div>
    </ng-container>

    <!-- CURRENCY Column -->
    <ng-container *ngSwitchCase="columnType.CURRENCY">
      <span [innerHtml]="row[header.FieldName] | CustomCurrency | Highlight: searchKey"></span>
    </ng-container>

    <!-- DATE Column -->
    <ng-container *ngSwitchCase="columnType.DATE">
      <span [innerHtml]="row[header.FieldName] | date: dateFormat"></span>
    </ng-container>

    <!-- DATETIME Column -->
    <ng-container *ngSwitchCase="columnType.DATETIME">
      <span [innerHtml]="row[header.FieldName] | date: dateTimeFormat"></span>
    </ng-container>

    <!-- Sub Table Column -->
    <ng-container *ngSwitchCase="columnType.SUB_TABLE">
      <ec-app-table *ngIf="row[header.FieldName]" [tableConfig]="header.SubTableConfig"
        [rowData]="getRowDataInArray(row[header.FieldName])">
      </ec-app-table>
    </ng-container>

    <!-- InputType Column -->
    <ng-container *ngSwitchCase="columnType.TEXTBOX">
      <input type="textbox" [value]="row[header?.FieldName]"
        (change)="onTxtBoxChanged(actionType.TEXTBOX,$event, rowIndex, row)" />
    </ng-container>

    <!-- LINK Column -->
    <ng-container *ngSwitchCase="columnType.LINK">
      <div class="cell-content" [title]="row[header.FieldName]">
        <a (click)="onLinkClicked(rowIndex, header.FieldName, row)">
          <span [innerHtml]="row[header.FieldName] | Highlight: searchKey"></span>
        </a>
      </div>
    </ng-container>

    <!-- Action Column -->
    <ng-container *ngSwitchCase="columnType.ACTION">
      <ng-container [ngSwitch]="header.ActionOption.ActionType">
        <!-- Action Button -->
        <ng-container *ngSwitchCase="actionType.BUTTON">
          <button type="button" class="btn btn-sm btn-primary"
            (click)="actionClick(actionType.BUTTON, rowIndex, row)">{{header.ActionOption.Lable}}</button>
        </ng-container>

        <!-- Icon View -->
        <ng-container *ngSwitchCase="actionType.ICON_VIEW">
          <i class="fa fa-eye" title="View" (click)="actionClick(actionType.ICON_VIEW, rowIndex, row)"></i>
        </ng-container>

        <!-- Icon Edit -->
        <ng-container *ngSwitchCase="actionType.ICON_EDIT">
          <i class="fas fa-pencil-alt" title="Edit" (click)="actionClick(actionType.ICON_EDIT, rowIndex, row)"></i>
        </ng-container>

        <!-- Icon Delete -->
        <ng-container *ngSwitchCase="actionType.ICON_DELETE">
          <i class="fas fa-trash top-right-cornered negativeIcon-sm"
            (click)="actionClick(actionType.ICON_DELETE, rowIndex, row)"></i>
        </ng-container>
      </ng-container>
    </ng-container>
  </td>

  <td *ngIf="tableConfig.CheckFriendly">
    <input type="checkbox" [(ngModel)]="checkedColumns[rowIndex]" />
  </td>

  <td *ngIf="tableConfig.AllowReOrdering">
    <div>
      <svg class="drag-handle bi bi-list handle" cdkDragHandle (mouseenter)="dragEnabled = true"
        (mouseleave)="dragEnabled = false" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
        xmlns="http://www.w3.org/2000/svg" *ngIf="tableConfig.AllowReOrdering">
        <path fill-rule="evenodd"
          d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
          clip-rule="evenodd" />
      </svg>
    </div>
  </td>
</ng-template>