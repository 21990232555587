import { Component, OnInit, Input } from '@angular/core';
import { ItemModel, ItemVarietyModel, DiscountModel, AlertType, SelectOptionModel, DeductionType, AvailabilityType, Utilities } from 'eccommons';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { AdminService } from '../services/admin.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss']
})
export class StocksComponent implements OnInit {

  itemList: Array<ItemVarietyModel>;
  discounts: Array<DiscountModel>;
  editingPriceItemVarietyID: number = null;
  editingUnitItemVarietyID: number = null;
  editingUnitModeType: AvailabilityType;
  editingUnitValue: number;

  unitModeOptions: Array<SelectOptionModel>;
  unitModeType = AvailabilityType;
  deductionTypeOptions: Array<SelectOptionModel>;
  priceFormGroup: UntypedFormGroup;

  private itemListOriginal: Array<ItemVarietyModel>;

  constructor(private httpHelper: HttpHelperService, private appSettings: AppSettings,
    private adminService: AdminService, private sharedService: SharedService) {
    this.unitModeOptions = [
      {
        lable: 'Unlimited',
        value: AvailabilityType.Unlimited
      },
      {
        lable: 'Stocked',
        value: AvailabilityType.Stocked
      },
      {
        lable: 'Out_of_stock',
        value: AvailabilityType.Out_Of_Stock
      }
    ];
    this.deductionTypeOptions = [
      {
        lable: '%',
        value: DeductionType.PERCENT
      },
      {
        lable: this.appSettings.CURRENCY_SYMBOL,
        value: DeductionType.AMOUNT
      }
    ];
  }

  ngOnInit(): void {
    this.adminService.getItemBasicDetails().subscribe(data => {
      if (data) {
        if (data[0]) {
          this.itemList = data[0].map(d => new ItemVarietyModel(d));
          this.itemListOriginal = JSON.parse(JSON.stringify(this.itemList)) as Array<ItemVarietyModel>;
        }
        if (data[1])
          this.discounts = data[1].map(d => new DiscountModel(d));
      }
    });
  }  
}
