"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoneyOutMasterCategoryType = void 0;
var MoneyOutMasterCategoryType;
(function (MoneyOutMasterCategoryType) {
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Purchase"] = 13] = "Purchase";
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Expense"] = 15] = "Expense";
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Sales_Return"] = 17] = "Sales_Return";
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Loan_Given"] = 19] = "Loan_Given";
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Repaying_Loan"] = 21] = "Repaying_Loan";
    MoneyOutMasterCategoryType[MoneyOutMasterCategoryType["Refund"] = 23] = "Refund";
})(MoneyOutMasterCategoryType = exports.MoneyOutMasterCategoryType || (exports.MoneyOutMasterCategoryType = {}));
