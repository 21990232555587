"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DataListConfigModel = void 0;
var DataListConfigModel = /** @class */ (function () {
    function DataListConfigModel(value) {
        this.PageSize = 10;
        Object.assign(this, value);
    }
    return DataListConfigModel;
}());
exports.DataListConfigModel = DataListConfigModel;
