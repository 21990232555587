"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemVarietyBaseModel = void 0;
var availability_type_enum_1 = require("../../enum/availability-type.enum");
var deduction_type_enum_1 = require("../../enum/deduction-type.enum");
var utilities_1 = require("../../utils/utilities");
var ItemVarietyBaseModel = /** @class */ (function () {
    function ItemVarietyBaseModel(value) {
        Object.assign(this, value);
        if (value) {
            this.DiscountValue = utilities_1.Utilities.parseNumber(this.DiscountValue);
            this.MRP = utilities_1.Utilities.parseNumber(this.MRP);
            // Set Discount Display Info
            if (this.DiscountValue)
                this.DiscountDisplayInfo = "".concat(this.DiscountValue, " ").concat(deduction_type_enum_1.DeductionType[this.DiscountType]);
        }
    }
    Object.defineProperty(ItemVarietyBaseModel.prototype, "HasDiscount", {
        get: function () {
            if (this.DiscountValue && this.DiscountType in deduction_type_enum_1.DeductionType) {
                return true;
            }
            return false;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemVarietyBaseModel.prototype, "StockAvailabilityStatus", {
        get: function () {
            return utilities_1.Utilities.valueToEnumName(availability_type_enum_1.AvailabilityType, this.AvailablilityType);
        },
        enumerable: false,
        configurable: true
    });
    return ItemVarietyBaseModel;
}());
exports.ItemVarietyBaseModel = ItemVarietyBaseModel;
