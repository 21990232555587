"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteSettingsModel = void 0;
var SiteSettingsModel = /** @class */ (function () {
    function SiteSettingsModel(value) {
        Object.assign(this, value);
    }
    return SiteSettingsModel;
}());
exports.SiteSettingsModel = SiteSettingsModel;
