/*** Core ***/
import { Component, Inject, ElementRef, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

/*** Libraries ***/
import { Subscription } from 'rxjs/internal/Subscription';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PopUpDialogModel } from 'eccommons';

/*** Services ***/
import { AppSettings } from './shared/services/appSettings.services';
import { SharedService } from './shared/services/shared.service';
import { AccountService } from './account/service/account.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'ecadmin';
  showLoader: boolean = false;
  loaderImgPath: string;
  isLoggedIn: boolean;
  popUpDialogDetails: PopUpDialogModel;
  showConfirmDialogModal: boolean = false;
  sideMenuShown: boolean = true;
  layout: "web" | "mobile" | "login" = "login";

  private compSubscriptions = new Subscription();
  private routerEvents: Observable<Event>;

  /*** Constructor ****/
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private appSettings: AppSettings, private elementRef: ElementRef,
    private sharedService: SharedService, private accountService: AccountService,
    private router: Router, private route: ActivatedRoute,
    private breakpointObserver: BreakpointObserver
  ) {
    // detect screen size changes
    this.breakpointObserver.observe([
      "(max-width: 480px)"
    ]).subscribe((result: BreakpointState) => {
      // Result matches if mobile screen
      if (result.matches) {
        this.sharedService.currentScreenSize = "mobile";
      } else {
        // web screen
        this.sharedService.currentScreenSize = "web";
      }

      this.setLayout();
    });
  }

  /*** Lifecycle Events ****/
  // On Init  
  ngOnInit() {
    this.isLoggedIn = this.accountService.IsLoggedIn;

    if (this.isLoggedIn) {
      this.appSettings.BID = this.accountService.BID;
    }
    else {
      this.accountService.logOutUser();
    }

    this.loaderImgPath = this.appSettings.loaderImgPath;

    // Listen for Loader change
    this.compSubscriptions.add(this.sharedService.showLoaderSubject.subscribe(show => {
      Promise.resolve(null).then(() => this.showLoader = show);
    }));

    // Listen for Login State Change
    this.compSubscriptions.add(this.accountService.loginStateChangeSubject.subscribe(change => {
      this.isLoggedIn = this.accountService.IsLoggedIn;
      this.setLayout();
    }));

    // Listen for Pop Up Dialog
    this.compSubscriptions.add(this.sharedService.popUpDialogSubject.subscribe(cd => {
      if (cd) {
        this.popUpDialogDetails = cd;
        this.showConfirmDialogModal = true;
      }
    }));

    // Listen for Route Change
    this.listenRouteChange();
  }

  // On Destroy
  ngOnDestroy(): void {
    this.compSubscriptions.unsubscribe();
  }

  /*** Methods ****/
  confirmDialogResponse(proceed: boolean) {
    this.popUpDialogDetails = null;
    this.sharedService.popUpDialogResponseSubject.next(proceed);
  }

  confirmDialogClose() {
    this.showConfirmDialogModal = false;
  }

  menuShowEvent(value) {
    this.sideMenuShown = value;
  }

  /*** Private Methods ***/
  private listenRouteChange() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) route = route.firstChild
        return route
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      if (data) {
        let title = data['title'] ?? '';
        this.sharedService.pageTitleSubject.next(title);
      }
    }
    )
  }

  private setLayout() {
    if (!this.isLoggedIn) {
      this.layout = "login";
      return;
    }

    if (this.sharedService.currentScreenSize == "mobile") {
      this.layout = "mobile";
    }
    else {
      this.layout = "web";
    }
  }
}
