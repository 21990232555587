"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StateModel = void 0;
var StateModel = /** @class */ (function () {
    function StateModel(value) {
        Object.assign(this, value);
    }
    return StateModel;
}());
exports.StateModel = StateModel;
