"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableActionModel = void 0;
var TableActionModel = /** @class */ (function () {
    function TableActionModel(value) {
        Object.assign(this, value);
    }
    return TableActionModel;
}());
exports.TableActionModel = TableActionModel;
