"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Alert = void 0;
var Alert = /** @class */ (function () {
    function Alert(init) {
        this.keepAfterRouteChange = true;
        Object.assign(this, init);
    }
    return Alert;
}());
exports.Alert = Alert;
