"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentCategoryModel = void 0;
var utilities_1 = require("../../utils/utilities");
var commons_1 = require("../commons");
var PaymentCategoryModel = /** @class */ (function (_super) {
    __extends(PaymentCategoryModel, _super);
    function PaymentCategoryModel(value) {
        var _this = _super.call(this, value) || this;
        _this.CategoryID = utilities_1.Utilities.parseNumber(_this.CategoryID);
        _this.MasterCategoryID = utilities_1.Utilities.parseNumber(_this.MasterCategoryID);
        _this.ParentID = utilities_1.Utilities.parseNumber(_this.ParentID);
        _this.IsFunctional = utilities_1.Utilities.parseBoolean(_this.IsFunctional);
        _this.ParentID = utilities_1.Utilities.parseNumber(_this.ParentID);
        _this.IsActive = utilities_1.Utilities.parseBoolean(_this.IsActive);
        return _this;
    }
    Object.defineProperty(PaymentCategoryModel.prototype, "HasChildren", {
        get: function () {
            var _a;
            return ((_a = this.Children) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PaymentCategoryModel.prototype, "IsPartyLinked", {
        get: function () {
            return this.PartyID > 0;
        },
        enumerable: false,
        configurable: true
    });
    PaymentCategoryModel.prototype.addChild = function (newChild) {
        if (!this.Children)
            this.Children = new Array();
        this.Children.push(newChild);
    };
    PaymentCategoryModel.prototype.getAllLeafNodeIDs = function () {
        var idList = [];
        if (this.HasChildren) {
            this.Children.forEach(function (chld) {
                idList = idList.concat(chld.getAllLeafNodeIDs());
            });
        }
        else
            idList = this.CategoryID ? [this.CategoryID] : null;
        return idList;
    };
    return PaymentCategoryModel;
}(commons_1.BaseModel));
exports.PaymentCategoryModel = PaymentCategoryModel;
