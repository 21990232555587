"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileModel = void 0;
var FileModel = /** @class */ (function () {
    function FileModel(value) {
        Object.assign(this, value);
    }
    return FileModel;
}());
exports.FileModel = FileModel;
