"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryModel = void 0;
var utils_1 = require("../../utils");
var CategoryModel = /** @class */ (function () {
    function CategoryModel(value) {
        Object.assign(this, value);
        if (value) {
            this.MaxDiscountValue = utils_1.Utilities.parseNumber(this.MaxDiscountValue) | 0;
            this.IsActive = utils_1.Utilities.parseBoolean(this.IsActive);
        }
    }
    Object.defineProperty(CategoryModel.prototype, "HasDiscount", {
        get: function () {
            if (this.MaxDiscountValue > 0)
                return true;
            else
                return false;
        },
        enumerable: false,
        configurable: true
    });
    return CategoryModel;
}());
exports.CategoryModel = CategoryModel;
