"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UrlModel = void 0;
var UrlModel = /** @class */ (function () {
    // getQueryParmas(): Params {
    //     if (this.qparams) {
    //         let key = this.qparams.key;
    //         let param_value = this.qparams.value;
    //         if (key == 'step')            
    //             return { 'step': param_value };
    //         else
    //             return { key: param_value };            
    //     }
    //     return null;
    // }
    function UrlModel(value) {
        Object.assign(this, value);
    }
    return UrlModel;
}());
exports.UrlModel = UrlModel;
