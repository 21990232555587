"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvailabilityType = void 0;
var AvailabilityType;
(function (AvailabilityType) {
    AvailabilityType[AvailabilityType["Unlimited"] = 1] = "Unlimited";
    AvailabilityType[AvailabilityType["Out_Of_Stock"] = 2] = "Out_Of_Stock";
    AvailabilityType[AvailabilityType["Stocked"] = 3] = "Stocked";
    AvailabilityType[AvailabilityType["Low_Stock"] = 4] = "Low_Stock";
})(AvailabilityType = exports.AvailabilityType || (exports.AvailabilityType = {}));
