"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RouteFlowModel = void 0;
var RouteFlowModel = /** @class */ (function () {
    function RouteFlowModel(value) {
        Object.assign(this, value);
    }
    return RouteFlowModel;
}());
exports.RouteFlowModel = RouteFlowModel;
