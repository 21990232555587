"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryStateCodes = void 0;
var select_option_model_1 = require("../models/commons/select-option.model");
var country_model_1 = require("../models/user/country.model");
var state_model_1 = require("../models/user/state.model");
var CountryStateCodes = /** @class */ (function () {
    function CountryStateCodes() {
    }
    CountryStateCodes.CountriesSelectOptions = function () {
        return this.Countries.map(function (c) { return new select_option_model_1.SelectOptionModel({
            value: c.CountryCode,
            lable: c.Country
        }); });
    };
    CountryStateCodes.StateSelectOptionsForCountry = function (countryCode) {
        var _a;
        return (_a = this.Countries.find(function (c) { return c.CountryCode == countryCode; })) === null || _a === void 0 ? void 0 : _a.StatesSelectOptions;
    };
    CountryStateCodes.countryName = function (countryCode) {
        var _a;
        if (countryCode)
            return (_a = this.Countries.find(function (c) { return c.CountryCode == countryCode; })) === null || _a === void 0 ? void 0 : _a.Country;
        else
            return null;
    };
    CountryStateCodes.stateName = function (countryCode, stateCode) {
        var _a, _b;
        if (countryCode && stateCode)
            return (_b = (_a = this.Countries.find(function (c) { return c.CountryCode == countryCode; })) === null || _a === void 0 ? void 0 : _a.States.find(function (s) { return s.StateCode == stateCode; })) === null || _b === void 0 ? void 0 : _b.State;
        else
            return null;
    };
    CountryStateCodes.Countries = [
        new country_model_1.CountryModel({
            Country: 'India',
            CountryCode: 'IND',
            States: [
                new state_model_1.StateModel({
                    State: 'Tamil Nadu',
                    ShortName: 'TN',
                    StateCode: 33
                }),
                new state_model_1.StateModel({
                    State: 'Kerala',
                    ShortName: 'KL',
                    StateCode: 31
                }),
                new state_model_1.StateModel({
                    State: 'Karnataka',
                    ShortName: 'KA',
                    StateCode: 28
                }),
                new state_model_1.StateModel({
                    State: 'Andrapradesh',
                    ShortName: 'KL',
                    StateCode: 27
                }),
            ]
        })
    ];
    return CountryStateCodes;
}());
exports.CountryStateCodes = CountryStateCodes;
