"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemVarietyModel = void 0;
var enum_1 = require("../../enum");
var utilities_1 = require("../../utils/utilities");
var item_variety_base_model_1 = require("./item-variety-base.model");
var ItemVarietyModel = /** @class */ (function (_super) {
    __extends(ItemVarietyModel, _super);
    function ItemVarietyModel(value) {
        var _this = _super.call(this, value) || this;
        Object.assign(_this, value);
        if (value) {
            // load primary & secondary price
            _this.setPrimaryAndSecondaryPrice();
            if (_this.PrimaryMinSellingUnit)
                _this.PrimaryMinSellingUnit = utilities_1.Utilities.parseNumber(_this.PrimaryMinSellingUnit);
            if (_this.SecondaryMinSellingUnit)
                _this.SecondaryMinSellingUnit = utilities_1.Utilities.parseNumber(_this.SecondaryMinSellingUnit);
            // Available Quantity
            _this.AvailableQty = utilities_1.Utilities.parseNumber(_this.AvailableQty);
            // Availablity Type
            _this.AvailablilityType = utilities_1.Utilities.getAvailabilityType(value.AvailableQty);
            // Defaults to Primary
            if (!_this.AvailabilityUnit)
                _this.AvailabilityUnit = enum_1.UnitLevel.PrimaryUnit;
            // Defaults to Primary
            if (!_this.SellingUnit)
                _this.SellingUnit = enum_1.UnitLevel.PrimaryUnit;
            //Availability Status set
            if (_this.AvailablilityType == enum_1.AvailabilityType.Unlimited) {
                _this.PrimaryUnitAvailabilityStatus = enum_1.ItemAvailabilityStatus.AVAILABLE;
                _this.SecondaryUnitAvailabilityStatus = enum_1.ItemAvailabilityStatus.AVAILABLE;
                _this.DisplayStockInfo = 'Unlimited';
            }
            else if (_this.AvailablilityType == enum_1.AvailabilityType.Out_Of_Stock) {
                _this.PrimaryUnitAvailabilityStatus = enum_1.ItemAvailabilityStatus.OUT_OF_STOCK;
                _this.SecondaryUnitAvailabilityStatus = enum_1.ItemAvailabilityStatus.OUT_OF_STOCK;
                _this.DisplayStockInfo = 'Out_Of_Stock';
            }
            else if (_this.AvailablilityType == enum_1.AvailabilityType.Stocked) {
                _this.PrimaryAvailableQty = _this.getAvailableQtyByUnit(enum_1.UnitLevel.PrimaryUnit);
                _this.SecondaryAvailableQty = _this.getAvailableQtyByUnit(enum_1.UnitLevel.SecondaryUnit);
                if (_this.SellingUnit == enum_1.UnitLevel.PrimaryUnit) {
                    _this.PrimaryUnitAvailabilityStatus = _this.getItemAvailabilityStatus(_this.PrimaryAvailableQty);
                }
                else {
                    _this.SecondaryUnitAvailabilityStatus = _this.getItemAvailabilityStatus(_this.SecondaryAvailableQty);
                }
                //Compute Display Stock Info
                if (_this.AvailabilityUnit == enum_1.UnitLevel.PrimaryUnit) {
                    var availableQty = _this.PrimaryAvailableQty;
                    if (_this.UnitConversionValue && !utilities_1.Utilities.isWholeNumber(availableQty)) {
                        var fraction = availableQty % 1;
                        availableQty = availableQty - fraction;
                        _this.DisplayStockInfo = "".concat(availableQty, " ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, _this.PrimaryUnitCode), " ").concat(utilities_1.Utilities.parseTo2DecPlaces(fraction * _this.UnitConversionValue), " ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, _this.SecondaryUnitCode));
                    }
                    else
                        _this.DisplayStockInfo = "".concat(availableQty, " ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, _this.PrimaryUnitCode));
                }
                else if (_this.AvailabilityUnit == enum_1.UnitLevel.SecondaryUnit) {
                    _this.DisplayStockInfo = "".concat(_this.SecondaryAvailableQty, " ").concat(_this.SecondaryUnitName);
                }
            }
            //Selling Unit
            _this.IsMultiUnit = false;
            if (_this.SellingUnit == enum_1.UnitLevel.All)
                _this.IsMultiUnit = true;
            _this.AllowCustomValue = utilities_1.Utilities.isValueTruthy(_this.AllowCustomValue);
            //Min Cust Value
            if (_this.PrimaryMinSellingUnit <= 0)
                _this.PrimaryMinSellingUnit = null;
            if (_this.SecondaryMinSellingUnit <= 0)
                _this.SecondaryMinSellingUnit = null;
            // Set DisplayPrice & DisplayUnitConversion
            _this.setDisplayPriceAndUnitConversion();
            _this.IsActive = utilities_1.Utilities.isValueTruthy(_this.IsActive);
            _this.IsDeleted = utilities_1.Utilities.isValueTruthy(_this.IsDeleted);
        }
        return _this;
    }
    Object.defineProperty(ItemVarietyModel.prototype, "PrimaryUnitName", {
        get: function () {
            var unitName = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode);
            if (this.AllowCustomValue && this.PrimaryMinSellingUnit > 0)
                return "".concat(utilities_1.Utilities.parseNumber(this.PrimaryMinSellingUnit), " ").concat(unitName);
            else
                return unitName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemVarietyModel.prototype, "SecondaryUnitName", {
        get: function () {
            var unitName = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode);
            if (this.AllowCustomValue && this.SecondaryMinSellingUnit > 0)
                return "".concat(utilities_1.Utilities.parseNumber(this.SecondaryMinSellingUnit), " ").concat(unitName);
            else
                return unitName;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemVarietyModel.prototype, "SellingUnitName", {
        get: function () {
            if (this.SellingUnit) {
                if (this.SellingUnit == enum_1.UnitLevel.PrimaryUnit)
                    return this.PrimaryUnitName;
                else if (this.SellingUnit == enum_1.UnitLevel.SecondaryUnit)
                    return this.SecondaryUnitName;
                else if (this.SellingUnit == enum_1.UnitLevel.All)
                    return "Both ".concat(this.PrimaryUnitName, " and ").concat(this.SecondaryUnitName);
            }
            return null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemVarietyModel.prototype, "IsLowOnStock", {
        get: function () {
            return this.AvailablilityType == enum_1.AvailabilityType.Stocked && Number(this.AvailableQty) <= Number(this.LowStockThreshold);
        },
        enumerable: false,
        configurable: true
    });
    ItemVarietyModel.prototype.getUnitType = function (level) {
        if (!level)
            return null;
        if (level == enum_1.UnitLevel.PrimaryUnit)
            return this.PrimaryUnitCode;
        if (level == enum_1.UnitLevel.SecondaryUnit)
            return this.SecondaryUnitCode;
    };
    ItemVarietyModel.prototype.getDefaultOrderCount = function (level) {
        if (this.AllowCustomValue) {
            if (level == enum_1.UnitLevel.PrimaryUnit && this.PrimaryMinSellingUnit)
                return this.PrimaryMinSellingUnit;
            if (level == enum_1.UnitLevel.SecondaryUnit && this.SecondaryMinSellingUnit)
                return this.SecondaryMinSellingUnit;
        }
        return 1;
    };
    ItemVarietyModel.prototype.getItemAvailabilityStatus = function (availableCount) {
        var status;
        if (availableCount > 5) {
            status = enum_1.ItemAvailabilityStatus.AVAILABLE;
        }
        else if (availableCount > 0) {
            status = enum_1.ItemAvailabilityStatus.ONLY_FEW_ITEMS_LEFT;
        }
        else {
            status = enum_1.ItemAvailabilityStatus.OUT_OF_STOCK;
        }
        return status;
    };
    ItemVarietyModel.prototype.getPriceByProfile = function (profileID, unitLevel) {
        var _a;
        var priceProfile = (_a = this.PriceProfiles.find(function (x) { return x.PartyProfileID == profileID; })) !== null && _a !== void 0 ? _a : this.PriceProfiles[0];
        if (unitLevel == enum_1.UnitLevel.SecondaryUnit)
            return priceProfile.SecondaryPrice;
        else // Default is Primary Price  
            return priceProfile.PrimaryPrice;
    };
    ItemVarietyModel.prototype.getAvailableQtyByUnit = function (forUnit) {
        var availableCount = null;
        // Both are same then available count is also same.
        if (forUnit == this.AvailabilityUnit)
            availableCount = this.AvailableQty;
        // Stock is in Primary but we need for in Secondary
        else if (this.AvailabilityUnit == enum_1.UnitLevel.PrimaryUnit && forUnit == enum_1.UnitLevel.SecondaryUnit) {
            availableCount = this.AvailableQty * this.UnitConversionValue;
        }
        // Stock is in Secondary but we need for in Primary
        else if (this.AvailabilityUnit == enum_1.UnitLevel.SecondaryUnit && forUnit == enum_1.UnitLevel.PrimaryUnit) {
            availableCount = this.AvailableQty / this.UnitConversionValue;
        }
        return utilities_1.Utilities.parseNumber(availableCount, 3);
    };
    ItemVarietyModel.prototype.setPriceProfileID = function (profileID) {
        this.SelectedPriceProfileID = profileID;
        this.setPrimaryAndSecondaryPrice();
    };
    ItemVarietyModel.prototype.getPurchasePrice = function (buyingUnitLevel) {
        // If none or primary then purchase price
        if (!buyingUnitLevel || buyingUnitLevel == enum_1.UnitLevel.PrimaryUnit)
            return this.PurchasePrice || this.PrimaryPrice;
        if (buyingUnitLevel == enum_1.UnitLevel.SecondaryUnit) {
            return (this.PurchasePrice || this.PrimaryPrice) / this.UnitConversionValue;
        }
        return null;
    };
    ItemVarietyModel.prototype.getPrice = function (buyingUnitLevel) {
        // If none or primary then purchase price
        return buyingUnitLevel && buyingUnitLevel == enum_1.UnitLevel.SecondaryUnit ?
            this.SecondaryPrice : this.PrimaryPrice;
    };
    /***** PRIVATE METHODS *****/
    ItemVarietyModel.prototype.setDisplayPriceAndUnitConversion = function () {
        if (!this.SellingUnit || this.SellingUnit == enum_1.UnitLevel.PrimaryUnit) {
            this.DisplayPrice = "".concat(utilities_1.Utilities.parseTo2DecPlaces(this.PrimaryPrice));
            if (this.PrimaryUnitCode)
                this.DisplayPrice += " per ".concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode));
        }
        else if (this.SellingUnit == enum_1.UnitLevel.All) {
            this.DisplayPrice = "".concat(utilities_1.Utilities.parseTo2DecPlaces(this.PrimaryPrice), "  per ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode), " / ").concat(utilities_1.Utilities.parseTo2DecPlaces(this.SecondaryPrice), " per ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode));
            this.DisplayUnitConversion = "1 ".concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode), " = ").concat(this.UnitConversionValue, " ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode));
        }
        else if (this.SellingUnit == enum_1.UnitLevel.SecondaryUnit) {
            this.DisplayPrice = "".concat(utilities_1.Utilities.parseTo2DecPlaces(this.SecondaryPrice), " per ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode), " ");
            this.DisplayUnitConversion = "1 ".concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode), " = ").concat(this.UnitConversionValue, " ").concat(utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode));
        }
    };
    ItemVarietyModel.prototype.setPrimaryAndSecondaryPrice = function () {
        var _this = this;
        var _a;
        if (!this.PriceProfiles || this.PriceProfiles.length == 0) {
            this.loadPriceProfilePrices(null);
            return;
        }
        var profile = (_a = this.PriceProfiles.find(function (x) { return x.PartyProfileID == _this.SelectedPriceProfileID; })) !== null && _a !== void 0 ? _a : this.PriceProfiles[0];
        this.loadPriceProfilePrices(profile);
        return;
    };
    ItemVarietyModel.prototype.loadPriceProfilePrices = function (priceProfile) {
        if (priceProfile) {
            this.SelectedPriceProfileID = priceProfile.PartyProfileID;
            this.PrimaryPrice = priceProfile.PrimaryPrice;
            this.SecondaryPrice = priceProfile.SecondaryPrice;
        }
        else {
            this.SelectedPriceProfileID = null;
            this.PrimaryPrice = null;
            this.SecondaryPrice = null;
        }
        this.setDisplayPriceAndUnitConversion();
    };
    return ItemVarietyModel;
}(item_variety_base_model_1.ItemVarietyBaseModel));
exports.ItemVarietyModel = ItemVarietyModel;
