<div id="appBody">
  <ng-container [ngSwitch]="layout">
    <!-- Web Layout -->
    <ng-container *ngSwitchCase="'web'">
      <div id="web-layout-content">
        <!-- Side menu Bar -->
        <div id="side-menu-container">
          <app-side-menu-bar></app-side-menu-bar>
        </div>

        <div id="workarea-container">
          <div id="top-bar-container">
            <!-- Top Bar -->
            <app-top-bar></app-top-bar>
          </div>

          <!-- Body -->
          <div id="body-content">
            <ng-container *ngTemplateOutlet="routeroutlet">
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Mobile Layout -->
    <ng-container *ngSwitchCase="'mobile'">
      <div id="mobile-layout-content">
        <div id="top-bar-container">
          <!-- Top Bar -->
          <app-top-bar></app-top-bar>
        </div>

        <!-- Body -->
        <div id="body-content">
          <ng-container *ngTemplateOutlet="routeroutlet">
          </ng-container>
        </div>

        <div id="bottom-bar-container">
          <!-- Bottom Bar -->
          <app-bottom-menu-bar></app-bottom-menu-bar>
        </div>
      </div>
    </ng-container>

    <!-- Login Layout -->
    <ng-container *ngSwitchCase="'login'">
      <!-- Body -->
      <div id="body-content">
        <ng-container *ngTemplateOutlet="routeroutlet">
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <!-- Templates -->
  <ng-template #routeroutlet>
    <router-outlet></router-outlet>
  </ng-template>


  <!-- Others -->
  <!-- Alert Dialog Component -->
  <alert></alert>

  <!-- confirm dialog modal -->
  <ng-container *ngIf="showConfirmDialogModal">
    <app-popup-modal [popUpDialogDetails]="popUpDialogDetails" [showModal]="showConfirmDialogModal"
      (proceedClickEvent)="confirmDialogResponse(true)" (cancelClickEvent)="confirmDialogResponse(false)"
      (modalCloseEvent)="confirmDialogClose()">
      <span [innerHtml]="popUpDialogDetails.message"></span>
    </app-popup-modal>
  </ng-container>

  <!-- Spinner -->
  <div id="modalSpinner" *ngIf="showLoader">
    <img [src]="loaderImgPath" class="img-xm" />
  </div>
</div>