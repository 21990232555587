import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertType, Utilities } from 'eccommons';
import { AdminUtilities } from 'src/app/shared/admin_utilities';
import { AccountService } from '../service/account.service';
import { AlertService } from 'src/app/shared/services/alert.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';

@Component({
  selector: 'app-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.scss']
})
export class PasswordSetComponent implements OnInit {
  businessUpLogoPath: string;
  Bid: string;
  passwordSetForm: UntypedFormGroup;
  isEnabledOtp: boolean = false;
  passwordMatch: boolean = true;
  disableBtn: boolean = false;

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private accountService: AccountService, private alertService: AlertService, private sharedService: SharedService, private appSettings: AppSettings,) {
    this.businessUpLogoPath = this.appSettings.businessUpLogoImgPath;
  }

  ngOnInit(): void {
    this.passwordSetForm = this.fb.group({
      bid: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(7)]),
        { errLable: 'Business ID' }),
      phone: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(10)]),
        { errLable: 'Phone Number' }),
      pin: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(4)]),
        { errLable: 'OTP' }),
      password: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required, Validators.pattern("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"), Validators.minLength(8)]), { errLable: 'Password' }),
      confirmPassword: AdminUtilities.newFormControl(new UntypedFormControl('', [Validators.required]))
    });
    this.route.queryParams.subscribe(
      params => {
        this.Bid = params['bid'];
        this.passwordSetForm.patchValue({
          bid: this.Bid
        })
      });
  }

  onSubmit() {
    const bid = this.passwordSetForm.get('bid').value;
    const phone = this.passwordSetForm.get('phone').value;
    const pin = this.passwordSetForm.get('pin').value;
    const password = this.passwordSetForm.get('password').value;
    this.appSettings.BID = bid;
    this.checkPassword();
    if (this.passwordSetForm.valid && this.passwordMatch) {
      this.accountService.setPassword(bid, phone, pin, password).subscribe({
        next: (data: any) => {
          if (data != null) {
            if (data.Status == "1") {
              this.sharedService.notify(AlertType.Success, "Your password has been set successfully");
              this.router.navigate(['/login']);
            }
          } else {
            this.sharedService.notify(AlertType.Error, "Invalid OTP");
          }

        },
        error: err => {
          console.log(err);
        }
      })
    }
  }

  sendOTP() {
    if (this.passwordSetForm.get('phone').valid) {
      this.accountService.sendOTP(this.passwordSetForm.get('phone').value).subscribe({
        next: res => {
          if (res && Number(res) > 0) {
            this.disableBtn = true;
            setTimeout(() => {
              this.disableBtn = false;
              this.isEnabledOtp = true;
            }, 20000);
            this.alertService.info('Your 4 digit password is sent to your Mobile No.');
          }
        },
        error: err => {
          this.sharedService.notify(AlertType.Error, 'Oops Something Went Wrong!');
        }
      })
    } else {
      this.sharedService.notify(AlertType.Warning, 'Enter your phone number!');
    };
  }

  checkPassword() {
    let password = this.passwordSetForm.get('password').value;
    let confirm_password = this.passwordSetForm.get('confirmPassword').value;
    return password === confirm_password ? this.passwordMatch = true : this.passwordMatch = false;
  }
}
