"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardCountModel = void 0;
var DashboardCountModel = /** @class */ (function () {
    function DashboardCountModel(value) {
        Object.assign(this, value);
    }
    return DashboardCountModel;
}());
exports.DashboardCountModel = DashboardCountModel;
