"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropLocationModel = void 0;
var utils_1 = require("../../utils");
var DropLocationModel = /** @class */ (function () {
    function DropLocationModel(value) {
        Object.assign(this, value);
        if (value) {
            this.City = utils_1.Utilities.capitalizeFirstWord(this.City);
            this.Area = utils_1.Utilities.capitalizeFirstWord(this.Area);
            this.IsActive = utils_1.Utilities.parseBoolean(value.IsActive);
        }
    }
    Object.defineProperty(DropLocationModel.prototype, "locationName", {
        get: function () {
            if (this.Area)
                return "".concat(this.City, ", ").concat(this.Area);
            else
                return this.City;
        },
        enumerable: false,
        configurable: true
    });
    return DropLocationModel;
}());
exports.DropLocationModel = DropLocationModel;
