"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyModel = void 0;
var utilities_1 = require("../../utils/utilities");
var enum_1 = require("../../enum");
var PartyModel = /** @class */ (function () {
    function PartyModel(value) {
        if (value)
            Object.assign(this, value);
        this.WalletBalance = utilities_1.Utilities.parseNumber(this.WalletBalance);
        this.OrderBalance = utilities_1.Utilities.parseNumber(this.OrderBalance);
        this.Balance = this.WalletBalance + this.OrderBalance;
        this.IsActive = utilities_1.Utilities.parseBoolean(this.IsActive);
        // Wallet Balance
        if (this.WalletBalance > 0)
            this.WalletBalanceTypeID = enum_1.PaymentActionType.TO_PAY;
        else if (this.WalletBalance < 0) {
            //this.WalletBalance = (-1 * this.WalletBalance);
            this.WalletBalanceTypeID = enum_1.PaymentActionType.TO_RECEIVE;
        }
        else
            this.WalletBalanceTypeID = null;
        // Order Balance
        if (this.OrderBalance > 0) {
            this.OrderBalanceTypeID = enum_1.PaymentActionType.TO_PAY;
        }
        else if (this.OrderBalance < 0) {
            this.OrderBalanceTypeID = enum_1.PaymentActionType.TO_RECEIVE;
        }
        else
            this.OrderBalanceTypeID = null;
        // OverallBalance
        if (this.Balance > 0)
            this.BalanceTypeID = enum_1.PaymentActionType.TO_PAY;
        else if (this.Balance < 0) {
            //this.Balance = (-1 * this.Balance);
            this.BalanceTypeID = enum_1.PaymentActionType.TO_RECEIVE;
        }
        else {
            this.Balance = null;
            this.BalanceTypeID = null;
        }
    }
    Object.defineProperty(PartyModel.prototype, "HasAddress", {
        get: function () {
            return this.Address && this.Address.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PartyModel.prototype, "IsIndividual", {
        get: function () {
            return this.PartyType == enum_1.PartyType.INDIVIDUAL;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PartyModel.prototype, "IsBusiness", {
        get: function () {
            return this.PartyType == enum_1.PartyType.BUSINESS;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PartyModel.prototype, "WalletBalanceToReceive", {
        get: function () {
            if (this.WalletBalance && this.WalletBalanceTypeID == enum_1.PaymentActionType.TO_RECEIVE) {
                return Math.abs(this.WalletBalance);
            }
            return 0;
        },
        enumerable: false,
        configurable: true
    });
    PartyModel.prototype.addAddress = function (addEditAddress) {
        if (this.Address) {
            var index = this.Address.findIndex(function (adr) { return adr.AddressID == addEditAddress.AddressID; });
            if (index > -1)
                this.Address[index] = addEditAddress;
            else
                this.Address.push(addEditAddress);
        }
        else {
            this.Address = new Array();
            this.Address.push(addEditAddress);
        }
    };
    return PartyModel;
}());
exports.PartyModel = PartyModel;
