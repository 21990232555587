<div id="top-bar-content">
  <div class="flxRowNwSB align-center w-100">
    <div>
      <span class="page-title">
        <h4>{{title}}</h4>
      </span>
    </div>

    <div class="flxRowNwSB align-center">
      <i class="icon-Notification icon-m action-icon"></i>

      <div class="profile">
        <div class="avatar m-2 mr-3 cursor-pointer" (click)="showProfileMenu = !showProfileMenu;">
          <span>{{user.ProfileUITitle}}</span>
        </div>

        <div *ngIf="showProfileMenu" class="menu box-shadow flxColNwAlignCenter" [ngClass]="{'active': showProfileMenu}" clickedOutside
          (clickedOutsideEvent)="closeProfileMenu()" [@fadeInOut]>
          <!-- Profile Info -->
          <div class="flxRowAlignCenter">
            <div class="avatar avatar-l m-2 mr-3">
              <span>{{user.ProfileUITitle}}</span>
            </div>
            <div>
              <h3>{{user.Name}}<br /><span>{{user.Phone}}</span></h3>
            </div>
          </div>
          <!-- Profile Menu -->
          <div>
            <ul>
              <li class="nav-link">
                <i class="fa-solid fa-language"></i> <span class="side-menu-title">Language</span>
              </li>

              <li class="nav-link" (click)="logout()">
                <i class="fa fa-sign-out" aria-hidden="true"></i>
                <span class="side-menu-title">Logout</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>