"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoneyFlowType = void 0;
var MoneyFlowType;
(function (MoneyFlowType) {
    MoneyFlowType["MONEY_IN"] = "MONEY_IN";
    MoneyFlowType["MONEY_OUT"] = "MONEY_OUT";
    MoneyFlowType["ROUTE_IN"] = "ROUTE_IN";
    MoneyFlowType["ROUTE_OUT"] = "ROUTE_OUT";
    MoneyFlowType["WALLET_IN"] = "WALLET_IN";
    MoneyFlowType["WALLET_OUT"] = "WALLET_OUT";
    MoneyFlowType["INIT_PARTY"] = "INIT_PARTY";
    MoneyFlowType["INIT_ACCOUNT"] = "INIT_ACCOUNT";
    MoneyFlowType["INIT_CASH"] = "INIT_CASH";
})(MoneyFlowType = exports.MoneyFlowType || (exports.MoneyFlowType = {}));
