import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwitchComponent
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor, OnInit {
  @Input() lable: string = 'Is Active';  
  @Input() readonly: boolean = false;

  @Output() onToggledEvent = new EventEmitter<boolean>();

  on: boolean = false;
  touched = false;
  disabled = false;
  stateLable: string = 'Yes';


  onChange = (quantity) => { };

  onTouched = () => { };

  /**** Constructor *****/
  constructor() { }

  /**** On Init *****/
  ngOnInit(): void {        
  }

  /**** Event ****/
  onCheckBoxChange() {
    this.onChange(this.on);
    this.setStateLable();
    this.onToggledEvent.emit(this.on);
  }

  /****  Form Control Related *****/
  writeValue(formValue: boolean) {
    this.on = formValue;
    this.setStateLable();
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  /**** Private  Methods ****/
  private setStateLable() {
    if (this.on)
      this.stateLable = 'Yes';
    else
      this.stateLable = 'No';
  }
}
