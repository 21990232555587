import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileModel } from 'eccommons';
import { take } from 'rxjs/internal/operators/take';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-ec-upload-file',
  templateUrl: './ec-upload-file.component.html'
})
export class EcUploadFileComponent {

  @Input() allowedExtensions: string;
  @Input() multiple: boolean = false;
  @Input() public set previewFiles(value: Array<FileModel>) {
    if (value) {
      this._previewFiles = value;
      this.selectedFiles = [];
    }
  }
  @Input() readOnly: boolean = false;
  @Input() buttonName: string = 'Upload';
  @Input() uploadType: string = 'image';
  @Input() defaultDeleteEnabled: boolean = true;
  @Input() confirmBeforeDelete: boolean = true;
  @Input() isOpenFileInNewTabOnClick: boolean = false;

  @Output() files = new EventEmitter<Array<File>>();
  @Output() fileRemoved = new EventEmitter<number>();
  @Output() fileSelectFailed = new EventEmitter<string>();
  @Output() fileLoadFailed = new EventEmitter<number>();
  @Output() fileClicked = new EventEmitter<FileModel>();

  public selectedFiles = new Array<File>();

  public get allowedExtensionList(): Array<string> {
    if (this.allowedExtensions) {
      return this.allowedExtensions.split(',');
    }
    else {
      return null;
    }
  }

  public get previewFiles(): Array<FileModel> {
    return this._previewFiles;
  }

  private _newFileURL: any;
  private _previewFiles: Array<FileModel> = new Array<FileModel>();

  /* Constructor
  **********************************************/
  constructor(private _domSanitizer: DomSanitizer, private sharedService: SharedService) { }

  get hasAnyFile(): boolean {
    if ((this.selectedFiles && this.selectedFiles.length > 0)
      ||
      (this.previewFiles && this.previewFiles.length > 0)
    ) {
      return true;
    }
    else {
      return false;
    }
  }
  /* Methods
  **********************************************/
  public updateFileChange(fileList: FileList) {
    if (fileList && fileList.length > 0) {
      if (this.validFileType(fileList)) {
        for (let i = 0; i < fileList.length; i++) {
          let uploadedFile = fileList[i];
          Object.defineProperty(uploadedFile, 'previewImgUrl', {
            get() {
              if (this.FileContentURL)
                return this.FileContentURL;
              else
                return this.ImageUri;
            }
          });
          let reader = new FileReader();
          reader.readAsDataURL(uploadedFile);
          reader.onload = (_event) => {
            uploadedFile['FileContentURL'] = this._domSanitizer.bypassSecurityTrustUrl(String(reader.result));
          }
          this.selectedFiles.push(uploadedFile);
        }
        this.files.emit(this.selectedFiles);
      }
      else {
        this.fileSelectFailed.emit(`Only ${this.allowedExtensionList} file extensions are allowed.`);
      }
    }
  }

  public errorLoadingImg(imageID: number) {
    this.fileLoadFailed.emit(imageID);
  }

  public removeNewFile(i: number) {
    this.selectedFiles.splice(i, 1);
    this.files.emit(this.selectedFiles);
  }

  public removeSavedFile(id: number) {
    if (this.confirmBeforeDelete) {
      this.sharedService.showConfirmDialog('Delete File', 'Are you sure want to delete?')
        .pipe(take(1))
        .subscribe(res => {
          if (res) {
            this.deleteFile(id);
          }
        })
    }
    else {
      this.deleteFile(id);
    }
  }

  public validFileType(fileList: FileList) {
    let _isValid = true;

    if (this.allowedExtensionList && this.allowedExtensionList.length > 0) {
      for (let i = 0; i < fileList.length; i++) {
        let _extension = fileList[i].name.slice(fileList[i].name.lastIndexOf('.'));
        if (!_extension || !this.allowedExtensionList.some(ex => ex.trim().toLowerCase() == _extension.toLowerCase())) {
          _isValid = false;
          break;
        }
      }
    }

    return _isValid;
  }

  public onFileClicked(file: FileModel) {
    if (this.isOpenFileInNewTabOnClick) {
      window.open(file.filePath, '_blank');
    }
    this.fileClicked.emit(file);
  }

  public onFileNotFound(index: number) {
    this.previewFiles?.splice(index, 1);
  }

  public getFileName(url: string) {
    return url.substring(url.lastIndexOf('/') + 1);
  }

  private deleteFile(id: number) {
    let file = this.previewFiles.find(pi => pi.fileID == id);
    this.previewFiles = this.previewFiles.filter(pi => pi.fileID != id);
    this.fileRemoved.emit(id);

    // default delete from CDN by path When set to false parent component will handle the delete
    if (this.defaultDeleteEnabled) {
      let filePath = new URL(file.filePath)?.pathname;
      if (filePath) {
        this.sharedService.deleteFile(filePath).subscribe();
      }
    }
  }
}
