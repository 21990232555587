"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStatusEnum = void 0;
var PaymentStatusEnum;
(function (PaymentStatusEnum) {
    PaymentStatusEnum[PaymentStatusEnum["PAYMENT_PENDING"] = 1] = "PAYMENT_PENDING";
    PaymentStatusEnum[PaymentStatusEnum["HOLD"] = 2] = "HOLD";
    PaymentStatusEnum[PaymentStatusEnum["FAILED"] = 3] = "FAILED";
    PaymentStatusEnum[PaymentStatusEnum["VERIFYING"] = 4] = "VERIFYING";
    PaymentStatusEnum[PaymentStatusEnum["PARTIAL"] = 5] = "PARTIAL";
    PaymentStatusEnum[PaymentStatusEnum["REFUNDED"] = 6] = "REFUNDED";
    PaymentStatusEnum[PaymentStatusEnum["SUCCESS"] = 10] = "SUCCESS";
})(PaymentStatusEnum = exports.PaymentStatusEnum || (exports.PaymentStatusEnum = {}));
