/* Angular */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from "@angular/cdk/drag-drop";

/* Modules */
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { DirectivesModule } from '../helpers/directives/directives.module';
import { NgxPrintModule } from 'ngx-print';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule, BsDropdownConfig } from 'ngx-bootstrap/dropdown';

/* Pipe */
import { CDNPathPipe } from './pipe/cdnpath.pipe';
import { CustomCurrencyPipe } from './pipe/custom-currency.pipe';
import { HighlightPipe } from './pipe/highlight.pipe';

/* Components */
import { EcUploadFileComponent } from './components/ec-upload-file/ec-upload-file.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { AddressDetailsComponent } from './components/address-details/address-details.component';
import { QuantityCounterComponent } from './components/quantity-counter/quantity-counter.component';
import { DiscountLableComponent } from './components/discount-lable/discount-lable.component';
import { MsgCenterComponent } from './components/msg-center/msg-center.component';
import { AppTableComponent } from './components/app-table/app-table.component';
import { AlertComponent } from './components/alert/alert.component';
import { DisplayBankAccInfoComponent } from './components/display-bank-acc-info/display-bank-acc-info.component';
import { DropLocationsComponent } from './components/drop-locations/drop-locations.component';
import { FormErrorMsgComponent } from './components/form-error-msg/form-error-msg.component';
import { PopupModalComponent } from './components/popup-modal/popup-modal.component';
import { MultiTextInputComponent } from './components/multi-text-input/multi-text-input.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { SaveAddressComponent } from './components/save-address/save-address.component';
import { DisplayPaymentActionComponent } from './components/display-payment-action/display-payment-action.component';
import { OrderStatusIconComponent } from './components/order-status-icon/order-status-icon.component';
import { PaymentStatusIconComponent } from './components/payment-status-icon/payment-status-icon.component';
import { DisplayPartyComponent } from './components/display-party/display-party.component';
import { PrintBillComponent } from './components/print-bill/print-bill.component';
import { DisplayAddressComponent } from './components/display-address/display-address.component';
import { SaveAdditionalOrderItemComponent } from './components/save-additional-order-item/save-additional-order-item.component';
import { VarietyStockComponent } from './components/variety-stock/variety-stock.component';
import { MultiStockLocationComponent } from './components/multi-stock-location/multi-stock-location.component';
import { OrderNotesComponent } from './components/order-notes/order-notes.component';
import { DisplayOrderListComponent } from './components/display-order-list/display-order-list.component';
import { FieldsetComponent } from './components/fieldset/fieldset.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { DisplayOrderInfoComponent } from './components/display-order-info/display-order-info.component';
import { DisplayTransactionComponent } from './components/display-transaction/display-transaction.component';
import { DisplayInOutDiffComponent } from './components/display-in-out-diff/display-in-out-diff.component';
import { OptionsMenuComponent } from './components/options-menu/options-menu.component';
import { DisplayFormWarningsComponent } from './components/display-form-warnings/display-form-warnings.component';
import { SwitchComponent } from './components/switch/switch.component';
import { DisplayPartyTransactionsComponent } from './components/display-party-transactions/display-party-transactions.component';
import { OrderFiltersComponent } from './components/order-filters/order-filters.component';
import { SearchPartyComponent } from "./components/search-party/search-party.component";
import { SavePartyComponent } from "./components/save-party/save-party.component";
import { TabsComponent } from './components/tabs/tabs.component';
import { TabComponent } from './components/tabs/tab/tab.component';
import { DisplayItemComponent } from '../admin/item/display-item/display-item.component';
import { DisplayDataListComponent } from './components/display-data-list/display-data-list.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';

@NgModule({
    declarations: [
        /* Components */
        EcUploadFileComponent,
        OrderDetailsComponent,
        AddressDetailsComponent,
        QuantityCounterComponent,
        DiscountLableComponent,
        MsgCenterComponent,
        AppTableComponent,
        AlertComponent,
        DisplayBankAccInfoComponent,
        DropLocationsComponent,
        PopupModalComponent,
        DropLocationsComponent,
        FormErrorMsgComponent,
        MultiTextInputComponent,
        PopupModalComponent,
        HelpTextComponent,
        SaveAddressComponent,
        DisplayPaymentActionComponent,
        OrderStatusIconComponent,
        PaymentStatusIconComponent,
        DisplayPartyComponent,
        PrintBillComponent,
        DisplayAddressComponent,
        SaveAdditionalOrderItemComponent,
        VarietyStockComponent,
        MultiStockLocationComponent,
        OrderNotesComponent,
        DisplayOrderListComponent,
        FieldsetComponent,
        MultiSelectComponent,
        DisplayOrderInfoComponent,
        DisplayTransactionComponent,
        DisplayInOutDiffComponent,
        OptionsMenuComponent,
        DisplayFormWarningsComponent,
        SwitchComponent,
        DisplayPartyTransactionsComponent,
        OrderFiltersComponent,
        SearchPartyComponent,
        SavePartyComponent,
        TabsComponent,
        TabComponent,
        DisplayDataListComponent,
        PaginatorComponent,
        SearchBarComponent,

        /* Pipe */
        CDNPathPipe,
        CustomCurrencyPipe,
        HighlightPipe,


    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        DragDropModule,
        DirectivesModule,
        TooltipModule.forRoot(),
        NgxPrintModule,
        KeyboardShortcutsModule.forRoot(),
        BsDatepickerModule.forRoot(),
        BsDropdownModule
    ],
    exports: [
        /* Components */
        EcUploadFileComponent,
        OrderDetailsComponent,
        AddressDetailsComponent,
        QuantityCounterComponent,
        DiscountLableComponent,
        MsgCenterComponent,
        AppTableComponent,
        AlertComponent,
        DisplayBankAccInfoComponent,
        DropLocationsComponent,
        PopupModalComponent,
        FormErrorMsgComponent,
        MultiTextInputComponent,
        PopupModalComponent,
        HelpTextComponent,
        SaveAddressComponent,
        DisplayPaymentActionComponent,
        OrderStatusIconComponent,
        PaymentStatusIconComponent,
        DisplayPartyComponent,
        PrintBillComponent,
        DisplayAddressComponent,
        SaveAdditionalOrderItemComponent,
        VarietyStockComponent,
        MultiStockLocationComponent,
        OrderNotesComponent,
        DisplayOrderListComponent,
        FieldsetComponent,
        MultiSelectComponent,
        DisplayOrderInfoComponent,
        DisplayTransactionComponent,
        DisplayInOutDiffComponent,
        OptionsMenuComponent,
        DisplayFormWarningsComponent,
        SwitchComponent,
        DisplayPartyTransactionsComponent,
        OrderFiltersComponent,
        SearchPartyComponent,
        SavePartyComponent,
        TabsComponent,
        TabComponent,
        DisplayDataListComponent,
        PaginatorComponent,
        SearchBarComponent,

        /* Pipe */
        CDNPathPipe,
        CustomCurrencyPipe,
        HighlightPipe,
    ],
    providers: [
        CDNPathPipe,
        CustomCurrencyPipe,
        BsDropdownConfig
    ]
})
export class SharedModule { }