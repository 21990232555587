/*** Core ****/
import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

/*** Services ***/
import { AppSettings } from 'src/app/shared/services/appSettings.services';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SideMenuBarComponent implements OnInit {
  bupLogoImgPath: string;
  bussinessLogoImgPath: string;
  showFinanceSubMenu: boolean = false;
  showSettingsSubMenu: boolean = false;
  showReportsSubMenu: boolean = false;

  /*** Constructor */
  constructor(private appSettings: AppSettings, private router: Router) {
    this.bupLogoImgPath = this.appSettings.businessUpLogoImgPath;
    this.bussinessLogoImgPath = this.appSettings.businessLogoImgPath;
  }

  ngOnInit(): void {
  }


  /*** Events ****/
  routeChange(event, route: string) {
    event?.stopPropagation();
    this.router.navigate([route]);
  }

}
