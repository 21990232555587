import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CategoryModel, AlertType, FileModel, SelectOptionModel, Utilities } from 'eccommons'
import { AdminService } from '../services/admin.service';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { map, take } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { SharedService } from 'src/app/shared/services/shared.service';
import { CDNPathPipe } from 'src/app/shared/pipe/cdnpath.pipe';
import { AdminUtilities } from 'src/app/shared/admin_utilities';
@Component({
  selector: 'app-save-category',
  templateUrl: './save-category.component.html'
})
export class SaveCategoryComponent implements OnInit {

  public categoryModel: CategoryModel;
  public _categorydetails: CategoryModel;
  public parentCategory: string[];
  public createMessage: string;
  public files: any = [];
  public categoryList: any = [];
  public frmSaveCategory: UntypedFormGroup;
  previewImages: Array<FileModel>;
  public categoryImageFile: File;
  categoryIdParam: number;
  public allowedExtensions: string = ".png, .jpg, .jpeg";
  categoryOptions: Array<SelectOptionModel>;

  constructor(
    private adminServiceService: AdminService,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private _router: Router,
    private cdnPathPipe: CDNPathPipe
  ) { }

  get f() { return this.frmSaveCategory.controls; }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      let categoryID = Number(params["categoryID"]);
      if (categoryID) {
        this.adminServiceService.getCategoryById(categoryID).subscribe(data => {
          this.categoryModel = new CategoryModel(data[0][0]);

          this.initializeForm(this.categoryModel);
          this.frmSaveCategory.controls.ParentID.patchValue(this.categoryModel.ParentID);
        });
      }
      else {
        this.categoryModel = new CategoryModel();
        this.categoryModel.IsActive = true;
        this.initializeForm(this.categoryModel);
      }


    });

    this.adminServiceService.getCategory().subscribe(
      data => {
        this.categoryOptions = new Array<SelectOptionModel>();
        this.categoryOptions = data.map(category => {
          return new SelectOptionModel({
            lable: category.Name,
            value: category.CategoryID
          })
        });
        this.categoryOptions.unshift(new SelectOptionModel({ lable: 'None', value: -1 }));
      });


  };
  initializeForm(categoryModel: CategoryModel) {

    this.frmSaveCategory = this.formBuilder.group(
      {
        CategoryID: new UntypedFormControl(categoryModel.CategoryID),
        Name: AdminUtilities.newFormControl(new UntypedFormControl(categoryModel.Name, [Validators.required, Validators.maxLength(32)]),
          { errLable: 'Category Name' }),
        ParentID: new UntypedFormControl(categoryModel.ParentID),
        Description: new UntypedFormControl(categoryModel.Description, Validators.maxLength(120)),
        ImagePath: new UntypedFormControl(this.categoryModel.ImagePath),
        IsActive: new UntypedFormControl(Boolean(Number(categoryModel.IsActive))),
        DisplayOrder: new UntypedFormControl(categoryModel.DisplayOrder, [Utilities.onlyPositiveIntegerValidationFn()])
      });


    if (this.categoryModel.CategoryID && this.categoryModel.ImagePath) {
      this.previewImages = [new FileModel({
        fileID: this.categoryModel.CategoryID,
        filePath: this.cdnPathPipe.transform(this.categoryModel.ImagePath)
      })
      ];
    }
  }

  saveCategory() {
    this._categorydetails = this.frmSaveCategory.value;
    this.adminServiceService.saveCategoryDetail(this._categorydetails, this.categoryImageFile).subscribe(res => {
      // if (res && res[0]['SavedCategoryID'] > 0) {
      this.sharedService.notify(AlertType.Success, 'Saved Successfully!');
      this._router.navigate(['/categorydetails']);
      //}
    });
  };
  //To Track File Change
  updateFileChange(selectedFiles: FileList) {
    let _file: File = selectedFiles[0];
    this.categoryImageFile = _file;
  }

  onFileSelectFailed(msg) {
    this.sharedService.notify(AlertType.Warning, msg);
  }
  onFilesSelected(files: Array<File>) {
    this.categoryImageFile = files[0];
  }

  onFileRemove(id: number) {
  }

  cancelSave(confirmWithUser: boolean = true) {
    let shallProceed = of(true);
    if (confirmWithUser) {
      shallProceed = this.sharedService.showConfirmDialog('Cancel Category', 'Are you sure want to cancel the category?')
        .pipe(take(1));
    }
    shallProceed.subscribe(res => {
      if (res) {
        window.history.back();
      }
    });
  }
}
