"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResponseModel = void 0;
var ResponseModel = /** @class */ (function () {
    function ResponseModel(value) {
        var _a, _b, _c;
        if (value) {
            this.Data = (_a = value['Data']) !== null && _a !== void 0 ? _a : value['data'];
            this.StatusCode = (_b = value['StatusCode']) !== null && _b !== void 0 ? _b : value['statusCode'];
            this.Message = (_c = value['Message']) !== null && _c !== void 0 ? _c : value['message'];
        }
    }
    Object.defineProperty(ResponseModel.prototype, "IsSuccess", {
        get: function () {
            return this.StatusCode >= 200 && this.StatusCode < 300;
        },
        enumerable: false,
        configurable: true
    });
    return ResponseModel;
}());
exports.ResponseModel = ResponseModel;
