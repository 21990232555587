import { ElementRef, HostListener } from "@angular/core";
import { Directive, EventEmitter, Output } from "@angular/core";

@Directive({
  selector: '[clickedOutside]'
})
export class ClickedOutsideDirective {
  @Output() clickedOutsideEvent = new EventEmitter<void>();

  private isInitial: boolean = true;
  constructor(private elementRef: ElementRef) { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {    
    const clickedInside = this.elementRef.nativeElement.contains(target);
    if (!clickedInside) {
      if (!this.isInitial)
        this.clickedOutsideEvent.emit();
      this.isInitial = false;
    }
  }
}
