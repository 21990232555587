import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  AlertType,
  BannerModel, TableActionModel, TableActionType, TableColumnModel,
  TableColumnType, TableConfigModel
} from 'eccommons';

import { BannerService } from './service/banner.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {

  tableConfig: TableConfigModel;
  rowData: Array<BannerModel>;

  constructor(private bannerService: BannerService, private router: Router, private sharedService: SharedService) {
    this.tableConfig = new TableConfigModel({
      Headers: [
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Display Order',
          FieldName: 'DisplayOrder'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Name',
          FieldName: 'Name'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Link To',
          FieldName: 'LinkType'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Link Option',
          FieldName: 'LinkOptionName'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.BOOLEAN,
          ColumnName: 'Is Active',
          FieldName: 'IsActive'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.ACTION,
          ColumnName: 'Action',
          ActionOption: new TableActionModel({
            ActionType: TableActionType.ICON_EDIT,
            Lable: 'Edit',
          })
        })
      ],
      ShowRowNo: false,
      EnableRowSelection: false,
      AllowReOrdering: true
    });
  }

  ngOnInit(): void {
    this.loadBannerList();
  }

  loadBannerList() {
    this.bannerService.getAllBannerDetails().subscribe(res => {
      this.rowData = res;
    });
  }

  onActionClick(action: TableActionModel) {
    if (action && action.RowData) {
      const rowData = action.RowData as BannerModel;
      this.bannerService.bannerToEdit = rowData;
      this.router.navigate(['/saveBanner', rowData.BannerID]);
    }
  }

  onRowSorted(sortedKeys: Array<number>) {
    this.bannerService.saveBannerDisplayOrder(sortedKeys).subscribe(res => {
      if (res) {
        this.sharedService.notify(AlertType.Success, 'Display Order Saved Successfully!');
        this.loadBannerList();
      }
    })
  }
}
