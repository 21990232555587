<!-- Bottom Bar -->
<div id="bottom-bar-content">
  <div class="flxRowNwSB align-center w-100">
    <!-- Dashboard -->
    <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="dashboard" [routerLinkActive]="'is-active'">
      <i class="icon-Dashboard" alt="Dashboard"></i>
      <span class="icon-text">Dashboard</span>
    </div>
    <!-- Order -->
    <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="order/orderlist"
      [routerLinkActive]="'is-active'">
      <i class="icon-Orders" alt="order"></i>
      <span class="icon-text">Order</span>
    </div>
    <!-- Plus -->
    <div class="icon-menu" (click)="closeMoreMenuDrawer()" (click)="showAddMenuDrawer = true">
      <i class="fas fa-bolt icon-l radiating-gradient" alt="quick action"></i>
    </div>
    <!-- Parties -->
    <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="party" [routerLinkActive]="'is-active'">
      <i class="icon-Parties" alt="Parties"></i>
      <span class="icon-text">Parties</span>
    </div>
    <!-- More -->
    <div class="icon-menu" (click)="closeMoreMenuDrawer()" (click)="showMoreMenuDrawer = true">
      <i class="icon-Menu" alt="more"></i>
      <span class="icon-text">More</span>
    </div>
  </div>
</div>

<!-- Menu Drawer -->
<!-- Add Menu Drawer -->
<div class="bottom-drawer" *ngIf="showAddMenuDrawer" [@fadeInOut]>

  <!-- Row 1 -->
  <div class="drawer-row flxRowSB mb-2">
    <div class="flxRowNwSB p-2 w-100 align-center">
      <!-- Pay -->
      <div>
        <div class="icon-menu" (click)="closeAddMenuDrawer()" routerLink="finance/moneyout"
          [routerLinkActive]="'is-active'">
          <i class="fas fa-location-arrow icon negativeIcon-sm"></i>
          <span class="icon-text">Pay</span>
        </div>
      </div>

      <!-- New Order -->
      <div class="icon-menu" (click)="newOrder()">
        <i class="fa fa-plus-square icon" aria-hidden="true"></i>
        <span class="icon-text">New Order</span>
      </div>

      <!-- Receive -->
      <div>
        <div class="icon-menu" routerLink="finance/moneyin" (click)="closeAddMenuDrawer()"
          [routerLinkActive]="'is-active'">
          <i class="fas fa-location-arrow icon fa-rotate-180 positiveIcon-sm"></i>
          <span class="icon-text">Receive</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Close -->
  <div class="text-center pt-4">
    <i class="far fa-times-circle icon-l close-icon" (click)="closeAddMenuDrawer()"></i>
  </div>
</div>

<!-- More Menu Drawer -->
<div class="bottom-drawer" *ngIf="showMoreMenuDrawer" [@fadeInOut]>

  <!-- Row 1 -->
  <div class="drawer-row flxRowSB mb-2">
    <!-- Settings -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Settings </span>

      <div class="flxRowNwSB p-2">
        <!-- Site Image -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="settings/siteimages"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-image icon"></i>
          <span class="icon-text">Sites</span>
        </div>

        <!-- Announce -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="settings/notifycustomers"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-bullhorn icon"></i>
          <span class="icon-text">Announce</span>
        </div>

        <!-- Payment Settings -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="settings/paymentsettings"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-money-check-alt icon"></i>
          <span class="icon-text">Payment</span>
        </div>

        <!-- Delivery Settings -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="settings/deliverySettings"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-truck icon"></i>
          <span class="icon-text">Delivery</span>
        </div>

        <!-- SMS Settings -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="settings/smsSettings"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-comment-alt icon"></i>
          <span class="icon-text">SMS</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Row 2 -->
  <div class="drawer-row flxRowSB mb-2">
    <!-- Banner -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Banner </span>

      <div class="flxRowNwSB p-2">
        <!-- New Banner -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="saveBanner/0"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-plus-square icon" aria-hidden="true"></i>
          <span class="icon-text">Add Banner</span>
        </div>

        <!-- Banner List -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="banner" [routerLinkActive]="'is-active'">
          <i class="fa fa-money-check icon"></i>
          <span class="icon-text">Banners</span>
        </div>
      </div>
    </div>

    <!-- Messages -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Messages </span>

      <div class="flxRowNwSB p-2">
        <!-- Messages -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="customermessage"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-envelope icon"></i>
          <span class="icon-text">Messages</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Row 3 -->
  <div class="drawer-row flxRowSB mb-2">
    <!-- Finance -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Finance </span>

      <div class="flxRowNwSB p-2">
        <!-- Overview -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="finance/overview"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-chart-pie icon"></i>
          <span class="icon-text">Overview</span>
        </div>

        <!-- Investment -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="finance/investment"
          [routerLinkActive]="'is-active'">
          <i class="fas fa-coins icon"></i>
          <span class="icon-text">Investment</span>
        </div>

        <!-- Cash Register -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="finance/cashRegister"
          [routerLinkActive]="'is-active'">
          <i class="fas fa-cash-register icon"></i>
          <span class="icon-text">Cash Register</span>
        </div>
      </div>
    </div>

    <!-- Bulk Upload -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Bulk Upload </span>

      <div class="flxRowNwSB p-2">
        <!-- Item Bulk Upload -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="bulkupload" [routerLinkActive]="'is-active'">
          <i class="fa fa-file-upload icon"></i>
          <span class="icon-text">Items Upload</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Row 4 -->
  <div class="drawer-row flxRowSB mb-2">
    <!-- Category -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Category </span>

      <div class="flxRowNwSB p-2">
        <!-- New Category -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="saveCategory/0"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-plus-square icon" aria-hidden="true"></i>
          <span class="icon-text">Add Category</span>
        </div>

        <!-- Category List -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="categorydetails"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-layer-group icon"></i>
          <span class="icon-text">Category</span>
        </div>
      </div>
    </div>

    <!-- Items -->
    <div class="menu-group">
      <span class="drawer-menu-title"> Item </span>

      <div class="flxRowNwSB p-2">
        <!-- New Item -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="/item/saveItem/0" [routerLinkActive]="'is-active'">
          <i class="fa fa-plus-square icon" aria-hidden="true"></i>
          <span class="icon-text">Add Item</span>
        </div>

        <!-- Item List -->
        <div class="icon-menu" (click)="closeMoreMenuDrawer()" routerLink="item/itemdetails"
          [routerLinkActive]="'is-active'">
          <i class="fa fa-box icon"></i>
          <span class="icon-text">Items</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Close -->
  <div class="text-center pt-4">
    <i class="far fa-times-circle icon-l close-icon" (click)="closeMoreMenuDrawer()"></i>
  </div>
</div>