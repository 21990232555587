/*** Core ***/
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

/*** Modules ***/
import { HomeModule } from '../home/home.module';
import { DirectivesModule } from '../helpers/directives/directives.module';

/*** Components ***/
import { LayoutComponent } from './layout.component';
import { TopBarComponent } from './menu/top-bar/top-bar.component';
import { BottomMenuBarComponent } from './menu/bottom-menu-bar/bottom-menu-bar.component';
import { SideMenuBarComponent } from './menu/side-menu-bar/side-menu-bar.component';

@NgModule({
  declarations: [LayoutComponent,
    TopBarComponent,
    BottomMenuBarComponent,
    SideMenuBarComponent
  ],
  imports: [CommonModule,
    RouterModule,
    HomeModule,
    FormsModule,
    DirectivesModule
  ],
  exports: [LayoutComponent,
    TopBarComponent,
    BottomMenuBarComponent,
    SideMenuBarComponent
  ],
})
export class LayoutModule { }
