"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.APICallOptionsModel = void 0;
var APICallOptionsModel = /** @class */ (function () {
    function APICallOptionsModel(value) {
        this.showLoader = true;
        if (value)
            Object.assign(this, value);
    }
    return APICallOptionsModel;
}());
exports.APICallOptionsModel = APICallOptionsModel;
