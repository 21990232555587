"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SiteStatusTypeEnum = void 0;
var SiteStatusTypeEnum;
(function (SiteStatusTypeEnum) {
    SiteStatusTypeEnum["ACTIVE"] = "ACTIVE";
    SiteStatusTypeEnum["INACTIVE"] = "INACTIVE";
    SiteStatusTypeEnum["UNDER_CONSTRUCTION"] = "UNDER_CONSTRUCTION";
    SiteStatusTypeEnum["UNDER_MAINTENANCE"] = "UNDER_MAINTENANCE";
})(SiteStatusTypeEnum = exports.SiteStatusTypeEnum || (exports.SiteStatusTypeEnum = {}));
