"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertType = void 0;
var AlertType;
(function (AlertType) {
    AlertType["Info"] = "Info";
    AlertType["Success"] = "Success";
    AlertType["Warning"] = "Warning";
    AlertType["Error"] = "Error";
})(AlertType = exports.AlertType || (exports.AlertType = {}));
