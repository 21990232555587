"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryModel = void 0;
var select_option_model_1 = require("../commons/select-option.model");
var CountryModel = /** @class */ (function () {
    function CountryModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(CountryModel.prototype, "StatesSelectOptions", {
        get: function () {
            return this.States.map(function (s) { return new select_option_model_1.SelectOptionModel({
                value: s.StateCode,
                lable: s.State
            }); });
        },
        enumerable: false,
        configurable: true
    });
    return CountryModel;
}());
exports.CountryModel = CountryModel;
