
import { InjectionToken } from '@angular/core';

export const defaultErrors = {
  required: (error) => `This field is required`,
  min: (error) => `Value cannot be less than ${error.min}`,
  max: (error) => `Value cannot be more than ${error.max}`,
  minlength: ({ requiredLength, actualLength }) => `Should be atleast ${requiredLength} characters.`,
  maxlength: ({ requiredLength, actualLength }) => `Length can't be more than ${requiredLength} characters.`,
  email: (error) => `Invalid email`,
  pattern: (error, errLable) => `Invalid ${errLable}`,
  lessThan: ({ value, targetField }) => `Cannot be less than ${targetField}`,
  greaterThan: ({ value, targetField }) => `Cannot be greater than ${targetField}`,
  shouldBePositiveInteger: ({ value }) => `Should be a positive number`,
  custom: ({ message }) => `${message}`,
  atleast_one_required: ({ value }) => `Atleast one of ${value?.join()} is required`

}

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => defaultErrors
});
