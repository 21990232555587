"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExpenseModel = void 0;
var ExpenseModel = /** @class */ (function () {
    function ExpenseModel(value) {
        Object.assign(this, value);
    }
    return ExpenseModel;
}());
exports.ExpenseModel = ExpenseModel;
