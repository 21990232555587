"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MeasurementUnitModel = void 0;
var MeasurementUnitModel = /** @class */ (function () {
    function MeasurementUnitModel(value) {
        Object.assign(this, value);
    }
    return MeasurementUnitModel;
}());
exports.MeasurementUnitModel = MeasurementUnitModel;
