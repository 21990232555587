"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockLocationModel = void 0;
var utils_1 = require("../../utils");
var StockLocationModel = /** @class */ (function () {
    function StockLocationModel(value) {
        Object.assign(this, value);
        if (value)
            this.Quantity = utils_1.Utilities.parseNumber(value.Quantity);
    }
    return StockLocationModel;
}());
exports.StockLocationModel = StockLocationModel;
