"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BasicInfoModel = void 0;
var BasicInfoModel = /** @class */ (function () {
    function BasicInfoModel(value) {
        Object.assign(this, value);
    }
    return BasicInfoModel;
}());
exports.BasicInfoModel = BasicInfoModel;
