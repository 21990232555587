"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenericConstants = void 0;
var order_status_enum_1 = require("../enum/order-status.enum");
var GenericConstants = /** @class */ (function () {
    function GenericConstants() {
    }
    GenericConstants.activeOrderStatusOptions = [
        order_status_enum_1.OrderStatusEnum.NEW, order_status_enum_1.OrderStatusEnum.PACKAGING,
        order_status_enum_1.OrderStatusEnum.DELIVERED, order_status_enum_1.OrderStatusEnum.COMPLETED
    ];
    return GenericConstants;
}());
exports.GenericConstants = GenericConstants;
