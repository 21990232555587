import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { AlertType } from 'eccommons';

import { SharedService } from '../shared/services/shared.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {

  constructor(private sharedService: SharedService) {
  }

  handleError(error: Error) {
    if (error && error.message && !(error instanceof HttpErrorResponse) &&
      error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') < 0) {
      this.sharedService.hideLoader();
      this.sharedService.notify(AlertType.Error, 'Something went wrong!');
      console.error(error);
    }
  }
}