import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';
import { BehaviorSubject } from 'rxjs';

import { AdminUser } from 'eccommons';

import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { SharedService } from 'src/app/shared/services/shared.service';

@Injectable({
  providedIn: 'root',
})
export class AccountService {

  user: AdminUser;
  loginStateChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.IsLoggedIn);

  get IsLoggedIn(): boolean {
    return this.user?.IsLoggedIn;
  }

  get LoggedInUserID(): number {
    return this.user?.AdminUserID;
  }

  get BID(): number {
    if (this.IsLoggedIn)
      return this.user.BID;
    else
      return null;
  }

  get Token(): string {
    if (this.IsLoggedIn)
      return this.user.Token;
    else
      return null;
  }

  get ProfilePicURL(): string {
    if (this.IsLoggedIn)
      return this.user.ProfilePicURL;
    else
      return null;
  }

  constructor(private httpHelper: HttpHelperService, private appSettings: AppSettings, private sharedService: SharedService,
    private router: Router) {
    this.initUser();
  }

  validateAdminUser(phoneNumber: string, password: string): Observable<any> {
    const dataToSend = {
      'phoneNo': phoneNumber,
      'pwd': password
    };
    return this.httpHelper.post(this.appSettings.URL_Validate_Admin_User, dataToSend).pipe(map(res => res.Data));
  }

  saveUser(user: AdminUser) {
    this.user = user;
    this.sharedService.saveToLocalStorage(this.appSettings.LS_USER_INFO, this.user);
    this.appSettings.BID = this.user.BID;
    this.appSettings.APP_OWNER_NAME = this.user.Name;
    this.appSettings.APP_OWNER_EMAIL = this.user.Email;
  }

  logOutUser() {
    this.user = null;
    this.sharedService.removeFromLocalStorage(this.appSettings.LS_USER_INFO);
    this.sharedService.clearBusinessInfoCache();
    this.loginStateChangeSubject.next(false);
  }

  initUser() {
    this.user = new AdminUser(this.sharedService.getFromLocalStorage<AdminUser>(this.appSettings.LS_USER_INFO));
  }

  sendOTP(phone: string): Observable<number> {
    const dataToSend = {
      'phone': phone
    };
    return this.httpHelper.post<any, number>(this.appSettings.URL_SendOTP, dataToSend)
      .pipe(map(res => res.Data));
  }


  setPassword(bid: string, phone: string, pin: string, password: string): Observable<number> {
    const dataToSend = {
      'bid': bid,
      'phone': phone,
      'pin': pin,
      'password': password

    };
    return this.httpHelper.post<any, number>(this.appSettings.URL_SetPassword, dataToSend)
      .pipe(map(res => res.Data));
  }
}