import { Injectable } from '@angular/core';
import { HttpHelperService } from 'src/app/shared/services/http-helper.service';
import { AppSettings } from 'src/app/shared/services/appSettings.services';
import { BannerModel } from 'eccommons';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/internal/operators/map';

@Injectable({
  providedIn: 'root',
})
export class BannerService {

  bannerToEdit: BannerModel;

  constructor(private httpHelper: HttpHelperService, private appSettings: AppSettings) {
  }

  getAllBannerDetails(): Observable<Array<BannerModel>> {
    return this.httpHelper.getWithOptions<Array<BannerModel>>({
      apiTarget: 'site-api',
      endPoint: this.appSettings.URL_Banner,
    }).pipe(map(res => {
      console.log(res);
      if (!res?.Data?.length)
        return [];
      // sort by banner display order
      return res.Data.map(b => new BannerModel(b)).sort((a, b) => a.DisplayOrder - b.DisplayOrder);
    }));
  }

  getBannerDetailsById(bannerID?: string): Observable<BannerModel> {
    let _endPoint = this.appSettings.URL_Banner;
    if (bannerID)
      _endPoint = this.appSettings.URL_Banner + '/' + bannerID;

    return this.httpHelper.getWithOptions<BannerModel>({
      apiTarget: 'site-api',
      endPoint: _endPoint,
    }).pipe(map(res => {
      console.log(res);
      if (!res?.Data)
        return null;

      return new BannerModel(res.Data);
    }));
  }

  saveBanner(bannerInfo: BannerModel, file: File): Observable<any> {
    let input = new FormData();
    input.append("BannerImage", file);
    input.append("formData", JSON.stringify(bannerInfo));
    let apiCallOptions = {
      apiTarget: 'site-api',
      endPoint: this.appSettings.URL_Banner,
      dataToSend: input,
    };

    if (bannerInfo.BannerID) {
      return this.httpHelper.putWithOptions(apiCallOptions).pipe(map(res => res.Data));
    }
    else {
      return this.httpHelper.postWithOptions(apiCallOptions).pipe(map(res => res.Data));
    }
  }

  saveBannerDisplayOrder(bannerIDList: Array<number>) {
    const dataToSend = {
      'BannerIDList': bannerIDList
    };
    let apiCallOptions = {
      apiTarget: 'site-api',
      endPoint: this.appSettings.URL_SaveBannerDisplayOrder,
      dataToSend: dataToSend,
    };
       
    return this.httpHelper.postWithOptions(apiCallOptions);
  }
}