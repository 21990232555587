/*** Core ****/
import { Component, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';

/*** Libraries ****/
import { OrderModel, ReceiptGroupEnum } from 'eccommons';

/*** Services ***/
import { OrderService } from 'src/app/order-management/services/order.service';


@Component({
  selector: 'app-bottom-menu-bar',
  templateUrl: './bottom-menu-bar.component.html',
  styleUrls: ['./bottom-menu-bar.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate('400ms ease-in-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate('400ms ease-in-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class BottomMenuBarComponent implements OnInit {

  showMoreMenuDrawer: boolean = false;
  showAddMenuDrawer: boolean = false;

  /*** Constructore ***/
  constructor(private orderService: OrderService, private router: Router) { }

  /*** Life Cycle ***/
  // On Init
  ngOnInit(): void {
  }

  /*** Events ***/
  closeMoreMenuDrawer(): void {
    this.showMoreMenuDrawer = false;
  }

  closeAddMenuDrawer() {
    this.showAddMenuDrawer = false;
  }

  newOrder() {
    let orderDetails = new OrderModel();
    orderDetails.ReceiptGroup = ReceiptGroupEnum.INVOICE;
    this.orderService.orderToEdit = orderDetails;
    this.router.navigate(['/order/bill', orderDetails.OrderID || 0, orderDetails.ReceiptGroup]);
    this.closeAddMenuDrawer();
  }
}
