"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectOptionModel = void 0;
var SelectOptionModel = /** @class */ (function () {
    function SelectOptionModel(value) {
        Object.assign(this, value);
    }
    return SelectOptionModel;
}());
exports.SelectOptionModel = SelectOptionModel;
