"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedTransactionModel = void 0;
var enum_1 = require("../../enum");
var transactions_model_1 = require("./transactions-model");
var GroupedTransactionModel = /** @class */ (function (_super) {
    __extends(GroupedTransactionModel, _super);
    function GroupedTransactionModel(value) {
        var _this = _super.call(this, value) || this;
        Object.assign(_this, value);
        return _this;
    }
    Object.defineProperty(GroupedTransactionModel.prototype, "IsGroupTransaction", {
        get: function () {
            var _a;
            return ((_a = this.GroupedTransactions) === null || _a === void 0 ? void 0 : _a.length) > 0;
        },
        enumerable: false,
        configurable: true
    });
    GroupedTransactionModel.prototype.addTransactionToGroup = function (tran) {
        if (tran) {
            if (!this.GroupedTransactions) {
                this.GroupedTransactions = new Array();
                Object.assign(this, tran);
                // remove order specific info
                this.OrderID = null;
            }
            else {
                this.computeGroupAmount(tran);
            }
            this.GroupedTransactions.push(tran);
        }
    };
    GroupedTransactionModel.prototype.computeGroupAmount = function (tran) {
        if (tran.Action == enum_1.TransactionActionType.CREDIT)
            this.Amount += tran.Amount;
        else if (tran.Action == enum_1.TransactionActionType.DEBIT)
            this.Amount -= tran.Amount;
    };
    return GroupedTransactionModel;
}(transactions_model_1.TransactionModel));
exports.GroupedTransactionModel = GroupedTransactionModel;
