"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SocialMediaType = void 0;
var SocialMediaType;
(function (SocialMediaType) {
    SocialMediaType["FB"] = "FB";
    SocialMediaType["Twitter"] = "TWITTER";
    SocialMediaType["Instagram"] = "INSTAGRAM";
    SocialMediaType["Gplus"] = "GPLUS";
})(SocialMediaType = exports.SocialMediaType || (exports.SocialMediaType = {}));
