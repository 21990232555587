"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StockLogModel = void 0;
var utils_1 = require("../../utils");
var StockLogModel = /** @class */ (function () {
    function StockLogModel(value) {
        Object.assign(this, value);
        this.Qty = utils_1.Utilities.parseNumber(this.Qty);
        this.ChangedQtyDisplayName = "".concat(this.Qty, " ").concat(this.UnitCode);
        if (this.Qty == null)
            this.ChangedQtyDisplayName = "Unlimited ".concat(this.UnitCode);
        this.CurrentQty = utils_1.Utilities.parseNumber(this.CurrentQty);
        this.CurrentQtyDisplayName = "".concat(this.CurrentQty, " ").concat(this.CurrentUnitCode);
        if (this.CurrentQty == null)
            this.CurrentQtyDisplayName = "Unlimited ".concat(this.CurrentUnitCode);
        if (this.Direction == true) {
            this.DirectionAction = "InWard";
        }
        else {
            this.DirectionAction = "OutWard";
        }
    }
    return StockLogModel;
}());
exports.StockLogModel = StockLogModel;
