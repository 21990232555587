    <div class="background-image">
        <div class="container text-center center flxColCntr Bg">
            <div>
          
              <div class="section-title flxRowNwSB align-end">
                <h4> Set your password </h4>
    
                <img [src]="businessUpLogoPath" alt="BusinessUP-Logo" />
              </div>
          
              <div class="form-container shadowed-box section-content">
                <form [formGroup]="passwordSetForm" autocomplete="off">
                 <!-- BusinessID -->
                 <div class="form-group">
                   <input type="text" class="form-control" formControlName="bid" placeholder="bid" appAutofocus no-required-asterisk>
                   <span class="form__label">Business ID </span>
                 </div>
          
                  <!-- Phone Number-->
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="phone" placeholder="phone number"
                      no-required-asterisk maxlength="10">
                      <button class="btn btn-sm btn-primary btn-align" type="button" [disabled]="disableBtn" (click)="sendOTP()">{{!isEnabledOtp ? "Send OTP" : "Resend OTP"}}</button>
                    <span class="form__label">Phone Number </span>
                  </div>
                  
                  <!-- OTP -->
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="pin" placeholder="OTP"
                      no-required-asterisk maxlength="4">
                    <span class="form__label">OTP </span>
                  </div>
    
                  <!-- Password -->
                  <div class="form-group">
                    <input type="password" class="form-control" formControlName="password" placeholder="password"
                      no-required-asterisk>
                    <span class="form__label">Password</span>
                  </div>
    
                   <!--Confirm Password -->
                   <div class="form-group">
                    <input type="password" class="form-control" formControlName="confirmPassword" placeholder="Confirm password"
                      no-required-asterisk (input)="checkPassword()">
                    <span class="form__label">Confirm Password</span>
                    <span class="alert alert-danger" *ngIf="!passwordMatch">Please make sure your password match</span>
                  </div>

                  <div class="row float-right clearfix">
                    <button class="btn btn btn-success" type="submit" [disabled]="passwordSetForm.invalid" (click)="onSubmit()">Set your password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
    </div>
    