"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisplayItemModel = void 0;
var DisplayItemModel = /** @class */ (function () {
    function DisplayItemModel(value) {
        Object.assign(this, value);
    }
    return DisplayItemModel;
}());
exports.DisplayItemModel = DisplayItemModel;
