import { ComponentFactoryResolver, ComponentRef, Directive, ElementRef, Host, Inject, Optional, Renderer2, Self, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs/internal/Observable';
import { EMPTY } from 'rxjs/internal/observable/empty';
import { merge } from 'rxjs/internal/observable/merge';
import { FormErrorMsgComponent } from 'src/app/shared/components/form-error-msg/form-error-msg.component';

import { FormSubmitDirective } from '../form-submit.directive';
import { ControlErrorContainerDirective } from './control-error-container.directive';
import { FORM_ERRORS } from './form-errors';

@UntilDestroy()
@Directive({
  selector: '[formControl], [formControlName]'
})
export class ControlErrorsDirective {

  container: ViewContainerRef;
  submit$: Observable<Event>;
  ref: ComponentRef<FormErrorMsgComponent>;

  constructor(@Self() private control: NgControl,
    @Optional() @Host() private form: FormSubmitDirective,
    @Inject(FORM_ERRORS) private errors,
    private vcr: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    @Optional() controlErrorContainer: ControlErrorContainerDirective
    ) {
    this.submit$ = this.form ? this.form.submit$ : EMPTY;
    this.container = controlErrorContainer ? controlErrorContainer.vcr : vcr;
  }

  ngOnInit() {
    merge(
      this.submit$,
      this.control.valueChanges
    ).pipe(
      untilDestroyed(this)
    ).subscribe(() => {
      // handle errors      
      const controlErrors = this.control.errors;
      if (controlErrors) {
        const errLable = this.control?.control['errLable'];
        const firstKey = Object.keys(controlErrors)[0];
        const getError = this.errors[firstKey];
        const text = getError(controlErrors[firstKey], errLable);
        this.setError(text);
      }
      else if (this.ref) {
        this.setError(null);
      }
    });     
  }


  setError(text: string) {    
    if (!this.ref) {
      const factory = this.resolver.resolveComponentFactory(FormErrorMsgComponent);
      this.ref = this.container.createComponent(factory);
    }

    this.ref.instance.text = text;
  }

}
