import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({ name: 'cdnpath' })
export class CDNPathPipe implements PipeTransform {

    public constructor() {
    }

    transform(value: string): string {
        return environment.cdn_endpoint + value;
    }

}
