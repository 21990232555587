"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentModeType = void 0;
var PaymentModeType;
(function (PaymentModeType) {
    PaymentModeType[PaymentModeType["NET_BANKING"] = 1] = "NET_BANKING";
    PaymentModeType[PaymentModeType["CASH"] = 2] = "CASH";
    PaymentModeType[PaymentModeType["DIRECT_BANKING"] = 3] = "DIRECT_BANKING";
    PaymentModeType[PaymentModeType["MOBILE_BANKING"] = 4] = "MOBILE_BANKING";
    PaymentModeType[PaymentModeType["WALLET"] = 5] = "WALLET";
})(PaymentModeType = exports.PaymentModeType || (exports.PaymentModeType = {}));
