"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableConfigModel = void 0;
var TableConfigModel = /** @class */ (function () {
    function TableConfigModel(value) {
        this.PageSize = 10;
        Object.assign(this, value);
    }
    Object.defineProperty(TableConfigModel.prototype, "HasComputedColumn", {
        get: function () {
            var _a;
            return (_a = this.Headers) === null || _a === void 0 ? void 0 : _a.some(function (th) { return th.IsComputationRequired; });
        },
        enumerable: false,
        configurable: true
    });
    return TableConfigModel;
}());
exports.TableConfigModel = TableConfigModel;
