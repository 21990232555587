import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AdminService } from '../services/admin.service';
import { CategoryModel, TableConfigModel, TableActionModel, AlertType, TableColumnModel, TableColumnType, TableActionType } from 'eccommons'
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-category-details',
  templateUrl: './category-details.component.html',
  styleUrls: ['./category-details.component.scss']
})
export class CategoryDetailsComponent implements OnInit {

  tableConfig: TableConfigModel;
  rowData: Array<CategoryModel>;

  constructor(private http: HttpClient, private adminService: AdminService, private _router: Router,
    private _route: ActivatedRoute, private sharedService: SharedService) {
    this.tableConfig = new TableConfigModel({
      Headers: [
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Display Order',
          FieldName: 'DisplayOrder'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Name',
          FieldName: 'Name'
        }),      
        new TableColumnModel({
          ColumnType: TableColumnType.LABLE,
          ColumnName: 'Parent',
          FieldName: 'ParentCategoryName'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.BOOLEAN,
          ColumnName: 'Active',
          FieldName: 'IsActive'
        }),
        new TableColumnModel({
          ColumnType: TableColumnType.ACTION,
          ColumnName: 'Action',
          ActionOption: new TableActionModel({
            ActionType: TableActionType.ICON_EDIT,
            Lable: 'Edit',
          })
        })
      ],
      ShowRowNo: false,
      EnableRowSelection: false,
      AllowReOrdering: true
    });
  }
  ngOnInit() {
    this.loadCategory();
  }

  onActionClick(action: TableActionModel) {
    if (action && action.RowData) {
      const rowData = action.RowData as CategoryModel;
      this._router.navigate(['/saveCategory', rowData.CategoryID]);
    }
  }

  onRowSelect(selectedRows: Array<CategoryModel>) {    
  }

  onRowSorted(sortedKeys: Array<number>) {    
    this.adminService.saveCategoryDisplayOrder(sortedKeys).subscribe(res => {
      if (res && res > 0) {
        this.sharedService.notify(AlertType.Success, 'Saved Display Order Successfully!');
        this.loadCategory();
      }
    })
  }

  private loadCategory() {
    this.adminService.getCategory().subscribe(data => {
      if (data) {
        this.rowData = data;
        this.rowData.forEach(c => {
          if (c.ParentID)
            c.ParentCategoryName = this.rowData.find(pc => pc.CategoryID === c.ParentID)?.Name;
          else
            c.ParentCategoryName = 'No Parent';
        })
      }
    });
  }
}
