<section>  
  <div class="d-flex flex-column shadowed-box-non-mobile section-content">
    <div>
      <button class="btn btn-outline-primary btn-sm float-right" [routerLink]="'/saveCategory/0'">+ New</button>
    </div>
    <div>
      <ec-app-table *ngIf="rowData" [tableConfig]="tableConfig" [rowData]="rowData" rowKey="CategoryID"
        (actionClickEmit)="onActionClick($event)" (rowSortedEmit)="onRowSorted($event)">
      </ec-app-table>
    </div>
  </div>
</section>