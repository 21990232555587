"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomDataType = void 0;
var custom_data_type_enum_1 = require("../enum/custom-data-type.enum");
var utils_1 = require("../utils");
var CustomDataType = /** @class */ (function () {
    function CustomDataType() {
    }
    CustomDataType.IsCustomDataType = function (value) {
        return this.DataTypes.find(function (dt) { return dt.Name == utils_1.Utilities.stringToEnum(custom_data_type_enum_1.CustomDataTypeEnum, value); });
    };
    CustomDataType.IsValueValid = function (dataTypeName, value) {
        var dataType = this.IsCustomDataType(dataTypeName);
        if (!dataType)
            return false;
        return dataType.RegExp.test(String(value));
    };
    CustomDataType.DataTypes = new Array({
        Name: custom_data_type_enum_1.CustomDataTypeEnum.ANY_NATURAL_NUMBER,
        RegExp: new RegExp(/^[0-9]+$/) // 0, 1, 2, 3 etc.
    }, {
        Name: custom_data_type_enum_1.CustomDataTypeEnum.ANY_NUMBER,
        RegExp: new RegExp(/^[0-9]+(\.[0-9]+)?$/) // 0.0, 0.1, 1.0, 1.1, 1.2 etc.
    });
    return CustomDataType;
}());
exports.CustomDataType = CustomDataType;
