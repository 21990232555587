"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatesType = void 0;
var StatesType;
(function (StatesType) {
    StatesType["AP"] = "Andhra Pradesh";
    StatesType["KL"] = "Kerala";
    StatesType["TN"] = "Tamil Nadu";
    StatesType["KA"] = "Karnataka";
    StatesType["MH"] = "Maharashtra";
})(StatesType = exports.StatesType || (exports.StatesType = {}));
