import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from "@angular/forms";
import { AdditionalOrderItemModel, ChargeType, OrderItemModel, OrderModel, PaymentActionType, PaymentStatusEnum, TaxModel, Utilities } from "eccommons";
import { CustomCurrencyPipe } from 'src/app/shared/pipe/custom-currency.pipe';

export class AdminUtilities {
  public static currencyPipe = new CustomCurrencyPipe();

  public static scrollToTop() {
    const elmnt = document.getElementById('body-content');
    elmnt.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  }

  public static oneOfThemIsRequiredValFn(controlNames: string[]): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      let atleastOneHasValue: boolean = false;
      let errLables: string[] = [];
      for (let i = 0; i < controlNames?.length; i++) {
        let cntrl = group.get(controlNames[i]);
        errLables.push(cntrl['errLable'] ?? controlNames[i]);

        const ctrlValue = cntrl.value;
        if (ctrlValue && (ctrlValue.constructor !== Object || Object.keys(ctrlValue).length)) {
          atleastOneHasValue = true;
          break;
        }
      }

      if (errLables)

        return atleastOneHasValue ? null : { 'atleast_one_required': { errLables: errLables } };
    }
  }

  public static allShouldBeDifferentValFn(controlNames: string[]): ValidatorFn {
    return (group: UntypedFormGroup): { [key: string]: any } => {
      let duplicateFound: boolean = false;
      let valueSet = new Set();
      for (let i = 0; i < controlNames?.length; i++) {
        const ctrlValue = group.get(controlNames[i]).value;
        if (ctrlValue) {
          if (valueSet.has(ctrlValue)) {
            duplicateFound = true;
            break;
          }
          else
            valueSet.add(ctrlValue);
        }
      }

      return duplicateFound ? { 'custom': { message: `Fields ${controlNames?.join()} should not have same value` } } : null;
    }
  }

  public static newFormControl(cntrl: UntypedFormControl, properties: object = undefined): UntypedFormControl {
    if (properties !== undefined)
      Object.assign(cntrl, properties);
    return cntrl;
  }

  public static getCtrl(formGroup: UntypedFormGroup, ctrlName: string): AbstractControl {
    return formGroup?.get(ctrlName);
  }

  public static getCtrlValue<T>(formGroup: UntypedFormGroup, ctrlName: string): T {
    return formGroup?.get(ctrlName).value as T;
  }

  public static getPaymentActionColorCode(paymentAction: PaymentActionType): string {
    if (paymentAction == PaymentActionType.TO_RECEIVE)
      return 'positive-color';
    else if (paymentAction == PaymentActionType.TO_PAY)
      return 'negative-color';
    else
      return 'text-muted';
  }

  public static createPropertyValueMapJson<T>(data: Array<T>, propertyNameList: Array<string>,
    primaryDataFieldName: string, secondaryPropFieldName: string, excludingPropNameList: Array<string>): Array<T> {
    let propValueMapJson = new Array<T>();
    propertyNameList = propertyNameList.filter(pname => !excludingPropNameList.some(expname => expname == pname));

    data?.forEach(d => {
      let propValueItemJson = {} as T;

      propertyNameList?.forEach(propName => {
        propValueItemJson[propName] = d[primaryDataFieldName];
      });

      if (propValueItemJson)
        propValueMapJson.push(propValueItemJson);
    });

    return propValueMapJson;
  }

  public static displayNonZeroValue(data: any): number | null {
    data = Utilities.parseNumber(data);
    if (!data)
      return null;
    else
      return data;
  }

  public static isAnyPaymentReceived(paymentStatusID: PaymentStatusEnum): boolean {
    return [PaymentStatusEnum.PARTIAL, PaymentStatusEnum.SUCCESS]
      .some(ps => ps == paymentStatusID);
  }

  public static getReceiptPrinterOrderModel(orderInfo: OrderModel): Object {
    let orderInfoDynObj = {
      OrderID: orderInfo.OrderID,
      OrderItems: this.getRecieptPrinterOrderItemModel(orderInfo.OrderItems),
      Taxes: this.getReceiptPrinterTaxModel(orderInfo.Taxes),
      AdditionalOrderItems: this.getReceiptPrinterAOIModel(orderInfo.AdditionalOrderItems),
      TotalItemTaxableAmount: this.currencyPipe.transform(orderInfo.TotalItemTaxableAmount),
      TotalAdditionalItemAmount: this.currencyPipe.transform(orderInfo.TotalAdditionalItemAmount),
      TotalTaxableAmount: this.currencyPipe.transform(orderInfo.TotalTaxableAmount),
      TotalItemTax: this.currencyPipe.transform(orderInfo.TotalItemTax),
      TotalAdditionalItemTax: this.currencyPipe.transform(orderInfo.TotalAdditionalItemTax),
      TotalTax: this.currencyPipe.transform(orderInfo.TotalTax),
      TotalPayable: this.currencyPipe.transform(orderInfo.TotalPayable),
      TotalSavings: orderInfo.computeTotalSavings() ? this.currencyPipe.transform(orderInfo.computeTotalSavings()) : '',
      TotalItems: orderInfo.TotalItems,
      TotalQty: orderInfo.TotalQty,
      PaymentBalance: this.currencyPipe.transform(orderInfo.PaymentBalance),
      ReceivedAmount: this.currencyPipe.transform(orderInfo.TotalReceivedAmount),
      OrderDateTime: orderInfo.CreatedDateTime || new Date(),
      ReceiptNumber: orderInfo.ReceiptNumber,
      PartyName: orderInfo?.Party?.Name,
      PartyPhone: orderInfo?.Party?.ContactNumber
    };

    return orderInfoDynObj;
  }

  public static getRecieptPrinterOrderItemModel(orderItems: Array<OrderItemModel>): Object {

    let orderItemsArrayObj = [];
    orderItems?.forEach(ordItem => {
      orderItemsArrayObj.push({
        ItemName: ordItem.ItemName,
        Code: ordItem.Code,
        VarietyName: ordItem.VarietyName,
        FullName: ordItem.FullName,
        UnitPrice: this.currencyPipe.transform(ordItem.UnitPrice),
        UnitType: ordItem.UnitType,
        Count: ordItem.Count,
        Price: this.currencyPipe.transform(ordItem.Price),
        MRP: ordItem.MRP ? this.currencyPipe.transform(ordItem.MRP) : this.currencyPipe.transform(ordItem.Price),
        TaxID: ordItem.TaxID,
        TaxName: ordItem.TaxName
      });
    });

    return orderItemsArrayObj;
  }

  public static getReceiptPrinterTaxModel(taxes: Array<TaxModel>): Object {
    let taxesDynArrayObj = [];

    taxes?.forEach(tax => {
      let taxToPay = this.currencyPipe.transform(tax.TaxToPay);
      taxesDynArrayObj.push({
        AppliedTaxDescription: `${tax.Name} for ${this.currencyPipe.transform(tax.TaxableAmount)}`,
        TaxedAmount: taxToPay
      })
    });

    return taxesDynArrayObj;
  }

  public static getReceiptPrinterAOIModel(additionalOrderItems: Array<AdditionalOrderItemModel>): Object {
    let additionalOrderItemArrayObj = [];
    additionalOrderItems?.forEach(aoi => {
      additionalOrderItemArrayObj.push({
        Name: aoi.DisplayName,
        Note: aoi.Note,
        TaxName: aoi.TaxName,
        Amount: `${(aoi.ChargeType == ChargeType.MINUS) ? '-' : ''}${this.currencyPipe.transform(aoi.Amount)}`
      })
    });

    return additionalOrderItemArrayObj;
  }

  public static getThumbnailURI(path: string): string {
    if (path) {
      //add _m to file name to get thumbnail
      let fileName = path.split('/').pop();
      let thumbnailFileName = fileName.replace('.', '_m.');
      let thumbnailPath = path.replace(fileName, thumbnailFileName);
      return thumbnailPath;
    }
  }
  
}