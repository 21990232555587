import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { TaxModel, SelectOptionModel, DeductionType, AlertType } from 'eccommons';

import { TaxAndChargeService } from '../service/tax-charge.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-save-tax-charge',
  templateUrl: './save-tax-charge.component.html',
  styleUrls: ['./save-tax-charge.component.scss']
})
export class SaveTaxChargeComponent {
  /** To Replace with Charges **/
  
  // frmTaxAndCharge: FormGroup;
  // taxAndCharge: TaxModel;
  // deductionTypeSelOption: Array<SelectOptionModel>;

  //constructor(private route: ActivatedRoute, private taxChargeService: TaxAndChargeService, private fb: FormBuilder,
  //   private sharedService: SharedService, private router: Router) {
  //   this.deductionTypeSelOption = [
  //     {
  //       lable: 'Percentage',
  //       value: DeductionType.PERCENT
  //     },
  //     {
  //       lable: 'Amount',
  //       value: DeductionType.AMOUNT
  //     }
  //   ];
  // }

  // ngOnInit(): void {
  //   // this.route.params.subscribe(params => {
  //   //   const taxChargeID = Number(params['taxChargeID']) || 0;
  //   //   if (taxChargeID) {
  //   //     if (this.taxChargeService.taxToEdit && this.taxChargeService.taxToEdit.TaxAndChargeID == taxChargeID) {
  //   //       this.taxAndCharge = this.taxChargeService.taxToEdit;
  //   //       this.initializeForm();
  //   //     }
  //   //     else {
  //   //       this.taxChargeService.getTaxAndCharges(taxChargeID).subscribe(res => {
  //   //         this.taxAndCharge = res.find(tc => Number(tc.TaxAndChargeID) === taxChargeID);
  //   //         this.initializeForm();
  //   //       });
  //   //     }
  //   //   }
  //   //   else {
  //   //     this.taxAndCharge = new TaxAndChargeModel();
  //   //     this.initializeForm();
  //   //   }
  //   // });
  // }

  // public saveSaveAndCharge() {
  //   if (this.frmTaxAndCharge.valid) {
  //     this.taxChargeService.saveTaxAndCharge(this.frmTaxAndCharge.getRawValue()).subscribe(res => {
  //       if (res > 0) {
  //         this.sharedService.notify(AlertType.Success, 'Saved Successfully');
  //         this.router.navigate(['/taxandcharges']);
  //       }
  //     });
  //   }
  // }

  // cancelSave() {
  //   window.history.back();
  // }

  // private initializeForm(): void {
  //   this.frmTaxAndCharge = this.fb.group({
  //     'TaxAndChargeID': new FormControl(this.taxAndCharge.TaxID),
  //     'Name': new FormControl(this.taxAndCharge.Name, Validators.required),
  //     'Value': new FormControl(this.taxAndCharge.Value, Validators.required),
  //     'DeductionType': new FormControl(this.taxAndCharge.DeductionType, Validators.required),
  //     'IsActive': new FormControl(this.taxAndCharge.IsActive, Validators.required)
  //   });
  // }
}
