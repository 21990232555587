"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TreeNode = void 0;
var TreeNode = /** @class */ (function () {
    function TreeNode(value) {
        Object.assign(this, value);
    }
    return TreeNode;
}());
exports.TreeNode = TreeNode;
