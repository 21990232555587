"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectOptionsCollection = void 0;
var receipt_group_enum_1 = require("../enum/receipt-group.enum");
var order_status_enum_1 = require("../enum/order-status.enum");
var payment_status_enum_1 = require("../enum/payment-status.enum");
var payment_action_type_enum_1 = require("../enum/payment-action-type.enum");
var availability_type_enum_1 = require("../enum/availability-type.enum");
var SelectOptionsCollection = /** @class */ (function () {
    function SelectOptionsCollection() {
    }
    SelectOptionsCollection.paymentStatusOptions = [
        {
            lable: 'Payment Pending',
            value: payment_status_enum_1.PaymentStatusEnum.PAYMENT_PENDING
        },
        {
            lable: 'Hold',
            value: payment_status_enum_1.PaymentStatusEnum.HOLD
        },
        {
            lable: 'Failed',
            value: payment_status_enum_1.PaymentStatusEnum.FAILED
        },
        {
            lable: 'Partial Payment',
            value: payment_status_enum_1.PaymentStatusEnum.PARTIAL,
            disabled: true
        },
        {
            lable: 'Verifying',
            value: payment_status_enum_1.PaymentStatusEnum.VERIFYING
        },
        {
            lable: 'Refunded',
            value: payment_status_enum_1.PaymentStatusEnum.REFUNDED
        },
        {
            lable: 'Success',
            value: payment_status_enum_1.PaymentStatusEnum.SUCCESS
        },
    ];
    SelectOptionsCollection.orderStatusOptions = [
        {
            lable: 'Cart/Temporary',
            value: order_status_enum_1.OrderStatusEnum.CART
        },
        {
            lable: 'Enquire',
            value: order_status_enum_1.OrderStatusEnum.ENQUIRE
        },
        {
            lable: 'New',
            value: order_status_enum_1.OrderStatusEnum.NEW
        },
        {
            lable: 'Cancelled',
            value: order_status_enum_1.OrderStatusEnum.CANCELLED
        },
        {
            lable: 'Unavailable',
            value: order_status_enum_1.OrderStatusEnum.UNAVAILABLE
        },
        {
            lable: 'Packaging',
            value: order_status_enum_1.OrderStatusEnum.PACKAGING
        },
        {
            lable: 'Delivered',
            value: order_status_enum_1.OrderStatusEnum.DELIVERED
        },
        {
            lable: 'Completed',
            value: order_status_enum_1.OrderStatusEnum.COMPLETED
        },
    ];
    SelectOptionsCollection.receiptGroupOptions = [
        {
            lable: 'Invoice',
            value: receipt_group_enum_1.ReceiptGroupEnum.INVOICE
        },
        {
            lable: 'Delivery Challan',
            value: receipt_group_enum_1.ReceiptGroupEnum.DELIVERY_CHALLAN
        },
        {
            lable: 'Quotation',
            value: receipt_group_enum_1.ReceiptGroupEnum.QUOTATION
        },
        {
            lable: 'Purchase Order',
            value: receipt_group_enum_1.ReceiptGroupEnum.PURCHASE_ORDER
        },
        {
            lable: 'Credit Note / Sales Return',
            value: receipt_group_enum_1.ReceiptGroupEnum.CREDIT_NOTE
        }
    ];
    SelectOptionsCollection.paymentActionOptions = [
        {
            lable: 'To Pay',
            value: payment_action_type_enum_1.PaymentActionType.TO_PAY
        },
        {
            lable: 'To Receive',
            value: payment_action_type_enum_1.PaymentActionType.TO_RECEIVE
        },
    ];
    SelectOptionsCollection.stockModeOptions = [
        {
            lable: 'Unlimited',
            value: availability_type_enum_1.AvailabilityType.Unlimited
        },
        {
            lable: 'Stocked',
            value: availability_type_enum_1.AvailabilityType.Stocked
        },
        {
            lable: 'Out_of_stock',
            value: availability_type_enum_1.AvailabilityType.Out_Of_Stock
        }
    ];
    return SelectOptionsCollection;
}());
exports.SelectOptionsCollection = SelectOptionsCollection;
