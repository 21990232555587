"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseModel = void 0;
var BaseModel = /** @class */ (function () {
    function BaseModel(values) {
        if (values)
            Object.assign(this, values);
    }
    // To get an object without the _id property
    BaseModel.prototype.getCleanDBObject = function () {
        var obj = Object.assign({}, this);
        delete obj._id;
        return obj;
    };
    return BaseModel;
}());
exports.BaseModel = BaseModel;
