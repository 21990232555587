"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderStatusEnum = void 0;
var OrderStatusEnum;
(function (OrderStatusEnum) {
    OrderStatusEnum[OrderStatusEnum["DELETED"] = -1] = "DELETED";
    OrderStatusEnum[OrderStatusEnum["CART"] = 1] = "CART";
    OrderStatusEnum[OrderStatusEnum["NEW"] = 2] = "NEW";
    OrderStatusEnum[OrderStatusEnum["HOLD"] = 3] = "HOLD";
    OrderStatusEnum[OrderStatusEnum["CANCELLED"] = 4] = "CANCELLED";
    OrderStatusEnum[OrderStatusEnum["PACKAGING"] = 5] = "PACKAGING";
    OrderStatusEnum[OrderStatusEnum["UNAVAILABLE"] = 6] = "UNAVAILABLE";
    OrderStatusEnum[OrderStatusEnum["ENQUIRE"] = 7] = "ENQUIRE";
    OrderStatusEnum[OrderStatusEnum["DELIVERED"] = 8] = "DELIVERED";
    OrderStatusEnum[OrderStatusEnum["COMPLETED"] = 10] = "COMPLETED";
})(OrderStatusEnum = exports.OrderStatusEnum || (exports.OrderStatusEnum = {}));
