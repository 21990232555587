"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressModel = void 0;
var country_state_codes_constants_1 = require("../../constants/country-state-codes.constants");
var AddressModel = /** @class */ (function () {
    function AddressModel(value) {
        this.IsDeliverable = true;
        if (value) {
            Object.assign(this, value);
            if (this.CountryCode) {
                this.Country = country_state_codes_constants_1.CountryStateCodes.countryName(this.CountryCode);
                if (this.StateCode)
                    this.State = country_state_codes_constants_1.CountryStateCodes.stateName(this.CountryCode, this.StateCode);
            }
        }
    }
    return AddressModel;
}());
exports.AddressModel = AddressModel;
