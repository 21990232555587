<div>
	<div class="flxRowSt">
		<ng-container *ngFor="let file of previewFiles; let i = index">
			<div class="flxColStNw imagePreview">
				<div class="pb-1" *ngIf="!readOnly">
					<i class="far fa-times-circle float-right negativeIcon-sm" (click)="removeSavedFile(file.fileID)"></i>
				</div>
				<div (click)="onFileClicked(file)">
					<img *ngIf="uploadType == 'image'" [src]="file.filePath" (error)="onFileNotFound(i)" />
					<a *ngIf="uploadType == 'file'" [href]="file.filePath" target="_blank">{{getFileName(file.filePath)}}</a>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="!readOnly">
			<ng-container *ngFor="let uploadedFile of selectedFiles; let i = index">
				<div class="flxColStNw imagePreview">
					<div class="pb-1">
						<i class="far fa-times-circle float-right negativeIcon-sm" (click)="removeNewFile(i)"></i>
					</div>
					<div>
						<img *ngIf="uploadType == 'image'" [src]="uploadedFile.previewImgUrl" />
						<a *ngIf="uploadType == 'file'" [href]="uploadedFile.previewImgUrl" target="_blank"></a>
					</div>
				</div>
			</ng-container>
		</ng-container>
	</div>

	<ng-container *ngIf="(!hasAnyFile || multiple) && !readOnly">
		<button type="button" class="btn btn-outline-info btn-sm" (click)="fileInput.click()">
			{{ hasAnyFile ? 'Upload More' : buttonName}}
		</button>
		<input type="file" #fileInput style="display:none;" placeholder="Upload"
			(change)="updateFileChange(fileInput.files)" [accept]="allowedExtensions" [multiple]="multiple">
	</ng-container>

</div>