import { Directive, ElementRef, OnInit, Renderer2, Self } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName]'
})
export class AsteriskRequiredDirective implements OnInit {
  constructor(@Self() private control: NgControl,
    private renderer: Renderer2, private el: ElementRef) { }

  ngOnInit() {
    this.setAsterisk(this.control, this.el)
    this.control.statusChanges.subscribe((val) => {
      const ele = this.el;
      this.setAsterisk(this.control, ele)
    })
  }

  setAsterisk(control, el) {
    const ele = el.nativeElement;
    const validator = this.control.control.validator ? this.control.control.validator({} as AbstractControl) : '';
    if (validator && validator.required) {
      if (!ele.hasAttribute('no-required-asterisk')) {
        let id_ref = ele.id ?? "";
        let id = id_ref ? id_ref : control.name;
        let elem_style = ele.type == 'radio' ? 'top: 1rem' : 'top: 2rem'
        let asterisk = `<span class="required-asterisk " id='asterisk${id}' style='${elem_style}'>*</span>`;
        ele.insertAdjacentHTML('afterend', asterisk);
      }
    } else {
      let id_ref = ele.id ?? "";
      let id = id_ref ? id_ref : control.name;
      let doc = document.querySelectorAll(`#asterisk${id}`);
      if (doc) {
        doc.forEach((x, i) => { return x.remove() })
      }
    }
  }
}
