"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReceiptGroupEnum = void 0;
var ReceiptGroupEnum;
(function (ReceiptGroupEnum) {
    ReceiptGroupEnum[ReceiptGroupEnum["INVOICE"] = 1] = "INVOICE";
    ReceiptGroupEnum[ReceiptGroupEnum["QUOTATION"] = 2] = "QUOTATION";
    ReceiptGroupEnum[ReceiptGroupEnum["PURCHASE_ORDER"] = 3] = "PURCHASE_ORDER";
    ReceiptGroupEnum[ReceiptGroupEnum["DELIVERY_CHALLAN"] = 5] = "DELIVERY_CHALLAN";
    ReceiptGroupEnum[ReceiptGroupEnum["CREDIT_NOTE"] = 7] = "CREDIT_NOTE";
})(ReceiptGroupEnum = exports.ReceiptGroupEnum || (exports.ReceiptGroupEnum = {}));
