"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentCategoryConstants = void 0;
var enum_1 = require("../enum");
var money_flow_type_enum_1 = require("../enum/money-flow-type.enum");
var payment_category_model_1 = require("../models/payments/payment-category.model");
var PaymentCategoryConstants = /** @class */ (function () {
    function PaymentCategoryConstants() {
    }
    PaymentCategoryConstants.Money_In_Master_Categories = [
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyInMasterCategoryType.Sales,
            IsFunctional: true,
            Name: "Sales",
            Desc: "Sales done in the business.",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_IN,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyInMasterCategoryType.Purchase_Return,
            IsFunctional: true,
            Name: "Purchase Return",
            Desc: "Purchase return done in the business.",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_IN,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyInMasterCategoryType.Investment,
            IsFunctional: true,
            Name: "Investment",
            Desc: "Investments made into the business",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_IN,
            IsActive: true
        })
    ];
    PaymentCategoryConstants.Money_Out_Master_Categories = [
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Purchase,
            IsFunctional: true,
            Name: "Purchase",
            Desc: "Purchase of items/raw materials in to the business.",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Expense,
            IsFunctional: true,
            Name: "Expense",
            Desc: "Expense of the business (like Salary, Rent etc.).",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Sales_Return,
            IsFunctional: true,
            Name: "Sales Return",
            Desc: "Amount returned for sales return order.",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Loan_Given,
            IsFunctional: true,
            Name: "Loan Given",
            Desc: "You are giving credit/loan to a party",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Repaying_Loan,
            IsFunctional: true,
            Name: "Repaying Loan",
            Desc: "You are paying back the borrowed credit/loan to party",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
        new payment_category_model_1.PaymentCategoryModel({
            CategoryID: enum_1.MoneyOutMasterCategoryType.Refund,
            IsFunctional: true,
            Name: "Refund",
            Desc: "Refund on cancelled/damaged/lost order",
            CategoryType: money_flow_type_enum_1.MoneyFlowType.MONEY_OUT,
            IsActive: true
        }),
    ];
    return PaymentCategoryConstants;
}());
exports.PaymentCategoryConstants = PaymentCategoryConstants;
