"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MoneyInMasterCategoryType = void 0;
var MoneyInMasterCategoryType;
(function (MoneyInMasterCategoryType) {
    MoneyInMasterCategoryType[MoneyInMasterCategoryType["Investment"] = 1] = "Investment";
    MoneyInMasterCategoryType[MoneyInMasterCategoryType["Sales"] = 3] = "Sales";
    MoneyInMasterCategoryType[MoneyInMasterCategoryType["Purchase_Return"] = 5] = "Purchase_Return";
    MoneyInMasterCategoryType[MoneyInMasterCategoryType["Loan_Taken"] = 7] = "Loan_Taken";
    MoneyInMasterCategoryType[MoneyInMasterCategoryType["Party_Repay_Loan"] = 9] = "Party_Repay_Loan";
})(MoneyInMasterCategoryType = exports.MoneyInMasterCategoryType || (exports.MoneyInMasterCategoryType = {}));
