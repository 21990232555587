/*** Framework ***/
import { Injectable } from '@angular/core';

/*** Third Party Libraries ***/
import * as FileSaver from 'file-saver';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { DataValidation, Workbook, Worksheet } from 'exceljs';
import { ExcelHeaderDisplayConfigModel, ExcelModel, Utilities } from 'eccommons';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({ providedIn: 'root' })
export class ExcelService {
	constructor() { }

	public async exportAsExcelFile(excelData: ExcelModel): Promise<void> {
		const workbook = new ExcelJS.Workbook();

		excelData.WorkSheets.forEach(workSheet => {
			const sheet = workbook.addWorksheet(workSheet.Name);
			sheet.columns = workSheet.columnHeaders.map((x) => ({ header: x, key: x }));
			sheet.addRows(workSheet.Data);
		});

		const buffer = await workbook.xlsx.writeBuffer();
		this.saveAsExcelFile(buffer, excelData.FileName);
	}

	public writeDataToExcel(workbook: Workbook, data: Array<any>): void {

	}

	public saveAsExcelFile(buffer: any, fileName: string): void {
		const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
		FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
	}

	public populateHeader(worksheet: Worksheet, displayConfig: Array<ExcelHeaderDisplayConfigModel>, level: number = 1): void {
		this.loadHeaderRow(worksheet, displayConfig, level);

		if (displayConfig.some(x => x.Children && x.Children.length > 0)) {
			let nextLevel = level + 1;
			let nextLevelConfig = displayConfig.map(x => x.Children).filter(x => x && x.length > 0).reduce((a, b) => a.concat(b), []);
			this.populateHeader(worksheet, nextLevelConfig, nextLevel);
		}
	}

	public loadHeaderRow(worksheet: Worksheet, displayConfig: Array<ExcelHeaderDisplayConfigModel>, level: number): void {
		let rowHeaders = [];

		displayConfig.forEach(col => {
			rowHeaders.push(col.Name ?? '');

			if (col.Children && col.Children.length > 1) {
				for (let i = 0; i < col.Children.length - 1; i++) {
					rowHeaders.push('');
				}
			}
		});

		if (rowHeaders.length > 0) {		
			let lastColIndex = 0;
			// Merge & Fill Cells
			displayConfig.forEach(col => {
				if (col.Children && col.Children.length > 1) {
					let cellRange = `${ExcelService.getExcelAlphabetByPosition(lastColIndex + 1)}${level}:${ExcelService.getExcelAlphabetByPosition(lastColIndex + col.Children.length)}${level}`;
					// Value
					worksheet.getCell(cellRange).value = col.Name;
					// Merge
					worksheet.mergeCells(cellRange);
					// Fill
					if (col.FgColor) {
						worksheet.getCell(cellRange).fill = {
							type: 'pattern',
							pattern: 'solid',
							fgColor: { argb: col.FgColor }
						};
						worksheet.getCell(cellRange).alignment = {
							vertical: 'middle', horizontal: 'center'
						};
					}
					lastColIndex += col.Children?.length ?? 1;
				}
				else {
					let cellInfo = `${ExcelService.getExcelAlphabetByPosition(lastColIndex + 1)}${level}`;
					// Value
					worksheet.getCell(cellInfo).value = col.Name;
					// Fill
					if (col.FgColor)
						worksheet.getCell(`${cellInfo}`).fill = {
							type: 'pattern',
							pattern: 'solid',
							fgColor: { argb: col.FgColor }
						};						
					lastColIndex++;
				}
			});
		}
	}

	public setValidations(worksheet: Worksheet, displayConfig: Array<ExcelHeaderDisplayConfigModel>, startRowNo: number = 2, rowsCount: number = 250): void {
		displayConfig.forEach((col, index) => {
			if (col.DataValidation) {
				let colName = ExcelService.getExcelAlphabetByPosition(index + 1);
				for (let i = startRowNo; i < (startRowNo + rowsCount); i++) {
					worksheet.getCell(`${colName}${i}`).dataValidation = (col.DataValidation) as DataValidation;
				}
			}
		});
	}

	/**
	 * To get Excel Alphabet by position
	 * @param position on which alphabet is required. It is 1 based index.
	 * If position is greater than 26, it will return alphabet in excel format. 27 will return AA, 28 will return AB and so on.
	 * @returns Excel Alphabet at the given position
	 */
	public static getExcelAlphabetByPosition(position: number): string {
		if (!position || position < 1)
			return null;

		if (position / 26 > 1) {
			return `${Utilities.getAlphabetByPosition(Math.floor(position / 26))}${Utilities.getAlphabetByPosition(position % 26)}`;
		}
		return Utilities.getAlphabetByPosition(position);
	}
}