"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderModel = void 0;
var orderItem_1 = require("./orderItem");
var tax_model_1 = require("../tax/tax.model");
var order_status_enum_1 = require("../../enum/order-status.enum");
var utils_1 = require("../../utils");
var enum_1 = require("../../enum");
var OrderModel = /** @class */ (function () {
    function OrderModel(value) {
        var _a, _b;
        this.isSelected = false;
        if (value)
            Object.assign(this, value);
        this.OrderItems = (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.map(function (oi) { return new orderItem_1.OrderItemModel(oi); });
        this.DropLocationID = utils_1.Utilities.getValueOrNull(this.DropLocationID);
        this.ImmutableOrderStatusID = this.OrderStatusID;
        this.ImmutablePaymentStatusID = this.PaymentStatusID;
        this.TotalPayable = utils_1.Utilities.parseNumber(this.TotalPayable, 2);
        this.PaymentBalance = utils_1.Utilities.parseNumber(this.PaymentBalance, 2);
        this.TotalReceivedAmount = utils_1.Utilities.parseNumber(this.TotalReceivedAmount, 2);
        this.IsPaymentScreenShotUploaded = utils_1.Utilities.parseBoolean(this.IsPaymentScreenShotUploaded);
        this.CreditNotes = (_b = this.ReferringOrders) === null || _b === void 0 ? void 0 : _b.filter(function (o) { return o.ReceiptGroup == enum_1.ReceiptGroupEnum.CREDIT_NOTE && o.IsActiveOrder; });
        this.CreatedDateTime = utils_1.Utilities.convertUTCToLocalDate(String(this.CreatedDateTime));
        this.UpdatedDateTime = utils_1.Utilities.convertUTCToLocalDate(String(this.UpdatedDateTime));
    }
    Object.defineProperty(OrderModel.prototype, "IsOrderSaved", {
        get: function () {
            return this.OrderID > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsWebOrder", {
        get: function () {
            return this.OrderModeID == enum_1.OrderMode.WEB_ORDER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsAdminOrder", {
        get: function () {
            return this.OrderModeID == enum_1.OrderMode.ADMIN_ORDER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "hasItems", {
        get: function () {
            return this.OrderItems && this.OrderItems.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "hasAnyItemCode", {
        get: function () {
            return this.OrderItems && this.OrderItems.some(function (oi) { return oi.Code; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "hasAnyHSNSCNCode", {
        get: function () {
            return this.OrderItems && this.OrderItems.some(function (oi) { return oi.HSN_SCN_Code; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsPartiallyPaid", {
        get: function () {
            return this.TotalReceivedAmount > 0 && this.TotalReceivedAmount < this.TotalPayable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsFullyPaid", {
        get: function () {
            return this.TotalReceivedAmount > 0 && this.TotalReceivedAmount == this.TotalPayable;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsOrderComplete", {
        get: function () {
            return this.OrderStatusID == order_status_enum_1.OrderStatusEnum.COMPLETED;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "TotalItems", {
        get: function () {
            var _a;
            return (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "TotalQty", {
        get: function () {
            var totalQty = 0;
            this.OrderItems.forEach(function (oi) {
                totalQty += oi.Count;
            });
            return totalQty;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "ItemsWithPriceDifference", {
        get: function () {
            var _a;
            return (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.filter(function (oi) { return oi.Count && oi.IsPriceDifferent; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsSalesReturnAllowed", {
        get: function () {
            return this.IsOrderSaved && (this.ReceiptGroup == enum_1.ReceiptGroupEnum.INVOICE || this.ReceiptGroup == enum_1.ReceiptGroupEnum.DELIVERY_CHALLAN);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsCreditNote", {
        get: function () {
            return this.ReceiptGroup == enum_1.ReceiptGroupEnum.CREDIT_NOTE;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsActiveOrder", {
        get: function () {
            return (this.OrderStatusID != order_status_enum_1.OrderStatusEnum.DELETED && this.OrderStatusID != order_status_enum_1.OrderStatusEnum.CART && this.OrderStatusID != order_status_enum_1.OrderStatusEnum.CANCELLED
                && this.OrderStatusID != order_status_enum_1.OrderStatusEnum.UNAVAILABLE && this.OrderStatusID != order_status_enum_1.OrderStatusEnum.ENQUIRE);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "HasCreditNotes", {
        get: function () {
            return this.CreditNotes && this.CreditNotes.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsPurchaseOrder", {
        get: function () {
            return this.ReceiptGroup && this.ReceiptGroup == enum_1.ReceiptGroupEnum.PURCHASE_ORDER;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "OrderItemCount", {
        get: function () {
            var _a;
            return (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "IsWot", {
        get: function () {
            return this.ReceiptGroup == enum_1.ReceiptGroupEnum.DELIVERY_CHALLAN;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(OrderModel.prototype, "unavailableItems", {
        get: function () {
            var _a;
            return (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.filter(function (oi) {
                /* If it's a saved order and available qty is zero, then it is out of stock because of this order only.
                 So, don't show it as unavailable.
                 */
                if (oi.OrderID && (oi.AvailableQty == 0 || !oi.Count))
                    return false;
                return oi.AvailablilityType == enum_1.AvailabilityType.Out_Of_Stock;
            });
        },
        enumerable: false,
        configurable: true
    });
    OrderModel.prototype.computeBill = function () {
        var _this = this;
        var _a;
        this.TotalTaxableAmount = 0;
        this.TotalTax = 0;
        this.TotalPayable = 0;
        (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.forEach(function (oi) {
            var _a, _b;
            oi.computePriceAndTax();
            _this.TotalTaxableAmount += oi.Price;
            _this.TotalTax += (_b = (_a = oi.Tax) === null || _a === void 0 ? void 0 : _a.TaxToPay) !== null && _b !== void 0 ? _b : 0;
        });
        this.TotalPayable = this.TotalTaxableAmount + this.TotalTax;
    };
    OrderModel.prototype.computeTaxSplit = function (taxes) {
        var _a, _b;
        if (taxes) {
            var appliedTaxes_1 = new Array();
            // Order Items tax split
            (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.forEach(function (oi) {
                var tax = taxes.find(function (tx) { return tx.TaxID === oi.TaxID && tx.Value > 0; });
                if (tax) {
                    var appliedTax = appliedTaxes_1.find(function (atx) { return atx.TaxID === tax.TaxID; });
                    // If already similar tax applied for another item in the list
                    if (appliedTax) {
                        appliedTax.TaxableAmount += utils_1.Utilities.parseNumber(oi.Price);
                        appliedTax.computeTaxation();
                    }
                    else {
                        appliedTax = new tax_model_1.TaxModel(tax);
                        appliedTax.TaxableAmount = utils_1.Utilities.parseNumber(oi.Price);
                        appliedTax.computeTaxation();
                        appliedTaxes_1.push(appliedTax);
                    }
                }
            });
            //Compute tax for Additional Order Items
            (_b = this.AdditionalOrderItems) === null || _b === void 0 ? void 0 : _b.filter(function (aoi) { return aoi.TaxID > 0; }).forEach(function (aoi) {
                var tax = taxes.find(function (tx) { return tx.TaxID === aoi.TaxID; });
                if (tax) {
                    var appliedTax = appliedTaxes_1.find(function (atx) { return atx.TaxID === tax.TaxID; });
                    if (appliedTax) {
                        appliedTax.TaxableAmount += utils_1.Utilities.parseNumber(aoi.Amount);
                        appliedTax.computeTaxation();
                    }
                    else {
                        appliedTax = new tax_model_1.TaxModel(tax);
                        appliedTax.TaxableAmount = utils_1.Utilities.parseNumber(aoi.Amount);
                        appliedTax.computeTaxation();
                        appliedTaxes_1.push(appliedTax);
                    }
                }
            });
            appliedTaxes_1.sort(function (a, b) { return Number(a.Value) - Number(b.Value); });
            //keep it in Order
            this.Taxes = appliedTaxes_1;
        }
    };
    OrderModel.prototype.computeTotalSavings = function () {
        var _a;
        var savedAmount = 0;
        (_a = this.OrderItems) === null || _a === void 0 ? void 0 : _a.forEach(function (oi) {
            var itemSavings = oi.computeTotalSavings();
            if (itemSavings > 0)
                savedAmount += itemSavings;
        });
        this.TotalSavings = savedAmount;
        return this.TotalSavings;
    };
    /**
     * Sets the total received amount
     * @param totalReceivedAmount Total Received Amount
     */
    OrderModel.prototype.setReceivedAmount = function (totalReceivedAmount) {
        this.TotalReceivedAmount = utils_1.Utilities.parseNumber(totalReceivedAmount);
        this.computePaymentBalance();
    };
    /**
     * Add amount to received amount
     * @param amount new received amount
     */
    OrderModel.prototype.addReceivedAmount = function (amount) {
        this.TotalReceivedAmount = this.TotalReceivedAmount + utils_1.Utilities.parseNumber(amount);
        this.computePaymentBalance();
    };
    /**
     * update Order Item
     * @param orderItem Order Item
     */
    OrderModel.prototype.saveOrderItem = function (orderItem) {
        if (!orderItem)
            return;
        orderItem = new orderItem_1.OrderItemModel(orderItem);
        var index = this.OrderItems.findIndex(function (oi) { return oi.ItemVarietyID === orderItem.ItemVarietyID && oi.UnitLevel === orderItem.UnitLevel; });
        // If order item count is zero and order is not saved yet, remove the order item from the list
        if (!orderItem.Count && !this.OrderID) {
            if (index > -1) {
                this.OrderItems.splice(index, 1);
                this.computeBill();
            }
            return;
        }
        // If it's an saved orderitem, update the order item with zero to reflect the change in DB.
        if (index > -1) {
            this.OrderItems[index] = orderItem;
        }
        else {
            this.OrderItems.push(orderItem);
        }
        this.computeBill();
    };
    /***** Private ******/
    OrderModel.prototype.computePaymentBalance = function () {
        console.log('compuing payment balance');
        this.PaymentBalance = this.TotalPayable - this.TotalReceivedAmount - this.TotalReturnAmount;
    };
    return OrderModel;
}());
exports.OrderModel = OrderModel;
