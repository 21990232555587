"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeductionBaseModel = void 0;
var enum_1 = require("../../enum");
var DeductionBaseModel = /** @class */ (function () {
    function DeductionBaseModel(value) {
        Object.assign(this, value);
    }
    DeductionBaseModel.prototype.computeDeduction = function (inputValue) {
        if (!inputValue || !this.Value)
            return null;
        if (this.DeductionType === enum_1.DeductionType.PERCENT)
            return inputValue * (this.Value / 100);
        else
            return this.Value;
    };
    return DeductionBaseModel;
}());
exports.DeductionBaseModel = DeductionBaseModel;
