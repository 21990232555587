"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderItemModel = void 0;
var enum_1 = require("../../enum");
var utils_1 = require("../../utils");
var item_1 = require("../item");
var tax_1 = require("../tax");
var OrderItemModel = /** @class */ (function (_super) {
    __extends(OrderItemModel, _super);
    function OrderItemModel(value) {
        var _this = _super.call(this, value) || this;
        _this.IsUnitPriceChanged = false;
        if (value)
            Object.assign(_this, value);
        _this.OrderID = utils_1.Utilities.parseNumber(_this.OrderID);
        _this.FullName = _this.ItemName;
        if (_this.VarietyName)
            _this.FullName = _this.FullName.concat('-', _this.VarietyName);
        _this.UnitPrice = utils_1.Utilities.parseNumber(_this.UnitPrice, 3);
        _this.CurrentPrice = utils_1.Utilities.parseNumber(_this.CurrentPrice, 3);
        if (!_this.CurrentPrice)
            _this.CurrentPrice = _this.UnitPrice;
        _this.AllowCustomValue = utils_1.Utilities.isValueTruthy(_this.AllowCustomValue);
        _this.Count = utils_1.Utilities.parseNumber(_this.Count);
        _this.AvailableQty = utils_1.Utilities.parseNumber(_this.AvailableQty);
        _this.AvailablilityType = utils_1.Utilities.getAvailabilityType(value.AvailableQty);
        if (!_this.MRP && _this.HasDiscount) {
            _this.MRP = utils_1.Utilities.computeMarketPrice(_this.UnitPrice, _this.DiscountType, _this.DiscountValue);
        }
        // Tax
        if (_this.TaxDeductionType && _this.TaxValue) {
            _this.Tax = new tax_1.TaxModel({
                DeductionType: _this.TaxDeductionType,
                Value: _this.TaxValue,
                Name: _this.TaxName,
                TaxableAmount: _this.Price
            });
        }
        if (!_this.Price)
            _this.computePriceAndTax();
        return _this;
    }
    Object.defineProperty(OrderItemModel.prototype, "IsPriceDifferent", {
        get: function () {
            return this.CurrentPrice && this.CurrentPrice != this.UnitPrice;
        },
        enumerable: false,
        configurable: true
    });
    OrderItemModel.prototype.updateUnitPrice = function (newUnitPrice) {
        this.UnitPrice = utils_1.Utilities.parseNumber(newUnitPrice, 3);
        this.IsUnitPriceChanged = true;
        this.computePriceAndTax();
    };
    OrderItemModel.prototype.computePriceAndTax = function () {
        if (this.Count && this.UnitPrice)
            this.Price = this.Count * this.UnitPrice;
        else {
            this.Price = 0;
            return;
        }
        if (this.Tax) {
            this.Tax.TaxableAmount = this.Price;
            this.Tax.computeTaxation();
        }
    };
    OrderItemModel.prototype.updateCount = function (newCount) {
        // Increase or Decrease local availability count only when stocked    
        if (this.AvailablilityType == enum_1.AvailabilityType.Stocked) {
            if (this.Count > newCount)
                this.increaseAvailableQty(this.Count - newCount);
            else
                this.reduceAvailableQty(newCount - this.Count);
        }
        this.Count = newCount;
    };
    OrderItemModel.prototype.reduceAvailableQty = function (qty) {
        qty = utils_1.Utilities.parseNumber(qty);
        if (qty && this.AvailableQty)
            this.AvailableQty = this.AvailableQty - qty;
    };
    OrderItemModel.prototype.increaseAvailableQty = function (qty) {
        qty = utils_1.Utilities.parseNumber(qty);
        if (qty)
            this.AvailableQty = this.AvailableQty + qty;
    };
    /**
     * If MRP is present then return that else if discount is present then
     * compute market price from discount
     * @returns mrp price
     */
    OrderItemModel.prototype.computeTotalSavings = function () {
        if (!this.MRP)
            return 0;
        return utils_1.Utilities.parseNumber((this.MRP - this.UnitPrice) * this.Count, 2) || 0;
    };
    return OrderItemModel;
}(item_1.ItemVarietyBaseModel));
exports.OrderItemModel = OrderItemModel;
