/* Angular */
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DatePipe } from '@angular/common';

/* Modules */
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxPrintModule } from 'ngx-print';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { DirectivesModule } from './helpers/directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';

/* Components */
import { AppComponent } from './app.component';
import { LoginComponent } from './account/login.component';
import { TaxAndChargesComponent } from './admin/tax-and-charges/tax-and-charges.component';
import { SaveTaxChargeComponent } from './admin/tax-and-charges/save-tax-charge/save-tax-charge.component';
import { BannerComponent } from './admin/banner/banner.component';
import { SaveBannerComponent } from './admin/banner/save-banner/save-banner.component';
import { AdminComponent } from './admin/admin.component'
import { SaveCategoryComponent } from './admin/save-category/save-category.component';
import { StocksComponent } from './admin/stocks/stocks.component';
import { CustomerMessageComponent } from './customer-message/customer-message.component';
import { ItemBulkUploadComponent } from './admin/bulk-upload/item-bulk-upload/item-bulk-upload.component';
import { CategoryDetailsComponent } from './admin/category-details/category-details.component';

/* Pipe */
import { CustomCurrencyPipe } from './shared/pipe/custom-currency.pipe';


/* Helpers */
import { JwtInterceptor, ErrorInterceptor } from '../app/helpers/';
import { ErrorHandlerService } from './helpers/error.handler.service';
import { PasswordSetComponent } from './account/password-set/password-set.component';

@NgModule({
  declarations: [
    //Components
    AppComponent,
    AdminComponent,
    SaveCategoryComponent,
    CategoryDetailsComponent,
    LoginComponent,
    TaxAndChargesComponent,
    SaveTaxChargeComponent,
    BannerComponent,
    SaveBannerComponent,
    StocksComponent,
    CustomerMessageComponent,
    ItemBulkUploadComponent,
    PasswordSetComponent     
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    HttpClientModule,
    RouterModule,
    LayoutModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    SharedModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxPrintModule,
    DirectivesModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    DatePipe,
    CustomCurrencyPipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
