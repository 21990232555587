"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemBulkUploadModel = void 0;
var enum_1 = require("../../enum");
var utilities_1 = require("../../utils/utilities");
var ItemBulkUploadModel = /** @class */ (function () {
    function ItemBulkUploadModel(value) {
        this.ErrorMessages = new Array();
        Object.assign(this, value);
        if (value) {
            // Transformation      
            if (typeof (this.GST_Type) == 'string') {
                this.GST_TypeID = utilities_1.Utilities.stringToEnum(enum_1.ItemGSTType, this.GST_Type);
            }
            if (this.GST_TypeID) {
                this.GST_Type = utilities_1.Utilities.valueToEnumName(enum_1.ItemGSTType, this.GST_TypeID);
            }
            if (typeof (this.PrimaryUnit) == 'string') {
                this.PrimaryUnitCode = utilities_1.Utilities.stringToEnum(enum_1.UnitType, this.PrimaryUnit);
            }
            if (typeof (this.SecondaryUnit) == 'string') {
                this.SecondaryUnitCode = utilities_1.Utilities.stringToEnum(enum_1.UnitType, this.SecondaryUnit);
            }
            if (typeof (this.Sold_In) == 'string') {
                if (this.Sold_In.toLowerCase().includes('primary'))
                    this.SellingUnit = enum_1.UnitLevel.PrimaryUnit;
                else if (this.Sold_In.toLowerCase().includes('secondary'))
                    this.SellingUnit = enum_1.UnitLevel.SecondaryUnit;
                else if (this.Sold_In.toLowerCase().includes('both'))
                    this.SellingUnit = enum_1.UnitLevel.All;
            }
            if (typeof (this.DiscountType) == 'string') {
                this.DiscountType = utilities_1.Utilities.stringToEnum(enum_1.DeductionType, this.DiscountType);
            }
            this.PurchasePrice = utilities_1.Utilities.parseNumber(this.PurchasePrice);
            this.DiscountValue = utilities_1.Utilities.parseNumber(this.DiscountValue);
            // this.DiscountDesc = Utilities.discountDesc(this.YourSellingPrice, this.DiscountType, this.DiscountValue, this.MarketPrice);
            // Primary Unit
            this.PrimaryUnit = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.PrimaryUnitCode);
            // Secondary Unit
            this.SecondaryUnit = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, this.SecondaryUnitCode);
            // Selling Unit 
            if (this.SellingUnit) {
                if (this.SellingUnit == enum_1.UnitLevel.PrimaryUnit)
                    this.Sold_In = 'PRIMARY';
                else if (this.SellingUnit == enum_1.UnitLevel.SecondaryUnit)
                    this.Sold_In = 'SECONDARY';
                else if (this.SellingUnit == enum_1.UnitLevel.All)
                    this.Sold_In = 'BOTH';
            }
            // Stock Availability Type
            this.AvailableQty = utilities_1.Utilities.getAvailableQty(this.StockDisplayName);
            this.StockAvailabilityType = utilities_1.Utilities.getAvailabilityType(this.AvailableQty);
            // Stock Unit
            if (this.StockUnit) {
                if (this.PrimaryUnit && this.PrimaryUnit.toLowerCase() == this.StockUnit.toLowerCase())
                    this.AvailabilityUnit = enum_1.UnitLevel.PrimaryUnit;
                else if (this.SecondaryUnit && this.SecondaryUnit.toLowerCase() == this.StockUnit.toLowerCase())
                    this.AvailabilityUnit = enum_1.UnitLevel.SecondaryUnit;
            }
            else {
                if (this.AvailabilityUnit == enum_1.UnitLevel.PrimaryUnit) {
                    this.StockUnit = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, value.PrimaryUnitCode);
                }
                else if (this.AvailabilityUnit == enum_1.UnitLevel.SecondaryUnit) {
                    this.StockUnit = utilities_1.Utilities.valueToEnumName(enum_1.UnitType, value.SecondaryUnitCode);
                }
            }
        }
    }
    Object.defineProperty(ItemBulkUploadModel.prototype, "ErrorCount", {
        get: function () {
            var _a;
            return (_a = this.ErrorMessages) === null || _a === void 0 ? void 0 : _a.length;
        },
        enumerable: false,
        configurable: true
    });
    ItemBulkUploadModel.prototype.getError = function (field) {
        var _a;
        if (this.ErrorMessages && this.ErrorMessages.length > 0)
            return (_a = this.ErrorMessages.find(function (x) { return x.field == field; })) === null || _a === void 0 ? void 0 : _a.msg;
        return null;
    };
    ItemBulkUploadModel.prototype.savePriceProfile = function (priceProfile) {
        if (!this.PriceProfiles)
            this.PriceProfiles = new Array();
        var existing = this.PriceProfiles.find(function (x) { return x.PartyProfileID == priceProfile.PartyProfileID; });
        if (existing) {
            existing = priceProfile;
        }
        else {
            this.PriceProfiles.push(priceProfile);
        }
    };
    ItemBulkUploadModel.prototype.addErrorMessage = function (field, msg) {
        if (!this.ErrorMessages)
            this.ErrorMessages = new Array();
        var existing = this.ErrorMessages.find(function (x) { return x.field == field; });
        if (existing) {
            existing.msg = msg;
        }
        else {
            this.ErrorMessages.push({ field: field, msg: msg });
        }
    };
    return ItemBulkUploadModel;
}());
exports.ItemBulkUploadModel = ItemBulkUploadModel;
