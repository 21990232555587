"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminUser = void 0;
var utils_1 = require("../../utils");
var user_access_1 = require("./user-access");
var AdminUser = /** @class */ (function () {
    function AdminUser(value) {
        if (value) {
            if (value['AccessList'] && typeof (value['AccessList']) == 'string') {
                value['AccessList'] = JSON.parse(String(value['AccessList'])).map(function (al) { return new user_access_1.UserAccess(al); });
            }
        }
        Object.assign(this, value);
    }
    Object.defineProperty(AdminUser.prototype, "IsLoggedIn", {
        get: function () {
            return this.AdminUserID > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AdminUser.prototype, "ProfileUITitle", {
        get: function () {
            return this.Name ? this.Name.charAt(0).toUpperCase() : 'G';
        },
        enumerable: false,
        configurable: true
    });
    AdminUser.prototype.hasAccess = function (route) {
        return this.AccessList.findIndex(function (al) { return utils_1.Utilities.isStringsEqual(al.route, route) ||
            utils_1.Utilities.isStringsEqual(al.route, 'all'); }) > -1;
    };
    return AdminUser;
}());
exports.AdminUser = AdminUser;
