"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemModel = void 0;
var item_variety_1 = require("./item-variety");
var item_type_enum_1 = require("../../enum/item-type.enum");
var enum_1 = require("../../enum");
var utils_1 = require("../../utils");
var ItemModel = /** @class */ (function () {
    function ItemModel(value) {
        var _this = this;
        // Tax
        this.GstType = enum_1.ItemGSTType.Product;
        Object.assign(this, value);
        if (value) {
            if (this.Variety && Array.isArray(this.Variety) && this.Variety.length > 0) {
                //set DisplayName & Price for item variety
                this.Variety = this.Variety.map(function (iv) {
                    // Set DisplayName 
                    iv.DisplayName = "".concat(_this.Name, " ").concat(iv.Name ? iv.Name : '');
                    return new item_variety_1.ItemVarietyModel(iv);
                });
                if (this.Variety.length > 1) {
                    // To find the first item that is available/few items left else set the first as Display variety.
                    var displayItemVariety = this.Variety.find(function (iv) { return iv.AvailablilityType !== enum_1.AvailabilityType.Out_Of_Stock; });
                    if (!displayItemVariety)
                        displayItemVariety = this.Variety[0];
                    this.DisplayVariety = displayItemVariety;
                }
                else {
                    this.Variety = this.Variety[0];
                    this.DisplayVariety = this.Variety;
                }
            }
            else {
                this.Variety = new item_variety_1.ItemVarietyModel(this.Variety);
            }
        }
        this.IsAvailable = utils_1.Utilities.parseBoolean(value['IsAvailable']);
        this.IsActive = utils_1.Utilities.parseBoolean(value['IsActive']);
    }
    Object.defineProperty(ItemModel.prototype, "ItemType", {
        get: function () {
            return Array.isArray(this.Variety) ? item_type_enum_1.ItemType.Multi : item_type_enum_1.ItemType.Single;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ItemModel.prototype, "NoOfVarieties", {
        get: function () {
            if (!this.Variety)
                return 0;
            return Array.isArray(this.Variety) ? this.Variety.length : 1;
        },
        enumerable: false,
        configurable: true
    });
    ItemModel.prototype.changeDisplayVariety = function (itemVarietyID) {
        if (this.ItemType === item_type_enum_1.ItemType.Multi) {
            this.DisplayVariety = this.Variety.find(function (iv) { return iv.ItemVarietyID == itemVarietyID; });
        }
    };
    ItemModel.prototype.getVarietiesList = function () {
        if (this.ItemType == item_type_enum_1.ItemType.Single)
            return [this.Variety];
        else if (this.ItemType == item_type_enum_1.ItemType.Multi)
            return this.Variety;
    };
    ItemModel.prototype.hasMatchingKeyWord = function (searchKey) {
        if (!searchKey || !this.KeyWords)
            return false;
        return this.KeyWords.toLowerCase().includes(searchKey.toLowerCase());
    };
    ItemModel.prototype.getVariety = function (itemVarietyID) {
        return this.getVarietiesList().find(function (iv) { return iv.ItemVarietyID == itemVarietyID; });
    };
    ItemModel.prototype.addVariety = function (newVariety) {
        if (!this.Variety) {
            this.Variety = newVariety;
        }
        else if (this.ItemType == item_type_enum_1.ItemType.Single) {
            this.Variety = [this.Variety, newVariety];
        }
        else if (this.ItemType == item_type_enum_1.ItemType.Multi) {
            this.Variety.push(newVariety);
        }
    };
    ItemModel.prototype.hasVariety = function (itemVarietyID) {
        var _a;
        return (_a = this.getVarietiesList()) === null || _a === void 0 ? void 0 : _a.some(function (iv) { return iv.ItemVarietyID == itemVarietyID; });
    };
    return ItemModel;
}());
exports.ItemModel = ItemModel;
