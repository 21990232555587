"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkSheetModel = exports.ExcelModel = void 0;
var ExcelModel = /** @class */ (function () {
    function ExcelModel(value) {
        Object.assign(this, value);
    }
    return ExcelModel;
}());
exports.ExcelModel = ExcelModel;
var WorkSheetModel = /** @class */ (function () {
    function WorkSheetModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(WorkSheetModel.prototype, "columnHeaders", {
        get: function () {
            if (this.Data && this.Data.length > 0)
                return Object.keys(this.Data[0]);
            else
                return [];
        },
        enumerable: false,
        configurable: true
    });
    return WorkSheetModel;
}());
exports.WorkSheetModel = WorkSheetModel;
