"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartyProfileModel = void 0;
var utils_1 = require("../../utils");
var PartyProfileModel = /** @class */ (function () {
    function PartyProfileModel(value) {
        Object.assign(this, value);
    }
    Object.defineProperty(PartyProfileModel.prototype, "DefaultForList", {
        get: function () {
            if (!this.DefaultFor)
                return [];
            return this.DefaultFor.split(",").map(function (x) { return x; });
        },
        enumerable: false,
        configurable: true
    });
    PartyProfileModel.prototype.isDefaultFor = function (type) {
        if (!type || !this.DefaultFor)
            return false;
        return utils_1.Utilities.hasKey(this.DefaultFor, type);
    };
    PartyProfileModel.prototype.addDefaultFor = function (type) {
        if (!type)
            return;
        if (!this.DefaultFor)
            this.DefaultFor = "";
        this.DefaultFor = this.DefaultForList.includes(type) ? this.DefaultFor : this.DefaultFor + "," + type;
    };
    PartyProfileModel.prototype.removeDefaultFor = function (type) {
        if (!type || !this.DefaultFor)
            return;
        this.DefaultFor = this.DefaultForList.filter(function (x) { return !utils_1.Utilities.isStringsEqual(x, type); }).join(",");
    };
    return PartyProfileModel;
}());
exports.PartyProfileModel = PartyProfileModel;
