"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PositionType = void 0;
var PositionType;
(function (PositionType) {
    PositionType["PAGE_TOP"] = "PAGE_TOP";
    PositionType["PAGE_BOTTOM"] = "PAGE_BOTTOM";
    PositionType["ORDER_TOP"] = "ORDER_TOP";
    PositionType["ORDER_BOTTOM"] = "ORDER_BOTTOM";
})(PositionType = exports.PositionType || (exports.PositionType = {}));
