import { Directive, ElementRef, HostListener, Input, OnChanges } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utilities } from 'eccommons';

@Directive({
  selector: '[appFitWidth]'
})
export class FitWidthDirective implements OnChanges {

  @Input() appFitWidth = 30;

  constructor(private el: ElementRef, private ngControl: NgControl) {
    this.listenToChanges();
  }

  private listenToChanges() {
    this.ngControl.valueChanges?.subscribe(() => {
      this.resize();
    })
  }
  @HostListener('input', ['$event']) onInputChange(event) {
    this.resize();
  }

  @HostListener('change', ['$event']) onChange(event) {
    this.resize();
  }

  @HostListener('focus') onFocus() {
    this.resize();
  }

  ngOnChanges() {
  }

  private resize() {
    let width = ((this.el.nativeElement.value.length + 1) * 8);
    if (width < this.appFitWidth)
      width = Utilities.parseNumber(this.appFitWidth);

    if (this.el.nativeElement.type == 'number')
      width += 15;
    this.el.nativeElement.style.width = width + 'px';
  }
}