import { Component, OnInit } from '@angular/core';
import { MessageModel, MessageStatusType } from 'eccommons';
import { AdminService } from '../admin/services/admin.service';

@Component({
  selector: 'app-customer-message',
  templateUrl: './customer-message.component.html',
  styleUrls: ['./customer-message.component.scss']
})
export class CustomerMessageComponent implements OnInit {

  customerMessages: Array<MessageModel>;
  messageType = MessageStatusType;
  isLoading: boolean = false;

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.adminService.getCustomerMessages().subscribe(res => {
      this.customerMessages = res;
    }, null, () => {
      this.isLoading = false;
    })
  }

  markAsRead(customerMessageID: string) {
    if (customerMessageID) {
      this.adminService.saveCustomerMessageStatus(customerMessageID, MessageStatusType.READ).subscribe(res => {
        if (res && res > 0) {
          this.customerMessages.find(cm => String(cm._id) == customerMessageID).Status = MessageStatusType.READ;
        }
      });
    }
  }

}
