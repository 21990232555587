import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Router, RouterStateSnapshot } from '@angular/router';
import { AccountService } from '../account/service/account.service';
import { SharedService } from '../shared/services/shared.service';
import { AlertType } from 'eccommons';

@Injectable()
export class OnlyLoggedInUsersGuard implements CanActivate, CanLoad {

  constructor(private accountService: AccountService, private sharedService: SharedService,
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.accountService.IsLoggedIn) {
      if (this.accountService.user.hasAccess(route.url[0].path))
        return true;
      else {
        this.sharedService.notify(AlertType.Error, 'Unauthorized Access');
        this.router.navigate([this.accountService.user.AccessList[0]?.route]);
        return false;
      }
    }
    else {
      this.askUserToLogin();
      return false;
    }
  }

  canLoad(): boolean {
    if (this.accountService.IsLoggedIn)
      return true;
    else {
      this.askUserToLogin();
      return false;
    }
  }

  private askUserToLogin(): void {
    this.sharedService.notify(AlertType.Warning, 'Please login to continue');
    this.router.navigate(['/login']);
  }
}
