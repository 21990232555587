import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BannerService } from '../service/banner.service';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BannerModel, AlertType, SelectOptionModel, FileModel, LinkType, Utilities } from 'eccommons';
import { AdminService } from '../../services/admin.service';
import { CDNPathPipe } from 'src/app/shared/pipe/cdnpath.pipe';
import { of } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-save-banner',
  templateUrl: './save-banner.component.html',
  styleUrls: ['./save-banner.component.scss']
})
export class SaveBannerComponent implements OnInit {

  frmBannerGroup: UntypedFormGroup;
  bannerInfo: BannerModel;
  allowedExtensions: string = ".png, .jpg, .jpeg";
  bannerImageFile: File;
  linkTypeOptions: Array<SelectOptionModel>;
  linkOptions: Array<SelectOptionModel>;
  previewImages: Array<FileModel>;

  constructor(private route: ActivatedRoute, private bannerService: BannerService, private fb: UntypedFormBuilder,
    private sharedService: SharedService, private router: Router, private adminService: AdminService,
    private cdnPathPipe: CDNPathPipe) {
    this.linkTypeOptions = [
      {
        lable: 'None',
        value: null
      },
      {
        lable: 'Category',
        value: LinkType.Category
      },
      {
        lable: 'Item',
        value: LinkType.Item
      }
    ];
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const bannerID = String(params['bannerID']) ?? undefined;
      if (Utilities.isValueTruthy(bannerID)) {
        if (this.bannerService.bannerToEdit && String(this.bannerService.bannerToEdit.BannerID) == bannerID) {
          this.bannerInfo = this.bannerService.bannerToEdit;
          this.initializeForm();
        }
        else {
          this.bannerService.getBannerDetailsById(bannerID).subscribe(res => {
            this.bannerInfo = res;
            this.initializeForm();
          });
        }
      }
      else {
        this.bannerInfo = new BannerModel();
        this.bannerInfo.IsActive = true;
        this.initializeForm();
      }
    });
  }

  onLinkTypeChange(event) {
    const value = event.target.value;
    this.loadLinkOptions(value);
  }

  onLinkOptionChange(event) {
    const value = event.target.value;
    const linkType = this.frmBannerGroup.get('LinkType').value;
    if (linkType == LinkType.Category) {
      this.frmBannerGroup.get('Link').setValue(`/item/${value}`);
    }
    else if (linkType == LinkType.Item) {
      this.frmBannerGroup.get('Link').setValue(`/item/description/${value}`);
    }
    else {
      this.frmBannerGroup.get('Link').setValue(null);
    }
  }

  onFileSelectFailed(msg) {
    this.sharedService.notify(AlertType.Warning, msg);
  }

  onFilesSelected(files: Array<File>) {
    this.bannerImageFile = files[0];
  }

  onFileRemove(id: number) {
  }

  saveBanner() {
    this.bannerInfo = this.frmBannerGroup.getRawValue() as BannerModel;
    this.bannerService.saveBanner(this.bannerInfo, this.bannerImageFile).subscribe(res => {
      this.sharedService.notify(AlertType.Success, 'Saved Successfully!');
      this.router.navigate(['/banner']);
    });
  }

  cancelSave(confirmWithUser: boolean = true, callBackFn?: Function) {
    let shallProceed = of(true);
    if (confirmWithUser) {
      shallProceed = this.sharedService.showConfirmDialog('Cancel Banner', 'Are you sure want to cancel the banner?')
        .pipe(take(1));
    }
    shallProceed.subscribe(res => {
      if (res) {
        window.history.back();
      }
    });
  }

  private initializeForm() {
    this.frmBannerGroup = this.fb.group({
      'BannerID': new UntypedFormControl(this.bannerInfo.BannerID),
      'Name': new UntypedFormControl(this.bannerInfo.Name, Validators.required),
      'ImagePath': new UntypedFormControl(this.bannerInfo.ImagePath),
      'LinkType': new UntypedFormControl(this.bannerInfo.LinkType),
      'LinkOptionValue': new UntypedFormControl(this.bannerInfo.LinkOptionValue),
      'Link': new UntypedFormControl(this.bannerInfo.Link),
      'DisplayOrder': new UntypedFormControl(this.bannerInfo.DisplayOrder),
      'IsActive': new UntypedFormControl(Boolean(Number(this.bannerInfo.IsActive))),
    });

    if (this.bannerInfo.LinkType)
      this.loadLinkOptions(this.bannerInfo.LinkType);

    if (this.bannerInfo.BannerID && this.bannerInfo.ImagePath) {
      this.previewImages = [new FileModel({
        fileID: String(this.bannerInfo.BannerID),
        filePath: this.cdnPathPipe.transform(this.bannerInfo.ImagePath)
      })
      ];
    }
  }

  private loadLinkOptions(value) {
    if (value == LinkType.Category) {
      this.adminService.getCategory().subscribe(res => {
        this.linkOptions = res.map(c => new SelectOptionModel({
          lable: c.Name,
          value: c.CategoryID
        })
        );
        this.linkOptions.unshift({
          lable: 'Please Select',
          value: null
        });
      });

    }
    else if (value == LinkType.Item) {
      this.adminService.getItems().subscribe(res => {
        this.linkOptions = res.map(i => new SelectOptionModel({
          lable: i.Name,
          value: i.ItemID
        })
        );
        this.linkOptions.unshift({
          lable: 'Please Select',
          value: null
        });
      });
    }
    else {
      this.linkOptions = [];
      this.frmBannerGroup.get('Link').setValue(null);
    }
    this.frmBannerGroup.get('LinkType').setValue(value);
  }
}
