import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'Highlight' })
export class HighlightPipe implements PipeTransform {

  transform(text: string, key: string): string {
    if (text && key) {
      key = key.toLowerCase();
      let index = text.toLowerCase().indexOf(key);
      if (index >= 0) {
        key = text.slice(index, index + key.length);
        text = text.replace(key, '<mark>' + key + '</mark>');
      }
    }
    return text;
  }

}