"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemType = void 0;
var ItemType;
(function (ItemType) {
    ItemType[ItemType["Single"] = 1] = "Single";
    ItemType[ItemType["Multi"] = 2] = "Multi";
    ItemType[ItemType["Custom"] = 3] = "Custom";
})(ItemType = exports.ItemType || (exports.ItemType = {}));
;
