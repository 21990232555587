<div>
  <div class="form-container shadowed-box-non-mobile section-content">
    <form [formGroup]="frmSaveCategory" *ngIf="frmSaveCategory">
      <fieldset class="box-shadow box-shadow-border w-100">
        <legend>Save Category</legend>

        <!-- CategoryID [hidden] -->
        <input type="hidden" class="form-control" placeholder="" formControlName="CategoryID"
          aria-describedby="categoryID">

        <div class="flxRowSB align-end">
          <!--  Name -->
          <div class="form-group">
            <input type="text" class="form-control" placeholder="ex: Groceries" formControlName="Name"
              aria-describedby="basic-addon1" maxlength="32">
            <span class="form__label">Category Name: </span>
          </div>

          <!--  IsActive -->
          <div class="form-check">
            <app-switch formControlName="IsActive"></app-switch>
          </div>
        </div>
        <div class="flxRowSB align-end">
          <!--  Description -->
          <div class="form-group">
            <textarea class="form-control" placeholder="ex: Collection of all daily cooking needs"
              formControlName="Description" aria-describedby="basic-addon1">
            </textarea>
            <span class="form__label">Description: </span>
          </div>

          <!--  ParentID -->
          <div class="form-group">
            <select class="form-control" formControlName="ParentID">
              <option *ngFor="let option of categoryOptions" [value]="option.value">{{option.lable}}
              </option>
            </select>
            <span class="form__label">Parent Category: </span>
          </div>
        </div>

        <div class="flxRowSB align-end">
          <!--  Image -->
          <div class="form-group">
            <app-ec-upload-file [allowedExtensions]="allowedExtensions" [previewFiles]="previewImages"
              (files)="onFilesSelected($event)" (fileSelectFailed)="onFileSelectFailed($event)"
              (fileRemoved)="onFileRemove($event)">
            </app-ec-upload-file>

            <span class="form__label">Upload Category Image: </span>
          </div>

          <!--  DisplayOrder -->
          <div class="form-group">
            <input type="number" class="form-control form-control-m" placeholder="ex: 1" formControlName="DisplayOrder"
              aria-describedby="basic-addon1" min="1" oninput="this.value =
              !!this.value && Math.abs(this.value) > 0 ? Math.abs(this.value) : null">
            <span class="form__label">Display Order: </span>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="flxRowNwEnd mt-4">
          <button type="button" class="btn btn-outline-dark" (click)="cancelSave()">Cancel</button>

          <button type="button" class="btn btn-primary" [disabled]="!frmSaveCategory.valid"
            (click)="saveCategory()">Save</button>
        </div>
      </fieldset>
    </form>
  </div>
</div>