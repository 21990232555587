"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BannerModel = void 0;
var enum_1 = require("../../enum");
var utils_1 = require("../../utils");
var BannerModel = /** @class */ (function () {
    function BannerModel(value) {
        Object.assign(this, value);
        this.IsActive = utils_1.Utilities.parseBoolean(this.IsActive);
        this.LinkType = this.getLinkType();
        this.LinkOptionValue = this.getLinkOptionValue();
    }
    BannerModel.prototype.getLinkType = function () {
        if (this.Link) {
            if (this.Link.indexOf('item/description') > -1)
                return enum_1.LinkType.Item;
            else if (this.Link.indexOf('item') > -1)
                return enum_1.LinkType.Category;
            else
                return null;
        }
        else
            return null;
    };
    BannerModel.prototype.getLinkOptionValue = function () {
        var linkType = this.LinkType;
        if (linkType) {
            return this.Link.slice(this.Link.lastIndexOf('/') + 1);
        }
        return null;
    };
    return BannerModel;
}());
exports.BannerModel = BannerModel;
