"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankAccountModel = void 0;
var enum_1 = require("../../enum");
var utils_1 = require("../../utils");
var BankAccountModel = /** @class */ (function () {
    function BankAccountModel(value, qrImgPath) {
        Object.assign(this, value);
        this.IsPrimary = utils_1.Utilities.parseBoolean(this.IsPrimary);
        this.IsActive = utils_1.Utilities.parseBoolean(this.IsActive);
        this.Balance = utils_1.Utilities.parseNumber(this.Balance);
        this.AccTypeName = utils_1.Utilities.valueToEnumName(enum_1.BankAccountType, this.AccType);
        this.QRImgPath = qrImgPath;
    }
    return BankAccountModel;
}());
exports.BankAccountModel = BankAccountModel;
